.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.mask {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.39);
	z-index: 999;
}

.container {
	position: relative;
	z-index: 1001;
	border: none;
	outline: none;
	background-color: #ffffff;
	max-height: 100%;
	max-width: 360px;
	width: 100%;
	padding: 25px 20px;
	color: #0000bc;
}

.inner {
	margin: auto;
	width: 438px;
}

.content {
	padding: 0 131px;
}

.header {
	text-align: center;
	padding-top: 49px;
	padding-bottom: 49px;
}

.searchIcon {
	width: 17px;
	height: 18px;
	position: absolute;
	top: 30px;
	left: 84px;
	right: auto;
	cursor: pointer;
	opacity: 0.7;
	fill: #5c5c5c;
	stroke: #5c5c5c;
	z-index: 20;
}

.title {
	margin-bottom: 26px;
	font-size: 22px;
}

.text {
	font-size: 18px;
	line-height: 26px;
	letter-spacing: 0.5px;
}

.buttonWrapper {
	display: flex;
	justify-content: space-between;
}

.footer, .cancel {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	cursor: pointer;
	text-transform: uppercase;
}

.footer:hover, .cancel:hover {
	text-decoration: underline;
}

.cancel {
	color: #a3a3a3;
}
