
.content {
	padding: 40px 0;
}

.content h5 {
	font-size: 20px;
	margin-top: 0;
	font-weight: 300;
}

.info {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0 0 25px;
	padding: 0;
}

.info li {
	width: 33.333%;
}

.info li * {
	text-align: center;
}

.info li:first-child * {
	text-align: left;
}
.info li:last-child * {
	text-align: right;
}

.key {
	margin-bottom: 11px;
	font-size: 13px;
	letter-spacing: 0.5px;
}

@media (min-width: 768px) {
	.key {
		font-size: 16px;
	}
}

.value {
	font-size: 18px;
	text-transform: uppercase;
}

.content table {
	width: 100%;
	margin-top: 40px;
}

.content table th {
	width: 33.33%;
	text-align: center;
}
.content table tbody td {
	text-align: center;
}
.content table tbody td:first-child, .content table th:first-child {
	text-align: left;
}
.content table tbody td:last-child, .content table th:last-child {
	text-align: right;
}
