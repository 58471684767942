.wrapper {
	max-width: 390px;
}

.modal {

}

.title {
	font-size: 18px;
	line-height: 21px;
}

.qr {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.qrTitle {
	font-size: 21px;
	padding-bottom: 12px;
	padding-top: 12px;
}
