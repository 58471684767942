
.select {
	position: relative;
	margin-bottom: 20px;
}

.labelWrapper {
	margin-top: 24px;
}

.description {

}

.labelWrapper .label {
	position: absolute;
	top: -19px;
	left: 0;
}

.selectContainer {
	position: relative;
	display: flex;
	flex-direction: column;
}

.selected :global(.select2-container--default .select2-selection--single .select2-selection__rendered){
	color: #ffffff;
	background-color: #0000bc;
}

.selected :global(.select2-container--default .select2-selection--single .select2-selection__arrow b){
	border-right: solid 1px #ffffff !important;
	border-bottom: solid 1px #ffffff !important;
}

.label {
	margin-bottom: 5px;
	font-size: 12px;
}

.select label.error {
	display: block;
	order: 3;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.selectSearch :global(.select2-results__option) {
	padding-left: 65px;
}

.descriptionText {
	margin-top: 20px;
	padding: 12px;
	opacity: 0.56;
}
