
.input {
	position: relative;
	margin-bottom: 20px;
}

.input .inputLabel {
	margin-bottom: 5px;
	font-size: 12px;
}

.input .inputContainer {
	position: relative;
}

.input input {
	display: block;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
	line-height: 60px;
	background: transparent;
}

.input input::-webkit-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.input input:-moz-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.input input::-moz-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.input input:-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.input input::-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.input input::placeholder {
	color: #0000bc;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.input input {
		height: 60px;
	}
}
.inputIcon {
	position: absolute;
	right: 16px;
	top: 12px;
	width: 26px;
	height: 26px;
	fill: #fff;
	pointer-events: none;
}

@media (min-width: 768px) {
	.inputIcon {
		top: 17px;
	}
}

.inputShowPassword {
	position: absolute;
	right: 16px;
	top: 12px;
	outline: none;
	border: none;
	padding: 0;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	background: none;
}

.inputShowPassword svg {
	display: block;
	width: 26px;
	height: 26px;
}

.inputShowPassword.isActive::after {
	content: "";
	position: absolute;
	left: 10%;
	top: 50%;
	width: 80%;
	height: 1px;
	-webkit-box-shadow: 0 0 0 1px #fff;
	-moz-box-shadow: 0 0 0 1px #fff;
	box-shadow: 0 0 0 1px #fff;
	background: #0000bc;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.inputShowPassword {
		top: 17px;
	}
}

.row label.error {
	display: block;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.inputCarNumber {
	display: flex;
}

.inputCarNumber input {
	padding-right: 10px;
	padding-left: 10px;
	width: 100%;
}
