@import url(https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.css);
@font-face {
	src: url(/static/media/CitySans-Thin.6a85710e.woff2) format("woff2"), url(/static/media/CitySans-Thin.61cbd7e9.woff) format("woff");
	font-family: "CitySans";
	font-weight: 100;
	font-style: normal;
}

@font-face {
	src: url(/static/media/CitySans-ExtraLight.73654211.woff2) format("woff2"), url(/static/media/CitySans-ExtraLight.c3d144de.woff) format("woff");
	font-family: "CitySans";
	font-weight: 200;
	font-style: normal;
}

@font-face {
	src: url(/static/media/CitySans-Light.f2e90c62.woff2) format("woff2"), url(/static/media/CitySans-Light.d276e24f.woff) format("woff");
	font-family: "CitySans";
	font-weight: 300;
	font-style: normal;
}

@font-face {
	src: url(/static/media/CitySans-Regular.c7333303.woff2) format("woff2"), url(/static/media/CitySans-Regular.3a2e683e.woff) format("woff");
	font-family: "CitySans";
	font-weight: 400;
	font-style: normal;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

[hidden],
template {
	display: none;
}

html {
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	margin: 0;
}

a {
	background: transparent;
}

a:focus {
	outline: thin dotted;
}

a:active,
a:hover {
	outline: 0;
}

h1 {
	font-size: 2em;
	margin: 0.67em 0;
}

abbr[title] {
	border-bottom: 1px dotted;
}

b,
strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

hr {
	box-sizing: content-box;
	height: 0;
}

mark {
	background: #ff0;
	color: #000;
}

code,
kbd,
pre,
samp {
	font-family: monospace, serif;
	font-size: 1em;
}

pre {
	white-space: pre-wrap;
}

q {
	quotes: "\201C" "\201D" "\2018" "\2019";
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

img {
	border: 0;
}

svg:not(:root) {
	overflow: hidden;
}

figure {
	margin: 0;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
}

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	margin: 0;
}

button,
input {
	line-height: normal;
}

button,
select {
	text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	overflow: auto;
	vertical-align: top;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
}

body {
	font-family: "CitySans", sans-serif;
	font-weight: 300;
	color: #fff;
	background: #0000bc;
}

b {
	font-weight: 400;
}

img {
	vertical-align: middle;
	max-width: 100%;
}

.select2-container {
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	position: relative;
	vertical-align: middle;
}

.select2-container .select2-selection--single {
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	height: 28px;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	display: block;
	padding-left: 8px;
	padding-right: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
	position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
	padding-right: 8px;
	padding-left: 20px;
}

.select2-container .select2-selection--multiple {
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	min-height: 32px;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
	display: inline-block;
	overflow: hidden;
	padding-left: 8px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.select2-container .select2-search--inline {
	float: left;
}

.select2-container .select2-search--inline .select2-search__field {
	box-sizing: border-box;
	border: none;
	font-size: 100%;
	margin-top: 5px;
	padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.select2-dropdown {
	background-color: white;
	border: 1px solid #aaa;
	border-radius: 4px;
	box-sizing: border-box;
	display: block;
	position: absolute;
	left: -100000px;
	width: 100%;
	z-index: 1051;
}

.select2-results {
	display: block;
}

.select2-results__options {
	list-style: none;
	margin: 0;
	padding: 0;
}

.select2-results__option {
	padding: 6px;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
}

.select2-results__option[aria-selected] {
	cursor: pointer;
}

.select2-container--open .select2-dropdown {
	left: 0;
}

.select2-container--open .select2-dropdown--above {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-search--dropdown {
	display: block;
	padding: 4px;
}

.select2-search--dropdown .select2-search__field {
	padding: 4px;
	width: 100%;
	box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
	display: none;
}

.select2-close-mask {
	border: 0;
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 99;
	background-color: #fff;
	filter: alpha(opacity=0);
}

.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #aaa;
	border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #444;
	line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: 100;
	width: 28px;
	right: 12px;
	font-size: 34px;
	margin-top: -4px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
	color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 26px;
	position: absolute;
	top: 1px;
	right: 1px;
	width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #888 transparent transparent transparent;
	border-style: solid;
	border-width: 5px 4px 0 4px;
	height: 0;
	left: 0;
	margin-left: -4px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
	float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
	left: 1px;
	right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
	background-color: #eee;
	cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
	display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #888 transparent;
	border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
	background-color: white;
	border: 1px solid #aaa;
	border-radius: 4px;
	cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0 5px;
	width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
	list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: bold;
	margin-top: -5px;
	margin-right: 10px;
	padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #e4e4e4;
	border: 1px solid #aaa;
	border-radius: 4px;
	cursor: default;
	float: left;
	margin-right: 5px;
	margin-top: 5px;
	padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	color: #999;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;
	margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
	color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
	float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
	margin-left: 5px;
	margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
	margin-left: 2px;
	margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border: solid black 1px;
	outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
	background-color: #eee;
	cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
	display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
	background: transparent;
	border: none;
	outline: 0;
	box-shadow: none;
	-webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
	max-height: 200px;
	overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
	padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
	color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
	padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
	padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -1em;
	padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -2em;
	padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -3em;
	padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -4em;
	padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -5em;
	padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #5897fb;
	color: white;
}

.select2-container--default .select2-results__group {
	cursor: default;
	display: block;
	padding: 6px;
}

.select2-container--classic .select2-selection--single {
	background-color: #f7f7f7;
	border: 1px solid #aaa;
	border-radius: 4px;
	outline: 0;
	background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
	border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
	color: #444;
	line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: bold;
	margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
	color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
	background-color: #ddd;
	border: none;
	border-left: 1px solid #aaa;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	height: 26px;
	position: absolute;
	top: 1px;
	right: 1px;
	width: 20px;
	background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
	border-color: #888 transparent transparent transparent;
	border-style: solid;
	border-width: 5px 4px 0 4px;
	height: 0;
	left: 50%;
	margin-left: -4px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
	float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
	border: none;
	border-right: 1px solid #aaa;
	border-radius: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	left: 1px;
	right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
	border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
	background: transparent;
	border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #888 transparent;
	border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
	background-color: white;
	border: 1px solid #aaa;
	border-radius: 4px;
	cursor: text;
	outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
	border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
	list-style: none;
	margin: 0;
	padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
	display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
	background-color: #e4e4e4;
	border: 1px solid #aaa;
	border-radius: 4px;
	cursor: default;
	float: left;
	margin-right: 5px;
	margin-top: 5px;
	padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
	color: #888;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;
	margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
	color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
	float: right;
	margin-left: 5px;
	margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
	margin-left: 2px;
	margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
	border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
	border: 1px solid #aaa;
	outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
	outline: 0;
	box-shadow: none;
}

.select2-container--classic .select2-dropdown {
	background-color: white;
	border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
	border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
	border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
	max-height: 200px;
	overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
	padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
	color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
	background-color: #3875d7;
	color: white;
}

.select2-container--classic .select2-results__group {
	cursor: default;
	display: block;
	padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
	border-color: #5897fb;
}

.select2-container {
	outline: none;
}

.select2-container--default {
	outline: none;
}

.select2-container--default .select2-selection--multiple {
	outline: none;
	border: solid 1px #0000bc;
	border-radius: 0;
	height: 50px;
	line-height: 50px;
	background: transparent;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	padding: 0 20px;
	height: 100%;
	text-overflow: ellipsis;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
	display: inline-block;
	float: none;
	vertical-align: middle;
	margin: 0;
	border: none;
	padding: 0;
	font-size: 18px;
	letter-spacing: 0.5px;
	background: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li::after {
	content: ",";
	padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	display: none;
}

@media (min-width: 768px) {
	.select2-container--default .select2-selection--multiple {
		height: 60px;
		line-height: 60px;
	}
}

.select2-container--default .select2-selection--single {
	outline: none;
	border: solid 1px #0000bc;
	border-radius: 0;
	height: 60px;
	background: transparent;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	padding: 0 20px;
	font-size: 18px;
	line-height: 60px;
	letter-spacing: 0.5px;
	color: #0000bc;
}

@media (min-width: 768px) {
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 60px;
	}
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 0;
	width: 26px;
	height: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	margin-top: -7px;
	border: none !important;
	border-right: solid 1px #0000bc !important;
	border-bottom: solid 1px #0000bc !important;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
}

@media (min-width: 768px) {
	.select2-container--default .select2-selection--single {
		height: 60px;
	}
}

.select2-container--default .select2-results__option[aria-selected="true"] {
	background: #bcbcff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background: #0000bc;
}

.select2-container--default .select2-results__option[aria-disabled="true"] {
	display: none;
}

.select2-container--default .select2-results__option[aria-disabled="true"] + .select2-results__option {
	border-top: none;
}

.select2-container--default .select2-results > .select2-results__options {
	max-height: 244px;
}

.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background-color: transparent;
}

.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-track {
	background-color: transparent;
}

.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-thumb {
	background-color: #0000bc;
	opacity: 0;
}

.select2-container--default .select2-search {
	position: relative;
	padding: 0;
}

.select2-container--default .select2-search::before {
	content: "";
	position: absolute;
	left: 19px;
	top: 18px;
	width: 25px;
	height: 26px;
	background: url(/static/media/search.74c4315e.svg) 50% 50% no-repeat;
	background-size: contain;
	pointer-events: none;
}

.select2-container--default .select2-search__field {
	outline: none;
	border: none !important;
	border-bottom: solid 1px #0000bc !important;
	padding: 4px 25px 0 65px;
	height: 50px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
}

.select2-container--default .select2-search__field::-webkit-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.select2-container--default .select2-search__field:-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.select2-container--default .select2-search__field::-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.select2-container--default .select2-search__field::placeholder {
	color: #0000bc;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.select2-container--default .select2-search__field {
		height: 60px;
	}
}

/*.select2-container--default .select2-search:not(.select2-search--hide) + .select2-results .select2-results__option {*/
/*	padding-left: 65px;*/
/*}*/

.select2-container--default .select2-dropdown {
	top: 5px;
	z-index: 99999;
	border: solid 1px #0000bc;
	border-radius: 0;
}

.select2-container--default .select2-results__option {
	padding: 15px 20px;
	font-size: 18px;
	line-height: 20px;
	color: #0000bc;
}

.select2-container--default .select2-results__option + .select2-results__option {
	border-top: solid 1px #0000bc;
}

@media (min-width: 768px) {
	.select2-container--default .select2-results__option {
		padding: 20px;
	}
}

.select2-container--default .select2-search--inline {
	display: none !important;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	margin-top: -2px;
	transform: rotate(225deg);
}


.xdsoft_datetimepicker {
	box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
	background: #fff;
	border-bottom: 1px solid #bbb;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-top: 1px solid #ccc;
	color: #333;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	padding: 8px;
	padding-left: 0;
	padding-top: 2px;
	position: absolute;
	z-index: 9999;
	box-sizing: border-box;
	display: none;
}

.xdsoft_datetimepicker.xdsoft_rtl {
	padding: 8px 0 8px 8px;
}

.xdsoft_datetimepicker iframe {
	position: absolute;
	left: 0;
	top: 0;
	width: 75px;
	height: 210px;
	background: transparent;
	border: 0;
}

.xdsoft_datetimepicker button {
	border: none !important;
}

.xdsoft_noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.xdsoft_noselect::selection {
	background: transparent;
}

.xdsoft_noselect::-moz-selection {
	background: transparent;
}

.xdsoft_datetimepicker.xdsoft_inline {
	display: inline-block;
	position: static;
	box-shadow: none;
}

.xdsoft_datetimepicker * {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker, .xdsoft_datetimepicker .xdsoft_timepicker {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active, .xdsoft_datetimepicker .xdsoft_timepicker.active {
	display: block;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
	width: 224px;
	margin-left: 8px;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
	float: right;
	margin-right: 8px;
	margin-left: 0;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
	width: 256px;
}

.xdsoft_datetimepicker .xdsoft_timepicker {
	width: 58px;
	margin: auto;
	text-align: center;
	margin-top: 0;
	display: block;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker {
	float: right;
	margin-right: 8px;
	margin-left: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active + .xdsoft_timepicker {
	margin-top: 8px;
	margin-bottom: 3px;
}

.xdsoft_datetimepicker .xdsoft_monthpicker {
	position: relative;
	text-align: center;
}

.xdsoft_datetimepicker .xdsoft_label i, .xdsoft_datetimepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_next, .xdsoft_datetimepicker .xdsoft_today_button {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC);
}

.xdsoft_datetimepicker .xdsoft_label i {
	opacity: .5;
	background-position: -92px -19px;
	display: inline-block;
	width: 9px;
	height: 20px;
	vertical-align: middle;
}

.xdsoft_datetimepicker .xdsoft_prev {
	float: left;
	background-position: -20px 0;
}

.xdsoft_datetimepicker .xdsoft_today_button {
	float: left;
	background-position: -70px 0;
	margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_next {
	float: right;
	background-position: 0 0;
}

.xdsoft_datetimepicker .xdsoft_next, .xdsoft_datetimepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_today_button {
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0 none;
	cursor: pointer;
	display: block;
	height: 30px;
	opacity: .5;
	-ms-filter: "alpha(opacity=50)";
	outline: medium none;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-indent: 100%;
	white-space: nowrap;
	width: 20px;
	min-width: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
	float: none;
	background-position: -40px -15px;
	height: 15px;
	width: 30px;
	display: block;
	margin-left: 14px;
	margin-top: 7px;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_prev, .xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_next {
	float: none;
	margin-left: 0;
	margin-right: 14px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
	background-position: -40px 0;
	margin-bottom: 7px;
	margin-top: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
	height: 100px;
	overflow: hidden;
	border: 1px solid #ffffff;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div {
	background: #ffffff;
	border-top: 1px solid #ffffff;
	color: #0000bc;
	font-size: 12px;
	text-align: center;
	border-collapse: collapse;
	cursor: pointer;
	border-bottom-width: 0;
	height: 25px;
	line-height: 25px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:first-child {
	border-top-width: 0;
}

.xdsoft_datetimepicker .xdsoft_today_button:hover, .xdsoft_datetimepicker .xdsoft_next:hover, .xdsoft_datetimepicker .xdsoft_prev:hover {
	opacity: 1;
	-ms-filter: "alpha(opacity=100)";
}

.xdsoft_datetimepicker .xdsoft_label {
	display: inline;
	position: relative;
	z-index: 9999;
	margin: 0;
	padding: 5px 3px;
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	background-color: #fff;
	float: left;
	width: 182px;
	text-align: center;
	cursor: pointer;
}

.xdsoft_datetimepicker .xdsoft_label:hover > span {
	text-decoration: underline;
}

.xdsoft_datetimepicker .xdsoft_label:hover i {
	opacity: 1.0;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select {
	border: 1px solid #ccc;
	position: absolute;
	right: 0;
	top: 30px;
	z-index: 101;
	display: none;
	background: #fff;
	max-height: 160px;
	overflow-y: hidden;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_monthselect {
	right: -7px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_yearselect {
	right: 2px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
	color: #fff;
	background: #ff8000;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
	padding: 2px 10px 2px 5px;
	text-decoration: none !important;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
	background: #3af;
	box-shadow: #178fe5 0 1px 3px 0 inset;
	color: #fff;
	font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_month {
	width: 100px;
	text-align: right;
}

.xdsoft_datetimepicker .xdsoft_calendar {
	clear: both;
}

.xdsoft_datetimepicker .xdsoft_year {
	width: 48px;
	margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar table {
	border-collapse: collapse;
	width: 100%;
}

.xdsoft_datetimepicker .xdsoft_calendar td > div {
	padding-right: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	height: 25px;
}

.xdsoft_datetimepicker .xdsoft_calendar td, .xdsoft_datetimepicker .xdsoft_calendar th {
	width: 14.2857142%;
	background: #f5f5f5;
	border: 1px solid #ddd;
	color: #666;
	font-size: 12px;
	text-align: right;
	vertical-align: middle;
	padding: 0;
	border-collapse: collapse;
	cursor: pointer;
	height: 25px;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar td, .xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar th {
	width: 12.5%;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	background: #f1f1f1;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
	color: #3af;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_default {
	background: #ffe9d2;
	box-shadow: #ffb871 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_mint {
	background: #c1ffc9;
	box-shadow: #00dd1c 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
	background: #3af;
	box-shadow: #178fe5 0 1px 3px 0 inset;
	color: #fff;
	font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled, .xdsoft_datetimepicker .xdsoft_time_box > div > div.xdsoft_disabled {
	opacity: .5;
	-ms-filter: "alpha(opacity=50)";
	cursor: default;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month.xdsoft_disabled {
	opacity: .2;
	-ms-filter: "alpha(opacity=20)";
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
	color: #fff !important;
	background: #ff8000 !important;
	box-shadow: none !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_disabled:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current.xdsoft_disabled:hover {
	background: #3af !important;
	box-shadow: #178fe5 0 1px 3px 0 inset !important;
	color: #fff !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_disabled:hover {
	color: inherit !important;
	background: inherit !important;
	box-shadow: inherit !important;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	font-weight: 700;
	text-align: center;
	color: #999;
	cursor: default;
}

.xdsoft_datetimepicker .xdsoft_copyright {
	color: #ccc !important;
	font-size: 10px;
	clear: both;
	float: none;
	margin-left: 8px;
}

.xdsoft_datetimepicker .xdsoft_copyright a {
	color: #eee !important;
}

.xdsoft_datetimepicker .xdsoft_copyright a:hover {
	color: #aaa !important;
}

.xdsoft_time_box {
	position: relative;
	border: 1px solid #ccc;
}

.xdsoft_scrollbar > .xdsoft_scroller {
	background: #ccc !important;
	height: 20px;
	border-radius: 3px;
}

.xdsoft_scrollbar {
	position: absolute;
	width: 7px;
	right: 0;
	top: 0;
	bottom: 0;
	cursor: pointer;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_scrollbar {
	left: 0;
	right: auto;
}

.xdsoft_scroller_box {
	position: relative;
}

.xdsoft_datetimepicker.xdsoft_dark {
	box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
	background: #000;
	border-bottom: 1px solid #444;
	border-left: 1px solid #333;
	border-right: 1px solid #333;
	border-top: 1px solid #333;
	color: #ccc;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box {
	border-bottom: 1px solid #222;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div {
	background: #0a0a0a;
	border-top: 1px solid #222;
	color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label {
	background-color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select {
	border: 1px solid #333;
	background: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
	color: #000;
	background: #007fff;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
	background: #c50;
	box-shadow: #b03e00 0 1px 3px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label i, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_prev, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_next, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_today_button {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUExQUUzOTA0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUExQUUzOTE0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBQTFBRTM4RTQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBQTFBRTM4RjQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pp0VxGEAAAIASURBVHja7JrNSgMxEMebtgh+3MSLr1T1Xn2CHoSKB08+QmR8Bx9A8e7RixdB9CKCoNdexIugxFlJa7rNZneTbLIpM/CnNLsdMvNjM8l0mRCiQ9Ye61IKCAgZAUnH+mU3MMZaHYChBnJUDzWOFZdVfc5+ZFLbrWDeXPwbxIqrLLfaeS0hEBVGIRQCEiZoHQwtlGSByCCdYBl8g8egTTAWoKQMRBRBcZxYlhzhKegqMOageErsCHVkk3hXIFooDgHB1KkHIHVgzKB4ADJQ/A1jAFmAYhkQqA5TOBtocrKrgXwQA8gcFIuAIO8sQSA7hidvPwaQGZSaAYHOUWJABhWWw2EMIH9QagQERU4SArJXo0ZZL18uvaxejXt/Em8xjVBXmvFr1KVm/AJ10tRe2XnraNqaJvKE3KHuUbfK1E+VHB0q40/y3sdQSxY4FHWeKJCunP8UyDdqJZenT3ntVV5jIYCAh20vT7ioP8tpf6E2lfEMwERe+whV1MHjwZB7PBiCxcGQWwKZKD62lfGNnP/1poFAA60T7rF1UgcKd2id3KDeUS+oLWV8DfWAepOfq00CgQabi9zjcgJVYVD7PVzQUAUGAQkbNJTBICDhgwYTjDYD6XeW08ZKh+A4pYkzenOxXUbvZcWz7E8ykRMnIHGX1XPl+1m2vPYpL+2qdb8CDAARlKFEz/ZVkAAAAABJRU5ErkJggg==);
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	background: #0a0a0a;
	border: 1px solid #222;
	color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	background: #0e0e0e;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_today {
	color: #c50;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_default {
	background: #ffe9d2;
	box-shadow: #ffb871 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_mint {
	background: #c1ffc9;
	box-shadow: #00dd1c 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
	background: #c50;
	box-shadow: #b03e00 0 1px 3px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td:hover, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
	color: #000 !important;
	background: #007fff !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	color: #666;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright {
	color: #333 !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a {
	color: #111 !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a:hover {
	color: #555 !important;
}

.xdsoft_dark .xdsoft_time_box {
	border: 1px solid #333;
}

.xdsoft_dark .xdsoft_scrollbar > .xdsoft_scroller {
	background: #333 !important;
}

.xdsoft_datetimepicker .xdsoft_save_selected {
	display: block;
	border: 1px solid #ddd !important;
	margin-top: 5px;
	width: 100%;
	color: #454551;
	font-size: 13px;
}

.xdsoft_datetimepicker .blue-gradient-button {
	font-family: "museo-sans", "Book Antiqua", sans-serif;
	font-size: 12px;
	font-weight: 300;
	color: #82878c;
	height: 28px;
	position: relative;
	padding: 4px 17px 4px 33px;
	border: 1px solid #d7d8da;
	background: linear-gradient(to bottom, #fff 0, #f4f8fa 73%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#f4f8fa', GradientType=0);
}

.xdsoft_datetimepicker .blue-gradient-button:hover, .xdsoft_datetimepicker .blue-gradient-button:focus, .xdsoft_datetimepicker .blue-gradient-button:hover span, .xdsoft_datetimepicker .blue-gradient-button:focus span {
	color: #454551;
	background: linear-gradient(to bottom, #f4f8fa 0, #FFF 73%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f8fa', endColorstr='#FFF', GradientType=0);
}


.xdsoft_datetimepicker {
	z-index: 99999;
	margin-top: 5px;
	border: solid 1px #0000bc;
	padding: 20px 40px;
	box-shadow: none;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
	margin: 0;
	width: 280px;
}

.xdsoft_datetimepicker .xdsoft_monthpicker {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}

.xdsoft_datetimepicker .xdsoft_today_button {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_year {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_month {
	order: -1;
	margin-right: auto;
	padding: 0;
	font-weight: 300;
	font-size: 13px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
	color: #0000bc;
}

.xdsoft_datetimepicker .xdsoft_month i {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_month .xdsoft_monthselect {
	left: 0;
	right: auto !important;
	top: 20px;
	border: solid 1px #0000bc;
}

.xdsoft_datetimepicker .xdsoft_month .xdsoft_monthselect .xdsoft_option:hover {
	background: #0000bc !important;
}

.xdsoft_datetimepicker .xdsoft_month .xdsoft_monthselect .xdsoft_option.xdsoft_current {
	box-shadow: none !important;
	font-weight: 300 !important;
	background: #0000bc !important;
}

.xdsoft_datetimepicker .xdsoft_scroller {
	border-radius: 0;
	background: #0000bc !important;
}

.xdsoft_datetimepicker .xdsoft_calendar {
	margin: 0 -10px;
}

.xdsoft_datetimepicker .xdsoft_calendar table {
	border: none;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	border: none;
	padding: 0;
	font-weight: 300;
	font-size: 13px;
	letter-spacing: 2px;
	color: #0000bc;
	background: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td {
	border: none;
	padding: 6px 0;
	height: auto;
	font-weight: 300;
	font-size: 14px;
	letter-spacing: 2px;
	text-align: center;
	color: #0000bc;
	background: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td div {
	margin: 0 auto;
	padding: 0 0 0 2px;
	width: 24px;
	height: 24px;
	line-height: 24px;
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover {
	box-shadow: none;
	font-weight: 300;
	background: none !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover div {
	background: #0000bc;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
	box-shadow: none;
	font-weight: 300;
	color: #fff;
	background: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today div {
	margin: 0 auto;
	width: 24px;
	background: #d6d6ff;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current {
	box-shadow: none;
	font-weight: 300;
	background: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current div {
	margin: 0 auto;
	width: 24px;
	background: #0000bc;
}

.xdsoft_datetimepicker .xdsoft_prev {
	position: relative;
	background: none;
	opacity: 1;
}

.xdsoft_datetimepicker .xdsoft_prev::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -5px 0 0 -2px;
	border-bottom: solid 1px #0000bc;
	border-left: solid 1px #0000bc;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
}

.xdsoft_datetimepicker .xdsoft_next {
	position: relative;
	margin: 0 0 0 14px;
	background: none;
	opacity: 1;
}

.xdsoft_datetimepicker .xdsoft_next::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -5px 0 0 -7px;
	border-right: solid 1px #0000bc;
	border-bottom: solid 1px #0000bc;
	width: 10px;
	height: 10px;
	transform: rotate(-45deg);
}

.Toastify__toast-container {
	z-index: 9999;
	-webkit-transform: translate3d(0, 0, 9999px);
	position: fixed;
	padding: 4px;
	width: 320px;
	box-sizing: border-box;
	color: #fff;
}

.Toastify__toast-container--top-left {
	top: 1em;
	left: 1em;
}

.Toastify__toast-container--top-center {
	top: 1em;
	left: 50%;
	transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
	top: 1em;
	right: 1em;
}

.Toastify__toast-container--bottom-left {
	bottom: 1em;
	left: 1em;
}

.Toastify__toast-container--bottom-center {
	bottom: 1em;
	left: 50%;
	transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
	bottom: 1em;
	right: 1em;
}

@media only screen and (max-width: 480px) {
	.Toastify__toast-container {
		width: 100vw;
		padding: 0;
		left: 0;
		margin: 0;
	}

	.Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
		top: 0;
		transform: translateX(0);
	}

	.Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
		bottom: 0;
		transform: translateX(0);
	}

	.Toastify__toast-container--rtl {
		right: 0;
		left: auto;
		left: initial;
	}
}

.Toastify__toast {
	position: relative;
	min-height: 64px;
	box-sizing: border-box;
	margin-bottom: 1rem;
	padding: 8px;
	border-radius: 1px;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
	display: flex;
	justify-content: space-between;
	max-height: 800px;
	overflow: hidden;
	font-family: sans-serif;
	cursor: pointer;
	direction: ltr;
}

.Toastify__toast--rtl {
	direction: rtl;
}

.Toastify__toast--dark {
	background: #121212;
	color: #fff;
}

.Toastify__toast--default {
	background: #fff;
	color: #aaa;
}

.Toastify__toast--info {
	background: #3498db;
}

.Toastify__toast--success {
	background: #07bc0c;
}

.Toastify__toast--warning {
	background: #f1c40f;
}

.Toastify__toast--error {
	background: #e74c3c;
}

.Toastify__toast-body {
	margin: auto 0;
	flex: 1 1 auto;
}

@media only screen and (max-width: 480px) {
	.Toastify__toast {
		margin-bottom: 0;
	}
}

.Toastify__close-button {
	color: #fff;
	background: transparent;
	outline: none;
	border: none;
	padding: 0;
	cursor: pointer;
	opacity: 0.7;
	transition: 0.3s ease;
	align-self: flex-start;
}

.Toastify__close-button--default {
	color: #000;
	opacity: 0.3;
}

.Toastify__close-button > svg {
	fill: currentColor;
	height: 16px;
	width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
	opacity: 1;
}

@-webkit-keyframes Toastify__trackProgress {
	0% {
		transform: scaleX(1);
	}
	100% {
		transform: scaleX(0);
	}
}

@keyframes Toastify__trackProgress {
	0% {
		transform: scaleX(1);
	}
	100% {
		transform: scaleX(0);
	}
}

.Toastify__progress-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5px;
	z-index: 9999;
	opacity: 0.7;
	background-color: rgba(255, 255, 255, 0.7);
	transform-origin: left;
}

.Toastify__progress-bar--animated {
	-webkit-animation: Toastify__trackProgress linear 1 forwards;
	        animation: Toastify__trackProgress linear 1 forwards;
}

.Toastify__progress-bar--controlled {
	transition: transform 0.2s;
}

.Toastify__progress-bar--rtl {
	right: 0;
	left: auto;
	left: initial;
	transform-origin: right;
}

.Toastify__progress-bar--default {
	background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

.Toastify__progress-bar--dark {
	background: #bb86fc;
}

@-webkit-keyframes Toastify__bounceInRight {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	from {
		opacity: 0;
		transform: translate3d(3000px, 0, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(-25px, 0, 0);
	}
	75% {
		transform: translate3d(10px, 0, 0);
	}
	90% {
		transform: translate3d(-5px, 0, 0);
	}
	to {
		transform: none;
	}
}

@keyframes Toastify__bounceInRight {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	from {
		opacity: 0;
		transform: translate3d(3000px, 0, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(-25px, 0, 0);
	}
	75% {
		transform: translate3d(10px, 0, 0);
	}
	90% {
		transform: translate3d(-5px, 0, 0);
	}
	to {
		transform: none;
	}
}

@-webkit-keyframes Toastify__bounceOutRight {
	20% {
		opacity: 1;
		transform: translate3d(-20px, 0, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}
}

@keyframes Toastify__bounceOutRight {
	20% {
		opacity: 1;
		transform: translate3d(-20px, 0, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}
}

@-webkit-keyframes Toastify__bounceInLeft {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		transform: translate3d(-3000px, 0, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(25px, 0, 0);
	}
	75% {
		transform: translate3d(-10px, 0, 0);
	}
	90% {
		transform: translate3d(5px, 0, 0);
	}
	to {
		transform: none;
	}
}

@keyframes Toastify__bounceInLeft {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		transform: translate3d(-3000px, 0, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(25px, 0, 0);
	}
	75% {
		transform: translate3d(-10px, 0, 0);
	}
	90% {
		transform: translate3d(5px, 0, 0);
	}
	to {
		transform: none;
	}
}

@-webkit-keyframes Toastify__bounceOutLeft {
	20% {
		opacity: 1;
		transform: translate3d(20px, 0, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(-2000px, 0, 0);
	}
}

@keyframes Toastify__bounceOutLeft {
	20% {
		opacity: 1;
		transform: translate3d(20px, 0, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(-2000px, 0, 0);
	}
}

@-webkit-keyframes Toastify__bounceInUp {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	from {
		opacity: 0;
		transform: translate3d(0, 3000px, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}
	75% {
		transform: translate3d(0, 10px, 0);
	}
	90% {
		transform: translate3d(0, -5px, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes Toastify__bounceInUp {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	from {
		opacity: 0;
		transform: translate3d(0, 3000px, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}
	75% {
		transform: translate3d(0, 10px, 0);
	}
	90% {
		transform: translate3d(0, -5px, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes Toastify__bounceOutUp {
	20% {
		transform: translate3d(0, -10px, 0);
	}
	40%, 45% {
		opacity: 1;
		transform: translate3d(0, 20px, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}
}

@keyframes Toastify__bounceOutUp {
	20% {
		transform: translate3d(0, -10px, 0);
	}
	40%, 45% {
		opacity: 1;
		transform: translate3d(0, 20px, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}
}

@-webkit-keyframes Toastify__bounceInDown {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		transform: translate3d(0, -3000px, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0);
	}
	75% {
		transform: translate3d(0, -10px, 0);
	}
	90% {
		transform: translate3d(0, 5px, 0);
	}
	to {
		transform: none;
	}
}

@keyframes Toastify__bounceInDown {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		transform: translate3d(0, -3000px, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0);
	}
	75% {
		transform: translate3d(0, -10px, 0);
	}
	90% {
		transform: translate3d(0, 5px, 0);
	}
	to {
		transform: none;
	}
}

@-webkit-keyframes Toastify__bounceOutDown {
	20% {
		transform: translate3d(0, 10px, 0);
	}
	40%, 45% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}
}

@keyframes Toastify__bounceOutDown {
	20% {
		transform: translate3d(0, 10px, 0);
	}
	40%, 45% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
	-webkit-animation-name: Toastify__bounceInLeft;
	        animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
	-webkit-animation-name: Toastify__bounceInRight;
	        animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
	-webkit-animation-name: Toastify__bounceInDown;
	        animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
	-webkit-animation-name: Toastify__bounceInUp;
	        animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
	-webkit-animation-name: Toastify__bounceOutLeft;
	        animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
	-webkit-animation-name: Toastify__bounceOutRight;
	        animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
	-webkit-animation-name: Toastify__bounceOutUp;
	        animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
	-webkit-animation-name: Toastify__bounceOutDown;
	        animation-name: Toastify__bounceOutDown;
}

@-webkit-keyframes Toastify__zoomIn {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
	50% {
		opacity: 1;
	}
}

@keyframes Toastify__zoomIn {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
	50% {
		opacity: 1;
	}
}

@-webkit-keyframes Toastify__zoomOut {
	from {
		opacity: 1;
	}
	50% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
	to {
		opacity: 0;
	}
}

@keyframes Toastify__zoomOut {
	from {
		opacity: 1;
	}
	50% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
	to {
		opacity: 0;
	}
}

.Toastify__zoom-enter {
	-webkit-animation-name: Toastify__zoomIn;
	        animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
	-webkit-animation-name: Toastify__zoomOut;
	        animation-name: Toastify__zoomOut;
}

@-webkit-keyframes Toastify__flipIn {
	from {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
	}
	60% {
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}
	80% {
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}
	to {
		transform: perspective(400px);
	}
}

@keyframes Toastify__flipIn {
	from {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
	}
	60% {
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}
	80% {
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}
	to {
		transform: perspective(400px);
	}
}

@-webkit-keyframes Toastify__flipOut {
	from {
		transform: perspective(400px);
	}
	30% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		opacity: 1;
	}
	to {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		opacity: 0;
	}
}

@keyframes Toastify__flipOut {
	from {
		transform: perspective(400px);
	}
	30% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		opacity: 1;
	}
	to {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		opacity: 0;
	}
}

.Toastify__flip-enter {
	-webkit-animation-name: Toastify__flipIn;
	        animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
	-webkit-animation-name: Toastify__flipOut;
	        animation-name: Toastify__flipOut;
}

@-webkit-keyframes Toastify__slideInRight {
	from {
		transform: translate3d(110%, 0, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes Toastify__slideInRight {
	from {
		transform: translate3d(110%, 0, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes Toastify__slideInLeft {
	from {
		transform: translate3d(-110%, 0, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes Toastify__slideInLeft {
	from {
		transform: translate3d(-110%, 0, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes Toastify__slideInUp {
	from {
		transform: translate3d(0, 110%, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes Toastify__slideInUp {
	from {
		transform: translate3d(0, 110%, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes Toastify__slideInDown {
	from {
		transform: translate3d(0, -110%, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes Toastify__slideInDown {
	from {
		transform: translate3d(0, -110%, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes Toastify__slideOutRight {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(110%, 0, 0);
	}
}

@keyframes Toastify__slideOutRight {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(110%, 0, 0);
	}
}

@-webkit-keyframes Toastify__slideOutLeft {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(-110%, 0, 0);
	}
}

@keyframes Toastify__slideOutLeft {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(-110%, 0, 0);
	}
}

@-webkit-keyframes Toastify__slideOutDown {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(0, 500px, 0);
	}
}

@keyframes Toastify__slideOutDown {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(0, 500px, 0);
	}
}

@-webkit-keyframes Toastify__slideOutUp {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(0, -500px, 0);
	}
}

@keyframes Toastify__slideOutUp {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(0, -500px, 0);
	}
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
	-webkit-animation-name: Toastify__slideInLeft;
	        animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
	-webkit-animation-name: Toastify__slideInRight;
	        animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
	-webkit-animation-name: Toastify__slideInDown;
	        animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
	-webkit-animation-name: Toastify__slideInUp;
	        animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
	-webkit-animation-name: Toastify__slideOutLeft;
	        animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
	-webkit-animation-name: Toastify__slideOutRight;
	        animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
	-webkit-animation-name: Toastify__slideOutUp;
	        animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
	-webkit-animation-name: Toastify__slideOutDown;
	        animation-name: Toastify__slideOutDown;
}


/* REMOVE */

.rc-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 80;
}

.rc-backdrop.invert {
	background: rgba(1, 1, 1, .7);
}

.range-picker-input {
	display: inline-flex;
	border: 1px solid #e4e4e4;
	width: 300px;
}

.range-picker-input * {
	box-sizing: border-box;
}

.range-picker-input__icon {
	display: inline-flex;
	align-items: center;
}

.range-picker-input__start, .range-picker-input__end {
	display: inline-flex;
	flex: 1 1;
}

.range-picker-input__start .picker-input.range input, .range-picker-input__end .picker-input.range input {
	width: 100%;
	border: none;
}

.picker-input {
	display: inline-block;
	position: relative;
	width: 100%;
}

.picker-input_button {
	font-size: 13px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	line-height: 13px;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 4px;
	width: 100%;
	height: 60px;
	font-weight: 300;
	letter-spacing: 0.5px;
	color: #0000bc;
	background: transparent;
	position: relative;
	cursor: pointer;
}

.picker-input_button-placeholder {
	font-size: 18px;
	opacity: 0.5;
}
.picker-input_button-value {
	font-size: 18px;
}

.picker-input__icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 10px;
	display: inline-flex;
	align-items: center;
}

.picker-input__text {
	padding: 10px;
	border: 1px solid #e4e4e4;
	outline: none;
	font-size: 16.8px;
}

.picker-input__text:disabled {
	background: #e4e4e4;
}

.picker-input__clear {
	position: absolute;
	top: 0;
	width: 54px;
	height: 100%;
	right: 0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.picker-input__clear svg {
	width: 20px;
	height: 20px;
}

.picker {
	display: inline-block;
	width: 100%;
}

.picker__container {
	position: absolute;
	z-index: 100;
}

.picker-range {
	border: solid 1px #0000bc;
}


.picker-range .calendar__item {
	padding: 0 28px;
}

.picker__container.portal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.picker__container__include-time {
}

.picker__container__include-time .calendar__item, .picker__container__include-time .time__container {
	border: none;
}

.picker__container__tab {
	margin: 10px 0;
}

.picker__container__tab button {
	padding: 5px 10px;
	outline: none;
	display: inline-flex;
	align-items: center;
	background: none;
	border: none;
	border-bottom: 2px solid #e4e4e4;
}

.picker__container__tab button.active {
	color: #49599a;
	border-bottom: 2px solid #49599a;
}

.picker__container__tab button:first-child {
	border-right: none;
}

.picker__container__tab button svg {
	margin-right: 5px;
}

.time__container {
	display: inline-flex;
	align-items: center;
	border: 1px solid #e4e4e4;
	padding: 15px;
	background: white;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 12px;
}

.time__container__div {
	margin: 0 10px;
}

.time__container__type {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}

.time-input {
	display: inline-block;
	width: 40px;
	overflow: hidden;
}

.time-input__up, .time-input__down {
	border: 1px solid #e4e4e4;
}

.time-input__up button, .time-input__down button {
	outline: none;
	width: 100%;
	cursor: pointer;
	border: none;
}

.time-input__text {
	width: 100%;
	border-left: 1px solid #e4e4e4;
	border-right: 1px solid #e4e4e4;
	box-sizing: border-box;
}

.time-input__text input {
	width: 100%;
	box-sizing: border-box;
	border: none;
	font-size: 15px;
	padding: 5px !important;
	text-align: center;
	outline: none;
}

.calendar {
	display: block;
	background: white;
	font-size: 12px;
}

.calendar *, .calendar *:before, .calendar *:after {
	box-sizing: border-box;
}

.calendar__container {
	width: 270px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	display: none;
}

.calendar__list {
	display: table;
	padding: 24px;
}

.calendar__item {
	display: table-cell;
}

.calendar__item:not(:first-child) {
	border-left: none !important;
}

.calendar--show {
	display: inline-block;
}

.calendar__head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
	position: relative;
	text-align: center;
	color: #0a0a0a;
}

.calendar__head--title {
	order: -1;
	padding: 0;
	font-weight: 300;
	font-size: 13px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
	color: #0000bc;
}

.calendar__head--button {
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0 none;
	cursor: pointer;
	display: block;
	height: 30px;
	opacity: .5;
	-ms-filter: "alpha(opacity=50)";
	outline: medium none;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-indent: 100%;
	white-space: nowrap;
	width: 20px;
	min-width: 0;
}

.calendar__head--buttons {
	display: flex;
	justify-content: center;
	align-items: center;
}

.calendar__head--button:before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	width: 10px;
	height: 10px;
}

.calendar__head--prev, .calendar__head--next {
}

.calendar__head--prev .calendar__head--button:before {
	margin: -5px 0 0 -2px;
	border-bottom: solid 1px #0000bc;
	border-left: solid 1px #0000bc;
	transform: rotate(45deg);
}

.calendar__head--next .calendar__head--button:before {
	margin: -5px 0 0 -7px;
	border-right: solid 1px #0000bc;
	border-bottom: solid 1px #0000bc;
	transform: rotate(-45deg);
}

.calendar__panel--show {
	display: block !important;
}

.calendar__panel--today {
	background: #aab6fe;
	padding: 5px;
	display: none;
}

.calendar__panel--today h2 {
	margin: 0;
	cursor: pointer;
	font-size: 12px;
	text-align: center;
}

.calendar__body {
	margin: 0 -10px;
}

.calendar__body--table {
	width: 100%;
	table-layout: fixed;
	text-align: center;
	border-spacing: 0;
	border-collapse: collapse;
}

.calendar__body--table th {
	border: none;
	padding: 0;
	font-weight: 300;
	font-size: 13px;
	letter-spacing: 2px;
	color: #0000bc;
	background: none;
}

.calendar__day {
	border: none;
	padding: 6px 0;
	height: auto;
	font-weight: 300;
	font-size: 14px;
	letter-spacing: 2px;
	text-align: center;
	color: #0000bc;
	background: none;
}

.calendar__day div {
	margin: 0 auto;
	padding: 0 0 0 2px;
	width: 24px;
	height: 24px;
	line-height: 24px;
	cursor: pointer;
}

.calendar__day self:hover:not(self--disabled) {
	cursor: pointer;
	background: #0000bc;
	color: #FFFFFF;
}

.calendar__day--today div {
	background: #d6d6ff;
}

.calendar__day--disabled {
	opacity: .2;
}

.calendar__day--disabled > div {
	cursor: default;
}

.calendar__day--start div, .calendar__day--end div, .calendar__day--selected div {
	background: #0000bc;
	color: #fff;
}

.calendar__day--start:hover, .calendar__day--end:hover, .calendar__day--selected:hover {
	background: #7986cb;
}

.calendar__day--range {
	background: #dce1ff;
}

.calendar__day--text {
	display: block;
	font-size: 10px;
}

.calendar__year, .calendar__month {
	height: 55px;
	vertical-align: middle;
}

.calendar__year:hover, .calendar__month:hover {
	background: #aab6fe;
	cursor: pointer;
}

.picker__container__include-time, .picker__container__calonly, .picker__container__timeonly {
	margin-top: 5px;
	border: solid 1px #0000bc;
	background-color: #FFFFFF;
	padding: 20px 35px;
	box-shadow: none;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loginAndForgotPage_container__HCIFD {
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}

@media (min-width: 768px) {
	.loginAndForgotPage_container__HCIFD {
		padding: 0 10px;
		max-width: 720px;
	}
}

@media (min-width: 1024px) {
	.loginAndForgotPage_container__HCIFD {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.loginAndForgotPage_container__HCIFD {
		max-width: 1200px;
	}
}

.loginAndForgotPage_login__CoUms {
	position: relative;
	z-index: 5;
	padding: 50px 0;
	min-height: 100vh;
	background: #060606 url(/static/media/login.2a0fc9b5.jpg) 50% 50% no-repeat;
	background-size: cover;
}

.loginAndForgotPage_login__CoUms::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
}

.loginAndForgotPage_content__3Kkml {
	margin: 0 0 0 auto;
	max-width: 460px;
}

.loginAndForgotPage_title__38SUy {
	margin-bottom: 28px;
	font-weight: 200;
	font-size: 38px;
	line-height: 50px;
}

@media (min-width: 768px) {
	.loginAndForgotPage_title__38SUy {
		font-size: 50px;
		line-height: 70px;
	}
}

@media (min-width: 1200px) {
	.loginAndForgotPage_title__38SUy {
		font-size: 60px;
		line-height: 80px;
	}
}

.loginAndForgotPage_loginLabel__mi2vc {
	margin-bottom: 22px;
	font-size: 22px;
}

.loginAndForgotPage_form__2QWeA {
	max-width: 360px;
}

.loginAndForgotPage_buttonWrapper__23rnv {
	margin-top: 30px;
}

.loginAndForgotPage_buttonWrapper__23rnv .loginAndForgotPage_btn__3UN5D {
	border-color: #fff;
	color: #fff;
	fill: #fff;
}

.loginAndForgotPage_buttonWrapper__23rnv .loginAndForgotPage_btn__3UN5D::before {
	border-top-color: #fff;
}

.loginAndForgotPage_buttonWrapper__23rnv .loginAndForgotPage_btn__3UN5D::after {
	border-right-color: #fff;
}

.loginAndForgotPage_buttonWrapper__23rnv .loginAndForgotPage_btn__3UN5D i {
	border-bottom-color: #fff;
	border-left-color: #fff;
}

.loginAndForgotPage_buttonWrapper__23rnv .loginAndForgotPage_btn__3UN5D:hover {
	color: #333;
	fill: #333;
	background: #fff;
}

@media (min-width: 768px) {
	.loginAndForgotPage_buttonWrapper__23rnv {
		margin-top: 56px;
	}
}

.loginAndForgotPage_link__8CDUH {
	margin-top: 28px;
	font-weight: 400;
	font-size: 16px;
}

.loginAndForgotPage_link__8CDUH a {
	text-decoration: none;
	color: inherit;
}

.loginAndForgotPage_link__8CDUH a:hover {
	text-decoration: underline;
}

.loginAndForgotPage_login__CoUms .loginAndForgotPage_input__jot-G input {
	border-color: #fff;
	color: #fff;
}
.loginAndForgotPage_login__CoUms .loginAndForgotPage_input__jot-G svg {
	fill: #fff!important;
}

.loginAndForgotPage_login__CoUms .loginAndForgotPage_input__jot-G input::-webkit-input-placeholder {
	color: #fff;
	opacity: 0.5;
}

.loginAndForgotPage_login__CoUms .loginAndForgotPage_input__jot-G input:-ms-input-placeholder {
	color: #fff;
	opacity: 0.5;
}

.loginAndForgotPage_login__CoUms .loginAndForgotPage_input__jot-G input::-ms-input-placeholder {
	color: #fff;
	opacity: 0.5;
}

.loginAndForgotPage_login__CoUms .loginAndForgotPage_input__jot-G input::placeholder {
	color: #fff;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.loginAndForgotPage_login__CoUms {
		display: flex;
		align-items: center;
	}
}


.global_container__3sag0 {
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}

@media (min-width: 768px) {
	.global_container__3sag0 {
		padding: 0 10px;
		max-width: 720px;
	}
}

@media (min-width: 1024px) {
	.global_container__3sag0 {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.global_container__3sag0 {
		max-width: 1200px;
	}
}

.global_content__3eDzw {
	padding: 30px 0;
}

@media (min-width: 768px) {
	.global_content__3eDzw {
		padding: 100px 0;
	}
}

.global_wrapperContent__39UiY {
	position: relative;
	flex: 1 1;
	width: 100%;
}

.global_wrapperContentWhite__1Br2B {
	padding: 20px 15px 30px;
	color: #0000bc;
	background: #fff;
}

@media (min-width: 768px) {
	.global_wrapperContentWhite__1Br2B {
		padding: 25px 30px;
	}
}

@media (min-width: 1024px) {
	.global_wrapperContentWhite__1Br2B {
		padding: 53px 60px;
	}
}

@media (min-width: 768px) {
	.global_wrapper__1VZlH {
		display: flex;
	}
}

@media (min-width: 768px) {
	.global_visibleXs__2VBKQ {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.global_hiddenXs__3HmG9 {
		display: none !important;
	}
}

.global_close__1VHZI {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 5;
	outline: none;
	border: none;
	width: 25px;
	height: 25px;
	background: none;
}

.global_close__1VHZI::before, .global_close__1VHZI::after {
	content: "";
	position: absolute;
	left: 10%;
	top: 50%;
	width: 80%;
	height: 1px;
	background: #0000bc;
}

.global_close__1VHZI::before {
	transform: rotate(45deg);
}

.global_close__1VHZI::after {
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.global_close__1VHZI {
		right: 20px;
		top: 20px;
	}
}

.global_back__GF9o6 {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 5;
	outline: none;
	border: none;
	padding: 5px;
	width: 25px;
	height: 25px;
	fill: #0000bc;
	background: none;
}

.global_back__GF9o6 svg {
	display: block;
	width: 100%;
	height: 100%;
	transform: rotate(180deg);
}

@media (min-width: 768px) {
	.global_back__GF9o6 {
		left: 20px;
		top: 20px;
	}
}

.global_title__3um9- {
	margin: 0 0 30px;
	font-weight: 200;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.global_titleAdd__1aXBp {
	display: inline-block;
	vertical-align: middle;
	margin: -4px 0 0 15px;
	text-decoration: none;
}

.global_titleAdd__1aXBp svg {
	display: block;
	width: 26px;
	height: 26px;
}

@media (min-width: 768px) {
	.global_titleAdd__1aXBp svg {
		width: 32px;
		height: 32px;
	}
}

@media (min-width: 1024px) {
	.global_titleAdd__1aXBp svg {
		width: 38px;
		height: 38px;
	}
}

@media (min-width: 768px) {
	.global_titleAdd__1aXBp {
		margin: -6px 0 0 20px;
	}
}

@media (min-width: 1024px) {
	.global_titleAdd__1aXBp {
		margin: -8px 0 0 24px;
	}
}

@media (min-width: 768px) {
	.global_title__3um9- {
		font-size: 30px;
		line-height: 40px;
	}
}

@media (min-width: 1024px) {
	.global_title__3um9- {
		margin-bottom: 37px;
		font-size: 36px;
		line-height: 50px;
		letter-spacing: 3px;
	}
}

.global_row__2cyWt {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
}

@media (min-width: 768px) {
	.global_row__2cyWt {
		margin: 0 -10px;
	}
}

label.global_labelError__7NZ8y {
	display: block;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.global_col__HcaU2 {
	padding: 0 5px;
}

@media (min-width: 768px) {
	.global_col__HcaU2 {
		padding: 0 10px;
	}
}

.global_col6__4oeqZ {
	padding: 0 5px;
	width: 50%;
}

@media (min-width: 768px) {
	.global_col6__4oeqZ {
		padding: 0 10px;
	}
}

.global_colSm4__3zzYH {
	padding: 0 5px;
	width: 100%;
}

@media (min-width: 768px) {
	.global_colSm4__3zzYH {
		padding: 0 10px;
		width: 33.33334%;
	}
}

.global_colMd4__1Bcbm {
	padding: 0 5px;
	width: 100%;
}

@media (min-width: 768px) {
	.global_colMd4__1Bcbm {
		padding: 0 10px;
	}
}

@media (min-width: 1024px) {
	.global_colMd4__1Bcbm {
		width: 33.33334%;
	}
}

.global_btnSubmit__y3C0A {
	margin-top: 60px;
}

.global_kit__JYEHT {
	position: relative;
}

.global_kitRemove__2JooI {
	position: absolute;
	right: -5px;
	top: 30px;
	outline: none;
	border: none;
	padding: 0;
	color: #0000bc;
	fill: #0000bc;
	background: none;
	cursor: pointer;
}
.global_kitRemoveEdited__i5goh {
	right: 5px;
}

.global_kitRemove__2JooI:hover {
	color: #0000bc;
	fill: #0000bc;
}

.global_kitRemove__2JooI svg {
	display: block;
	width: 25px;
	height: 25px;
	right: 0;
	top: 0;
}

@media (min-width: 768px) {
	.global_kitRemove__2JooI {
		right: -50px;
		top: 35px;
	}
	.global_kitRemoveEdited__i5goh {
		right: -45px;
		top: 15px;
	}
}

@media (max-width: 768px) {
	.global_kitRemove__2JooI {
		right: -50px;
		top: 35px;
	}
	.global_kitRemoveEdited__i5goh {
		right: -30px;
		top: 15px;
	}
}

.global_disabledForm__1vMDT {
	opacity: 0.3;
	pointer-events: none;
}

.global_formButtonWrapper__H65rl {
	margin-right: 30px;
}

.global_balanceLine__q-kQ_ {
	display: flex;
	width: 100%;
	justify-content: space-around;
}


.input_input__xN9HW {
	position: relative;
	margin-bottom: 20px;
}

.input_labelWrapper__17psu {
	margin-top: 24px;
}

.input_labelWrapper__17psu .input_label__2ffJP {
	position: absolute;
	top: -24px;
	left: 0;
}

.input_input__xN9HW .input_inputLabel__28rQk {
	margin-bottom: 5px;
	font-size: 12px;
}

.input_input__xN9HW .input_inputContainer__3yQYU {
	position: relative;
}

.input_input__xN9HW input {
	display: block;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
	line-height: 60px;
	background: transparent;
}

.input_label__2ffJP {
	margin-bottom: 5px;
	font-size: 12px;
}

.input_input__xN9HW input::-webkit-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.input_input__xN9HW input:-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.input_input__xN9HW input::-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.input_input__xN9HW input::placeholder {
	color: #0000bc;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.input_input__xN9HW input {
		height: 60px;
	}
}

.input_inputIcon__3br-l {
	position: absolute;
	right: 16px;
	top: 12px;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	pointer-events: none;
	z-index: 12;
}
.input_inputIcon__3br-l.input_active__3AkN1 {
	cursor: pointer;
	pointer-events: all;
}

@media (min-width: 768px) {
	.input_inputIcon__3br-l {
		top: 17px;
	}
}

.input_inputShowPassword__bpK8v {
	position: absolute;
	right: 16px;
	top: 12px;
	outline: none;
	border: none;
	padding: 0;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	background: none;
}

.input_inputShowPassword__bpK8v svg {
	display: block;
	width: 26px;
	height: 26px;
}

.input_inputShowPassword__bpK8v.input_isActive__75LzC::after {
	content: "";
	position: absolute;
	left: 10%;
	top: 50%;
	width: 80%;
	height: 1px;
	box-shadow: 0 0 0 1px #fff;
	background: #0000bc;
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.input_inputShowPassword__bpK8v {
		top: 17px;
	}
}

.input_input__xN9HW label.input_error__t73pz {
	display: block;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.input_inputCarNumber__2jzd5 {
	display: flex;
}

.input_inputCarNumber__2jzd5 input {
	padding-right: 10px;
	padding-left: 10px;
	width: 61%;
	text-align: center;
}

.input_inputCarNumber__2jzd5 input + input {
	border-left: none;
	width: 39%;
}


.button_btn__2jkq9 {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-left: 9px;
	outline: none;
	border: solid 1px #0000bc;
	padding: 16px 20px 12px;
	min-width: 200px;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	text-align: left;
	text-decoration: none;
	color: #0000bc;
	fill: #0000bc;
	background: transparent;
	transition: 0.25s;
}

.button_btn__2jkq9::before, .button_btn__2jkq9::after {
	content: "";
	position: absolute;
	transition: 0.25s;
}

.button_btn__2jkq9::before {
	right: 100%;
	top: 9px;
	border-top: solid 1px #0000bc;
	width: 10px;
	height: 1px;
}

.button_btn__2jkq9::after {
	right: 9px;
	top: 100%;
	border-right: solid 1px #0000bc;
	width: 1px;
	height: 10px;
}

.button_btn__2jkq9 i {
	position: absolute;
	right: 10px;
	top: 10px;
	border-bottom: solid 1px #0000bc;
	border-left: solid 1px #0000bc;
	width: 100%;
	height: 100%;
	transition: 0.25s;
}

.button_btnArrow__gtpI_ {
	position: absolute;
	right: 20px;
	top: 50%;
	width: 15px;
	height: 14px;
	transform: translateY(-50%);
}

.button_btn__2jkq9:hover {
	color: #fff;
	fill: #fff;
	background: #0000bc;
}

.button_btn__2jkq9:active::before {
	top: 4px;
	width: 5px;
}

.button_btn__2jkq9:active::after {
	right: 4px;
	height: 5px;
}

.button_btn__2jkq9:active i {
	right: 5px;
	top: 5px;
}

.button_btn__2jkq9:disabled {
	opacity: 0.3;
	pointer-events: none;
}

.button_btn__2jkq9:disabled:hover {
	background: transparent;
}

@media (min-width: 768px) {
	.button_btn__2jkq9 {
		padding: 19px 20px;
	}
}

.button_btnPrimary__3pJRp {
	border-color: #333;
	color: #333;
	fill: #333;
}

.button_btnPrimary__3pJRp::before, .button_btnPrimary__3pJRp::after {
	border-color: #333;
}

.button_btnPrimary__3pJRp i {
	border-color: #333;
}

.button_btnPrimary__3pJRp:hover {
	color: #fff;
	fill: #fff;
	background: #333;
}

.button_addBtn__2IaK5.button_btn__2jkq9 {
	position: relative;
	margin: 0;
	padding-left: 45px;
	width: 100%;
	font-size: 16px;
	letter-spacing: 0.5px;
}

.button_addBtn__2IaK5.button_btn__2jkq9::before, .button_addBtn__2IaK5.button_btn__2jkq9::after {
	content: "";
	position: absolute;
	top: 50%;
	border: none;
	background: #0000bc;
}

.button_addBtn__2IaK5.button_btn__2jkq9::before {
	left: 12px;
	margin-top: -1px;
	width: 19px;
	height: 1px;
}

@media (min-width: 1200px) {
	.button_addBtn__2IaK5.button_btn__2jkq9::before {
		left: 22px;
	}
}

.button_addBtn__2IaK5.button_btn__2jkq9::after {
	left: 21px;
	margin-top: -10px;
	width: 1px;
	height: 19px;
}

@media (min-width: 1200px) {
	.button_addBtn__2IaK5.button_btn__2jkq9::after {
		left: 31px;
	}
}

.button_addBtn__2IaK5.button_btn__2jkq9 i {
	display: none;
}

.button_addBtn__2IaK5.button_btn__2jkq9:hover::before, .button_addBtn__2IaK5.button_btn__2jkq9:hover::after {
	background: #fff;
}

@media (min-width: 768px) {
	.button_addBtn__2IaK5.button_btn__2jkq9 {
		width: auto;
	}
}

@media (min-width: 1024px) {
	.button_addBtn__2IaK5.button_btn__2jkq9 {
		padding-right: 10px;
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.button_addBtn__2IaK5.button_btn__2jkq9 {
		padding-left: 65px;
		font-size: 18px;
	}
}

.button_onlyIcon__Wk3Vf {
	min-width: auto;
	border: 0;
	padding: 0;
	position: absolute;
}

.button_onlyIcon__Wk3Vf i, .button_onlyIcon__Wk3Vf:before, .button_onlyIcon__Wk3Vf:after {
	display: none;
}

.button_onlyIcon__Wk3Vf svg {
	display: block;
	width: 25px;
	height: 25px;
}

@media (min-width: 768px) {
	.button_kitRemove__4GRji {
		right: -50px;
		top: 35px;
	}
}


.car_input__69abu {
	position: relative;
	margin-bottom: 20px;
}

.car_input__69abu .car_inputLabel__3Twrd {
	margin-bottom: 5px;
	font-size: 12px;
}

.car_input__69abu .car_inputContainer__2bo_3 {
	position: relative;
}

.car_input__69abu input {
	display: block;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
	line-height: 60px;
	background: transparent;
}

.car_input__69abu input::-webkit-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.car_input__69abu input:-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.car_input__69abu input::-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.car_input__69abu input::placeholder {
	color: #0000bc;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.car_input__69abu input {
		height: 60px;
	}
}
.car_inputIcon__1m-HV {
	position: absolute;
	right: 16px;
	top: 12px;
	width: 26px;
	height: 26px;
	fill: #fff;
	pointer-events: none;
}

@media (min-width: 768px) {
	.car_inputIcon__1m-HV {
		top: 17px;
	}
}

.car_inputShowPassword__3Q9CZ {
	position: absolute;
	right: 16px;
	top: 12px;
	outline: none;
	border: none;
	padding: 0;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	background: none;
}

.car_inputShowPassword__3Q9CZ svg {
	display: block;
	width: 26px;
	height: 26px;
}

.car_inputShowPassword__3Q9CZ.car_isActive__3cERc::after {
	content: "";
	position: absolute;
	left: 10%;
	top: 50%;
	width: 80%;
	height: 1px;
	box-shadow: 0 0 0 1px #fff;
	background: #0000bc;
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.car_inputShowPassword__3Q9CZ {
		top: 17px;
	}
}

.car_row__3o5YM label.car_error__2VmdZ {
	display: block;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.car_inputCarNumber__16sph {
	display: flex;
}

.car_inputCarNumber__16sph input {
	padding-right: 10px;
	padding-left: 10px;
	width: 61%;
	text-align: center;
}

.car_inputCarNumber__16sph input + input {
	border-left: none;
	width: 39%;
}


.select_select__17ev_ {
	position: relative;
	margin-bottom: 20px;
}

.select_labelWrapper__28yz- {
	margin-top: 24px;
}

.select_description__3CyZz {

}

.select_labelWrapper__28yz- .select_label__1Niqo {
	position: absolute;
	top: -19px;
	left: 0;
}

.select_selectContainer__1dBmw {
	position: relative;
	display: flex;
	flex-direction: column;
}

.select_selected__it9NI .select2-container--default .select2-selection--single .select2-selection__rendered{
	color: #ffffff;
	background-color: #0000bc;
}

.select_selected__it9NI .select2-container--default .select2-selection--single .select2-selection__arrow b{
	border-right: solid 1px #ffffff !important;
	border-bottom: solid 1px #ffffff !important;
}

.select_label__1Niqo {
	margin-bottom: 5px;
	font-size: 12px;
}

.select_select__17ev_ label.select_error__3UCKS {
	display: block;
	order: 3;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.select_selectSearch__2Y-X7 .select2-results__option {
	padding-left: 65px;
}

.select_descriptionText__4Bg2V {
	margin-top: 20px;
	padding: 12px;
	opacity: 0.56;
}


.date_input__2LkMl {
	position: relative;
	margin-bottom: 20px;
}

.date_labelWrapper__Mml7J {
	margin-top: 24px;
}

.date_labelWrapper__Mml7J .date_label__2My5j {
	position: absolute;
	top: -24px;
	left: 0;
}

.date_input__2LkMl .date_inputLabel__mFN4j {
	margin-bottom: 5px;
	font-size: 12px;
}

.date_input__2LkMl .date_inputContainer__3e3Nt {
	position: relative;
}

.date_input__2LkMl input {
	display: block;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
	line-height: 60px;
	background: transparent;
}

.date_label__2My5j {
	margin-bottom: 5px;
	font-size: 12px;
}

.date_input__2LkMl input::-webkit-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.date_input__2LkMl input:-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.date_input__2LkMl input::-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.date_input__2LkMl input::placeholder {
	color: #0000bc;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.date_input__2LkMl input {
		height: 60px;
	}
}
.date_inputIcon__dkrj_ {
	position: absolute;
	right: 16px;
	top: 12px;
	width: 26px;
	height: 26px;
	fill: #fff;
	pointer-events: none;
}

@media (min-width: 768px) {
	.date_inputIcon__dkrj_ {
		top: 17px;
	}
}

.date_inputShowPassword__2BwM- {
	position: absolute;
	right: 16px;
	top: 12px;
	outline: none;
	border: none;
	padding: 0;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	background: none;
}

.date_inputShowPassword__2BwM- svg {
	display: block;
	width: 26px;
	height: 26px;
}

.date_inputShowPassword__2BwM-.date_isActive__2RtuX::after {
	content: "";
	position: absolute;
	left: 10%;
	top: 50%;
	width: 80%;
	height: 1px;
	box-shadow: 0 0 0 1px #fff;
	background: #0000bc;
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.date_inputShowPassword__2BwM- {
		top: 17px;
	}
}

.date_input__2LkMl label.date_error__2h_H_ {
	display: block;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.date_inputCarNumber__2ziN3 {
	display: flex;
}

.date_inputCarNumber__2ziN3 input {
	padding-right: 10px;
	padding-left: 10px;
	width: 61%;
	text-align: center;
}

.date_inputCarNumber__2ziN3 input + input {
	border-left: none;
	width: 39%;
}


.range_input__gAfbQ {
	position: relative;
	margin-bottom: 20px;
}

.range_labelWrapper__3FY-Y {
	margin-top: 24px;
}

.range_labelWrapper__3FY-Y .range_label__ei6Xb {
	position: absolute;
	top: -24px;
	left: 0;
}

.range_input__gAfbQ .range_inputLabel__2_bph {
	margin-bottom: 5px;
	font-size: 12px;
}

.range_input__gAfbQ .range_inputContainer__24SCd {
	position: relative;
}

.range_input__gAfbQ input {
	display: block;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
	line-height: 60px;
	background: transparent;
}

.range_label__ei6Xb {
	margin-bottom: 5px;
	font-size: 12px;
}

.range_input__gAfbQ input::-webkit-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.range_input__gAfbQ input:-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.range_input__gAfbQ input::-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.range_input__gAfbQ input::placeholder {
	color: #0000bc;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.range_input__gAfbQ input {
		height: 60px;
	}
}
.range_inputIcon__gkcHr {
	position: absolute;
	right: 16px;
	top: 12px;
	width: 26px;
	height: 26px;
	fill: #fff;
	pointer-events: none;
}

@media (min-width: 768px) {
	.range_inputIcon__gkcHr {
		top: 17px;
	}
}

.range_inputShowPassword__c6VXz {
	position: absolute;
	right: 16px;
	top: 12px;
	outline: none;
	border: none;
	padding: 0;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	background: none;
}

.range_inputShowPassword__c6VXz svg {
	display: block;
	width: 26px;
	height: 26px;
}

.range_inputShowPassword__c6VXz.range_isActive__ORFCk::after {
	content: "";
	position: absolute;
	left: 10%;
	top: 50%;
	width: 80%;
	height: 1px;
	box-shadow: 0 0 0 1px #fff;
	background: #0000bc;
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.range_inputShowPassword__c6VXz {
		top: 17px;
	}
}

.range_input__gAfbQ label.range_error__1jpZg {
	display: block;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.range_inputCarNumber__110St {
	display: flex;
}

.range_inputCarNumber__110St input {
	padding-right: 10px;
	padding-left: 10px;
	width: 61%;
	text-align: center;
}

.range_inputCarNumber__110St input + input {
	border-left: none;
	width: 39%;
}

.checkbox_wrapper__5Rakx {
	margin-bottom: 24px;
	position: relative;
}

.checkbox_labelWrapper__3LzDX {
	margin-top: 24px;
}

.checkbox_labelWrapper__3LzDX .checkbox_label__1iUuK {
	position: absolute;
	top: -24px;
	left: 0;
}

.checkbox_checkbox__BtPyC {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 5px;
	height: 32px;
}

.checkbox_checkbox__BtPyC input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.checkbox_checkbox__BtPyC input+label {
	display: inline-flex;
	align-items: center;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	cursor: pointer;
}

.checkbox_checkbox__BtPyC input+label::before {
	content: '';
	display: inline-block;
	width: 19px;
	height: 20px;
	flex-shrink: 0;
	flex-grow: 0;
	border: 1px solid #0000bc;
	border-radius: 0;
	margin-right: 12px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 70%;
	transition: all .2s ease-out;
}
.checkbox_checkbox__BtPyC input:checked+label::before {
	border-color: #0000bc;
	background-color: #0000bc;
	background-image: url("data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTUuNTU2IDUxNS41NTYiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTE1LjU1NiA1MTUuNTU2IiB3aWR0aD0iNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDI3NC4yMjYgMTc2LjU0OSAxNzYuODg2IDMzOS4wMDctMzM4LjY3Mi00OC42Ny00Ny45OTctMjkwLjMzNyAyOTAtMTI4LjU1My0xMjguNTUyeiIgZmlsbD0iI2ZmZmZmZiIvPjwvc3ZnPg==");
}

.checkbox_button__3ZhOb {
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
	background: transparent;
	line-height: 18px;
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.checkbox_inputIcon__2_WXk {
	position: absolute;
	right: 16px;
	top: 20px;
	width: 18px;
	height: 18px;
	fill: #0000bc;
	pointer-events: none;
	z-index: 12;
}

.checkbox_active__3vB2A .checkbox_inputIcon__2_WXk {
	transform: rotate(180deg);
}

.checkbox_checkboxInner__1K429 {

}

.checkbox_checkboxInner__1K429.checkbox_checkboxDropdown__1caPz {
	display: none;
	position: absolute;
	border: solid 1px #0000bc;
	padding: 12px;
	background-color: #FFFFFF;
	z-index: 200;
	min-width: 100%;
	margin-top: 5px;
}
.checkbox_active__3vB2A .checkbox_checkboxInner__1K429.checkbox_checkboxDropdown__1caPz{
	display: block;
}

.checkbox_label__1iUuK {
	margin-bottom: 5px;
	font-size: 12px;
}


.smallSwitch_smallSwitch__AAM6c {
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 20px;
	cursor: pointer;
}

@media (min-width: 768px) {
	.smallSwitch_smallSwitch__AAM6c + .smallSwitch_smallSwitch__AAM6c {
		margin-left: 30px;
	}
}

.smallSwitch_smallSwitch__AAM6c input {
	display: none;
}

.smallSwitch_smallSwitch__AAM6c input:checked + .smallSwitch_smallSwitchIcon__2lvqt {
	background: #0000bc;
}

.smallSwitch_smallSwitch__AAM6c input:checked + .smallSwitch_smallSwitchIcon__2lvqt::before {
	left: 36px;
	background: #fff;
}

.smallSwitch_smallSwitch__AAM6c input:checked + .smallSwitch_smallSwitchIcon__2lvqt::after {
	opacity: 1;
	visibility: visible;
}

.smallSwitch_smallSwitch__AAM6c input:checked + .smallSwitch_smallSwitchIcon__2lvqt i {
	opacity: 0;
	visibility: hidden;
}

.smallSwitch_smallSwitchIcon__2lvqt {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	border: solid 1px #0000bc;
	border-radius: 20px;
	width: 60px;
	height: 30px;
	background: #fff;
	transition: 0.25s;
}

.smallSwitch_smallSwitchIcon__2lvqt::before {
	content: "";
	position: absolute;
	left: 4px;
	top: 5px;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	background: #0000bc;
	transition: 0.25s;
}

.smallSwitch_smallSwitchIcon__2lvqt::after {
	content: "";
	position: absolute;
	left: 12px;
	top: 5px;
	border-right: solid 1px #fff;
	border-bottom: solid 1px #fff;
	width: 8px;
	height: 14px;
	opacity: 0;
	visibility: hidden;
	transform: rotate(38deg);
	transition: 0.25s;
}

.smallSwitch_smallSwitchIcon__2lvqt i {
	position: absolute;
	right: 7px;
	top: 5px;
	width: 17px;
	height: 17px;
	transition: 0.25s;
}

.smallSwitch_smallSwitchIcon__2lvqt i::before, .smallSwitch_smallSwitchIcon__2lvqt i::after {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	height: 1px;
	background: #0000bc;
}

.smallSwitch_smallSwitchIcon__2lvqt i::before {
	transform: rotate(45deg);
}

.smallSwitch_smallSwitchIcon__2lvqt i::after {
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.smallSwitch_smallSwitchIcon__2lvqt {
		width: 60px;
		height: 30px;
	}
}

.smallSwitch_smallSwitchLabel__3PaoS {
	display: inline-block;
	vertical-align: middle;
	margin-left: 8px;
	padding-top: 2px;
	font-size: 14px;
	letter-spacing: 0.5px;
}

@media (min-width: 768px) {
	.smallSwitch_smallSwitchLabel__3PaoS {
		font-size: 16px;
	}
}

.smallSwitch_smallSwitchInRow__1sGKo {
	margin-top: 40px;
}


.carMini_input__2pN3o {
	position: relative;
	margin-bottom: 20px;
}

.carMini_input__2pN3o .carMini_inputLabel__3MzC6 {
	margin-bottom: 5px;
	font-size: 12px;
}

.carMini_input__2pN3o .carMini_inputContainer__1JPLf {
	position: relative;
}

.carMini_input__2pN3o input {
	display: block;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
	line-height: 60px;
	background: transparent;
}

.carMini_input__2pN3o input::-webkit-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.carMini_input__2pN3o input:-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.carMini_input__2pN3o input::-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.carMini_input__2pN3o input::placeholder {
	color: #0000bc;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.carMini_input__2pN3o input {
		height: 60px;
	}
}
.carMini_inputIcon__u40FM {
	position: absolute;
	right: 16px;
	top: 12px;
	width: 26px;
	height: 26px;
	fill: #fff;
	pointer-events: none;
}

@media (min-width: 768px) {
	.carMini_inputIcon__u40FM {
		top: 17px;
	}
}

.carMini_inputShowPassword__1hIzg {
	position: absolute;
	right: 16px;
	top: 12px;
	outline: none;
	border: none;
	padding: 0;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	background: none;
}

.carMini_inputShowPassword__1hIzg svg {
	display: block;
	width: 26px;
	height: 26px;
}

.carMini_inputShowPassword__1hIzg.carMini_isActive__1u_bz::after {
	content: "";
	position: absolute;
	left: 10%;
	top: 50%;
	width: 80%;
	height: 1px;
	box-shadow: 0 0 0 1px #fff;
	background: #0000bc;
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.carMini_inputShowPassword__1hIzg {
		top: 17px;
	}
}

.carMini_row__Ir3sz label.carMini_error__1te69 {
	display: block;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.carMini_inputCarNumber__2jXrv {
	display: flex;
}

.carMini_inputCarNumber__2jXrv input {
	padding-right: 10px;
	padding-left: 10px;
	width: 100%;
}


.dateSwitch_smallSwitch__CCIEa {
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 20px;
	cursor: pointer;
}

@media (min-width: 768px) {
	.dateSwitch_smallSwitch__CCIEa + .dateSwitch_smallSwitch__CCIEa {
		margin-left: 30px;
	}
}

.dateSwitch_smallSwitch__CCIEa input {
	display: none;
}

.dateSwitch_smallSwitch__CCIEa input:checked + .dateSwitch_smallSwitchIcon__3sT43 {
	background: #0000bc;
}

.dateSwitch_smallSwitch__CCIEa input:checked + .dateSwitch_smallSwitchIcon__3sT43::before {
	left: 36px;
	background: #fff;
}

.dateSwitch_smallSwitch__CCIEa input:checked + .dateSwitch_smallSwitchIcon__3sT43::after {
	opacity: 1;
	visibility: visible;
}

.dateSwitch_smallSwitch__CCIEa input:checked + .dateSwitch_smallSwitchIcon__3sT43 i {
	opacity: 0;
	visibility: hidden;
}

.dateSwitch_smallSwitchIcon__3sT43 {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	border: solid 1px #0000bc;
	border-radius: 20px;
	width: 60px;
	height: 30px;
	background: #fff;
	transition: 0.25s;
}

.dateSwitch_smallSwitchIcon__3sT43::before {
	content: "";
	position: absolute;
	left: 4px;
	top: 5px;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	background: #0000bc;
	transition: 0.25s;
}

.dateSwitch_smallSwitchIcon__3sT43::after {
	content: "";
	position: absolute;
	left: 12px;
	top: 5px;
	border-right: solid 1px #fff;
	border-bottom: solid 1px #fff;
	width: 8px;
	height: 14px;
	opacity: 0;
	visibility: hidden;
	transform: rotate(38deg);
	transition: 0.25s;
}

.dateSwitch_smallSwitchIcon__3sT43 i {
	position: absolute;
	right: 7px;
	top: 5px;
	width: 17px;
	height: 17px;
	transition: 0.25s;
}

.dateSwitch_smallSwitchIcon__3sT43 i::before, .dateSwitch_smallSwitchIcon__3sT43 i::after {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	height: 1px;
	background: #0000bc;
}

.dateSwitch_smallSwitchIcon__3sT43 i::before {
	transform: rotate(45deg);
}

.dateSwitch_smallSwitchIcon__3sT43 i::after {
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.dateSwitch_smallSwitchIcon__3sT43 {
		width: 60px;
		height: 30px;
	}
}

.dateSwitch_smallSwitchLabel__1WTZd {
	display: inline-block;
	vertical-align: middle;
	margin-left: 8px;
	padding-top: 2px;
	font-size: 14px;
	letter-spacing: 0.5px;
}

@media (min-width: 768px) {
	.dateSwitch_smallSwitchLabel__1WTZd {
		font-size: 16px;
	}
}

.dateSwitch_smallSwitchInRow__23-4y {
	margin-top: 40px;
}

.modal_wrapper__3cdkq {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.modal_mask__1Y8fq {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.39);
	z-index: 999;
}

.modal_container__3GddM {
	position: relative;
	z-index: 1001;
	border: none;
	outline: none;
	background-color: #ffffff;
	max-height: 100%;
	max-width: 360px;
	width: 100%;
	padding: 25px 20px;
	color: #0000bc;
}

.modal_inner__j9MoL {
	margin: auto;
	width: 438px;
}

.modal_content__1tQ-X {
	padding: 0 131px;
}

.modal_header__2iX3r {
	text-align: center;
	padding-top: 49px;
	padding-bottom: 49px;
}

.modal_searchIcon__3T5B9 {
	width: 17px;
	height: 18px;
	position: absolute;
	top: 30px;
	left: 84px;
	right: auto;
	cursor: pointer;
	opacity: 0.7;
	fill: #5c5c5c;
	stroke: #5c5c5c;
	z-index: 20;
}

.modal_title__1efUU {
	margin-bottom: 26px;
	font-size: 22px;
}

.modal_text__1Kop1 {
	font-size: 18px;
	line-height: 26px;
	letter-spacing: 0.5px;
}

.modal_buttonWrapper__zKuf_ {
	display: flex;
	justify-content: space-between;
}

.modal_footer__3qJQe, .modal_cancel__2fC-W {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	cursor: pointer;
	text-transform: uppercase;
}

.modal_footer__3qJQe:hover, .modal_cancel__2fC-W:hover {
	text-decoration: underline;
}

.modal_cancel__2fC-W {
	color: #a3a3a3;
}

.boolButton_wrapper__2caMA {
	margin-bottom: 24px;
	position: relative;
}

.boolButton_labelWrapper__A_gXx {
	margin-top: 24px;
}

.boolButton_button__Vb8J7 {
	display: block;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: rgba(0, 0, 188, 0.29);
	background: transparent;
	line-height: 54px;
	position: relative;
	cursor: pointer;
}

.boolButton_active__2cjMy .boolButton_button__Vb8J7 {
	color: #0000bc;
}


.autocomplete_input__2aLz8 {
	position: relative;
	margin-bottom: 20px;
}

.autocomplete_labelWrapper__32FY0 {
	margin-top: 24px;
}

.autocomplete_labelWrapper__32FY0 .autocomplete_label__1c5UE {
	position: absolute;
	top: -24px;
	left: 0;
}

.autocomplete_input__2aLz8 .autocomplete_inputLabel__8MOWT {
	margin-bottom: 5px;
	font-size: 12px;
}

.autocomplete_input__2aLz8 .autocomplete_inputContainer__1x9Tj {
	position: relative;
}

.autocomplete_input__2aLz8 input {
	display: block;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
	line-height: 60px;
	background: transparent;
}

.autocomplete_label__1c5UE {
	margin-bottom: 5px;
	font-size: 12px;
}

.autocomplete_input__2aLz8 input::-webkit-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.autocomplete_input__2aLz8 input:-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.autocomplete_input__2aLz8 input::-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.autocomplete_input__2aLz8 input::placeholder {
	color: #0000bc;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.autocomplete_input__2aLz8 input {
		height: 60px;
	}
}

.autocomplete_inputIcon__3me2y {
	position: absolute;
	right: 16px;
	top: 12px;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	pointer-events: none;
	z-index: 12;
}
.autocomplete_inputIcon__3me2y.autocomplete_active__3axN4 {
	cursor: pointer;
	pointer-events: all;
}

@media (min-width: 768px) {
	.autocomplete_inputIcon__3me2y {
		top: 17px;
	}
}

.autocomplete_inputShowPassword__1eMXt {
	position: absolute;
	right: 16px;
	top: 12px;
	outline: none;
	border: none;
	padding: 0;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	background: none;
}

.autocomplete_inputShowPassword__1eMXt svg {
	display: block;
	width: 26px;
	height: 26px;
}

.autocomplete_inputShowPassword__1eMXt.autocomplete_isActive__32LV6::after {
	content: "";
	position: absolute;
	left: 10%;
	top: 50%;
	width: 80%;
	height: 1px;
	box-shadow: 0 0 0 1px #fff;
	background: #0000bc;
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.autocomplete_inputShowPassword__1eMXt {
		top: 17px;
	}
}

.autocomplete_input__2aLz8 label.autocomplete_error__2T0AS {
	display: block;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.autocomplete_inputCarNumber__ejQg3 {
	display: flex;
}

.autocomplete_inputCarNumber__ejQg3 input {
	padding-right: 10px;
	padding-left: 10px;
	width: 61%;
	text-align: center;
}

.autocomplete_inputCarNumber__ejQg3 input + input {
	border-left: none;
	width: 39%;
}

.autocomplete_suggestionsNo__3SWZz {
	color: #999;
	padding: 0.5rem;
}

.autocomplete_suggestions__3KqZm {
	list-style: none;
	margin-top: 0;
	max-height: 143px;
	overflow-y: auto;
	padding-left: 0;
	position: absolute;
	width: 100%;
	border: solid 1px #0000bc;
	border-top-width: 0;
	top: 60px;
	background-color: #fff;
	z-index: 2;
}

.autocomplete_suggestions__3KqZm li {
	padding: 0.5rem;
}

.autocomplete_suggestionActive__1KVct,
.autocomplete_suggestions__3KqZm li:hover {
	background-color: #0000bc;
	color: #ffffff;
	cursor: pointer;
	font-weight: 700;
}

.autocomplete_suggestions__3KqZm li:not(:last-of-type) {
	border-bottom: 1px solid #999;
}


.inputAndCardReader_input__UvVzR {
	position: relative;
	margin-bottom: 20px;
}

.inputAndCardReader_labelWrapper__3Wv0p {
	margin-top: 24px;
}

.inputAndCardReader_labelWrapper__3Wv0p .inputAndCardReader_label__1lAOt {
	position: absolute;
	top: -24px;
	left: 0;
}

.inputAndCardReader_input__UvVzR .inputAndCardReader_inputLabel__3K9-4 {
	margin-bottom: 5px;
	font-size: 12px;
}

.inputAndCardReader_input__UvVzR .inputAndCardReader_inputContainer__OOcQn {
	position: relative;
}

.inputAndCardReader_input__UvVzR input {
	display: block;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
	line-height: 60px;
	background: transparent;
}

.inputAndCardReader_label__1lAOt {
	margin-bottom: 5px;
	font-size: 12px;
}

.inputAndCardReader_input__UvVzR input::-webkit-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.inputAndCardReader_input__UvVzR input:-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.inputAndCardReader_input__UvVzR input::-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.inputAndCardReader_input__UvVzR input::placeholder {
	color: #0000bc;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.inputAndCardReader_input__UvVzR input {
		height: 60px;
	}
}

.inputAndCardReader_inputIcon__1C-v7 {
	position: absolute;
	right: 16px;
	top: 12px;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	pointer-events: none;
	z-index: 12;
}
.inputAndCardReader_inputIcon__1C-v7.inputAndCardReader_active__1G65x {
	cursor: pointer;
	pointer-events: all;
}

@media (min-width: 768px) {
	.inputAndCardReader_inputIcon__1C-v7 {
		top: 17px;
	}
}

.inputAndCardReader_inputShowPassword__316JZ {
	position: absolute;
	right: 16px;
	top: 12px;
	outline: none;
	border: none;
	padding: 0;
	width: 26px;
	height: 26px;
	fill: #0000bc;
	background: none;
}

.inputAndCardReader_inputShowPassword__316JZ svg {
	display: block;
	width: 26px;
	height: 26px;
}

.inputAndCardReader_inputShowPassword__316JZ.inputAndCardReader_isActive__3Kyrg::after {
	content: "";
	position: absolute;
	left: 10%;
	top: 50%;
	width: 80%;
	height: 1px;
	box-shadow: 0 0 0 1px #fff;
	background: #0000bc;
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.inputAndCardReader_inputShowPassword__316JZ {
		top: 17px;
	}
}

.inputAndCardReader_input__UvVzR label.inputAndCardReader_error__178sC {
	display: block;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.inputAndCardReader_inputCarNumber__NhTza {
	display: flex;
}

.inputAndCardReader_inputCarNumber__NhTza input {
	padding-right: 10px;
	padding-left: 10px;
	width: 61%;
	text-align: center;
}

.inputAndCardReader_inputCarNumber__NhTza input + input {
	border-left: none;
	width: 39%;
}

.inputAndCardReader_buttonCardReader__2mXCy {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 8px;
	right: -60px;
	cursor: pointer;
}

.inputAndCardReader_buttonCardReader__2mXCy path, .inputAndCardReader_buttonCardReader__2mXCy rect {
	fill: #0f02bb;
}

.inputAndCardReader_buttonCardReaderDisabled__2S-5r {
	opacity: 0.3;
	pointer-events: none;
	cursor: default;
}

.inputAndCardReader_buttonCardReaderActive__9lTX4 path, .inputAndCardReader_buttonCardReaderActive__9lTX4 rect {
	fill: #59d549;
}

.main_container__2FB0S {
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}

@media (min-width: 768px) {
	.main_container__2FB0S {
		padding: 0 10px;
		max-width: 720px;
	}
}

@media (min-width: 1024px) {
	.main_container__2FB0S {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.main_container__2FB0S {
		max-width: 1200px;
	}
}

.main_content__2Y6pH {
	padding: 30px 0;
}

@media (min-width: 768px) {
	.main_content__2Y6pH {
		padding: 100px 0;
	}
}


.main_wrapperContent__13yMv {
	position: relative;
	flex: 1 1;
	width: 100%;
}

.main_wrapperContentWhite__11hGI {
	padding: 20px 15px 30px;
	color: #0000bc;
	background: #fff;
}

@media (min-width: 768px) {
	.main_wrapperContentWhite__11hGI {
		padding: 25px 30px;
	}
}

@media (min-width: 1024px) {
	.main_wrapperContentWhite__11hGI {
		padding: 53px 60px;
	}
}

@media (min-width: 768px) {
	.main_wrapper__2LJ2K {
		display: flex;
	}
}

.main_side__mwJeZ {
	margin-bottom: 30px;
	flex-direction: column;
}


@media (min-width: 1024px) {
	.main_side__mwJeZ {
		height: 1325px;
		display: flex;
		justify-content: space-between;
	}
}


.main_close__1W4e1 {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 5;
	outline: none;
	border: none;
	width: 36px;
	height: 36px;
	background: none;
}

.main_close__1W4e1::before, .main_close__1W4e1::after {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	height: 2px;
	background: #fff;
}

.main_close__1W4e1::before {
	transform: rotate(45deg);
}

.main_close__1W4e1::after {
	transform: rotate(-45deg);
}

.main_name__302Jv {
	margin-bottom: 10px;
	font-size: 10px;
	letter-spacing: 2px;
	text-transform: uppercase;
	opacity: 0.7;
}

@media (min-width: 1200px) {
	.main_name__302Jv {
		font-size: 11px;
	}
}

.main_value__2cnX5 {
	font-size: 18px;
	line-height: 30px;
}

@media (min-width: 1200px) {
	.main_value__2cnX5 {
		font-size: 22px;
		line-height: 36px;
	}
}

.main_number__34J8L {
	font-weight: 200;
	font-size: 30px;
}

@media (min-width: 1200px) {
	.main_number__34J8L {
		margin-top: 13px;
		font-size: 40px;
	}
}

.main_footnote__1VuQT {
	font-size: 16px;
	letter-spacing: 0.5px;
}

.main_block__1lfzZ + .main_block__1lfzZ {
	margin-top: 27px;
}

.main_block__1lfzZ + .main_block--margin__2sjWI {
	margin-top: 63px;
}
.main_config__3Wizu {

}

.main_contact__3HWZ_ {

}

@media (max-width: 767px) {
	.main_side__mwJeZ {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 45;
		overflow: auto;
		padding: 30px 15px;
		width: 100%;
		height: 100%;
		background: #0000bc;
		opacity: 0;
		visibility: hidden;
		transition: 0.5s;
	}

	.main_isShowInfo__3dXWC .main_side__mwJeZ {
		opacity: 1;
		visibility: visible;
	}
}

@media (min-width: 768px) {
	.main_side__mwJeZ {
		flex-shrink: 0;
		margin: 0 15px 0 0;
		width: 160px;
	}
}

@media (min-width: 1024px) {
	.main_side__mwJeZ {
		width: 240px;
	}
}

@media (min-width: 1200px) {
	.main_side__mwJeZ {
		width: 275px;
	}
}

.main_sideBtn__2UvFy {
	display: block;
	border: solid 1px #fff;
	padding: 15px;
	text-align: center;
	text-decoration: none;
	color: #fff;
	margin-bottom: 30px;
}


.mainPage_index__zXYOD {
	margin: 0 0 -20px;
}

.mainPage_col__2sDLP {
	margin-bottom: 20px;
	text-decoration: none;
	display: block;
}

@media (min-width: 1024px) {
	.mainPage_colSm__19U0Q {
		width: 44.5%;
	}
}

@media (min-width: 1024px) {
	.mainPage_colMd__T_wHr {
		width: 55.5%;
	}
}

@media (min-width: 1024px) {
	.mainPage_colLg__2z1Hg {
		width: 100%;
	}
}

@media (min-width: 1024px) {
	.mainPage_col__2sDLP {
		margin-bottom: 40px;
		padding: 0 20px;
	}
}

.mainPage_item__1WnG0 {
	position: relative;
	display: block;
	padding: 30px 30px 45px;
	height: 100%;
	min-height: 200px;
	text-decoration: none;
	color: #0000bc;
	background: #fff;
}

.mainPage_title__7yZjS {
	margin-bottom: 22px;
	font-weight: 200;
	font-size: 30px;
	line-height: 40px;
	letter-spacing: 3px;
	text-transform: uppercase;
}

.mainPage_title__7yZjS svg {
	display: inline-block;
	vertical-align: top;
	width: 36px;
	height: 36px;
	fill: #0000bc;
}

@media (min-width: 768px) {
	.mainPage_title__7yZjS {
		font-size: 36px;
		line-height: 50px;
	}
}

.mainPage_text__3KBZh {
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.5px;
}

.mainPage_table__2S7be table {
	width: 100%;
	font-size: 11px;
	line-height: 16px;
	letter-spacing: 1px;
}

.mainPage_table__2S7be table td {
	vertical-align: top;
	border-top: solid 1px #0000bc;
	padding: 15px 8px 23px;
}

.mainPage_table__2S7be table td:first-child {
	padding-left: 0;
}

.mainPage_table__2S7be table td:last-child {
	padding-right: 0;
}

.mainPage_table__2S7be table tr:first-child td {
	border-top: none;
	padding-top: 0;
}

.mainPage_table__2S7be table tr:last-child td {
	padding-bottom: 0;
}

@media (min-width: 1024px) {
	.mainPage_table__2S7be table {
		font-size: 14px;
		line-height: 20px;
	}
}

.mainPage_tableName__siwub {
	max-width: 115px;
}

@media (min-width: 1024px) {
	.mainPage_tableName__siwub {
		max-width: 170px;
	}
}

.mainPage_tableNumber__1ZWiv {
	margin-top: 5px;
}

.mainPage_tableTime__1R7YP {
	margin-top: 5px;
	font-size: 12px;
	letter-spacing: 0;
}

@media (max-width: 767px) {
	.mainPage_table__2S7be {
		overflow: auto;
	}

	.mainPage_table__2S7be table {
		min-width: 460px;
	}
}

@media (min-width: 1200px) {
	.mainPage_table__2S7be {
		margin-bottom: -9px;
	}
}

.mainPage_link__3rz6e {
	position: absolute;
	right: 20px;
	bottom: 20px;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	fill: #0000bc;
}

.mainPage_link__3rz6e svg {
	display: block;
	width: 15px;
	height: 14px;
}

.mainPage_disabled__3pOGb {
	opacity: 0.3;
}

@media (min-width: 1024px) {
	.mainPage_item__1WnG0 {
		min-height: 250px;
	}
}

@media (min-width: 1200px) {
	.mainPage_item__1WnG0 {
		padding: 53px 60px;
		min-height: 300px;
	}
}

@media (min-width: 1024px) {
	.mainPage_index__zXYOD {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -20px -40px;
	}
}

.mainPage_instructionLink__2Lzah {
	width: 100%;
	text-align: center;
	padding-top: 20px;
	display: none;
}
.mainPage_instructionLink__2Lzah > a {
	font-family: "CitySans", sans-serif;
	font-weight: 300;
	color: #fff;
	font-size: 14px;
	text-decoration: none;
	list-style-type: none;
}
.mainPage_instructionLink__2Lzah > a:hover {
	text-decoration: underline;
}

@media (max-width: 768px) {
	.mainPage_instructionLink__2Lzah {
		display: block;
	}
}

.guestPage_wrapper__1zOD1 {
	max-width: 390px;
}

.guestPage_modal__1oO0E {

}

.guestPage_title__APuxF {
	font-size: 18px;
	line-height: 21px;
}

.guestPage_qr__2tdBD {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.guestPage_qrTitle__3kOQD {
	font-size: 21px;
	padding-bottom: 12px;
	padding-top: 12px;
}

.header_header__v9FKw {
	background: url(/static/media/header.0346922a.jpg) 50% 50% no-repeat;
	background-size: cover;
}

.header_top__1XJtp {
	padding: 15px 0;
	background: rgba(0, 0, 0, 0.9);
}

.header_top__1XJtp .header_container__Qg6Mi {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media (min-width: 1024px) {
	.header_top__1XJtp {
		padding: 31px 0;
	}
}

.header_logo__1X7aW img {
	width: 120px;
}

@media (min-width: 768px) {
	.header_logo__1X7aW img {
		width: 160px;
	}
}

.header_menu__4iihD {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
	font-size: 12px;
}

.header_menu__4iihD li + li {
	margin-left: 20px;
}

.header_left__35XJZ {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.header_link__3CRBA {
	padding-left: 40px;
}
.header_link__3CRBA > a{
	font-family: "CitySans", sans-serif;
	font-weight: 300;
	color: #fff;
	font-size: 12px;
	text-decoration: none;
	list-style-type: none;
}
.header_link__3CRBA > a:hover {
	text-decoration: underline;
}

@media (max-width: 768px) {
	.header_link__3CRBA {
		display: none;
	}
}
@media (min-width: 768px) {
	.header_menu__4iihD li + li {
		margin-left: 40px;
	}
}

.header_menu__4iihD li a {
	text-decoration: none;
	color: inherit;
}

.header_menu__4iihD li a:hover {
	text-decoration: underline;
}

.header_bottom__3O8O5 {
	display: flex;
	align-items: center;
	height: 120px;
}

@media (min-width: 768px) {
	.header_bottom__3O8O5 {
		height: 170px;
	}
}

@media (min-width: 1200px) {
	.header_bottom__3O8O5 {
		height: 270px;
	}
}

.header_title__1DXxp {
	font-weight: 200;
	font-size: 30px;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.header_title__1DXxp {
		font-size: 40px;
	}
}

@media (min-width: 1024px) {
	.header_title__1DXxp {
		font-size: 50px;
	}
}

@media (min-width: 1200px) {
	.header_title__1DXxp {
		font-size: 60px;
	}
}


.header_container__Qg6Mi {
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}

@media (min-width: 768px) {
	.header_container__Qg6Mi {
		padding: 0 10px;
		max-width: 720px;
	}
}

@media (min-width: 1024px) {
	.header_container__Qg6Mi {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.header_container__Qg6Mi {
		max-width: 1200px;
	}
}

.employeePage_wrapper__3rx2I {
	max-width: 390px;
}


.filter_filter__2Y83Y {
	margin-bottom: 40px;
}

.filter_filterContainer__399wq {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-auto-flow: column;
	grid-column-gap: 1em;
	flex: 1 1;
	width: 100%;
}

.filter_filterRight__1pt1N {
	align-items: flex-end;
}

.filter_filterRight__1pt1N > button {
	margin: 0 0 20px;
}

@media (min-width: 768px) {
	.filter_filterRow__XTqeR {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
	}
}

@media (min-width: 768px) {
	.filter_filterCol__-NGda {
		padding-left: 20px;
		width: 33.33334%;
	}
}

@media (min-width: 768px) {
	.filter_filterRight__1pt1N {
		display: flex;
		flex-shrink: 0;
		justify-content: flex-start;
	}
	.filter_filterRight__1pt1N > button {
		margin-right: 40px;
	}
}

@media (min-width: 1024px) {
	.filter_filterRight__1pt1N {
		display: block;
		margin-left: 30px;
		width: 200px;
	}
}

@media (min-width: 1200px) {
	.filter_filterRight__1pt1N {
		margin-left: 25px;
	}
}

.filter_filterSubmit__pGzpa {
	margin: 0;
}

.filter_filterInput__17Gcy input {
	box-sizing: border-box;
}

@media (min-width: 768px) {
	.filter_filterSubmit__pGzpa .filter_btn__1pnVQ {
		margin-left: 0;
	}
}

@media (min-width: 1024px) {
	.filter_filter__2Y83Y {
		display: flex;
	}
}

@media (max-width: 768px) {
	.filter_filterContainer__399wq {
		display: flex;
		flex-direction: column;
	}
}


.table_staff__2asOh {
	overflow: auto;
}

.table_staff__2asOh > table {
	border-bottom: solid 1px #0000bc;
	width: 100%;
	min-width: 650px;
	font-size: 12px;
	line-height: 18px;
	border-collapse: collapse;
	border-spacing: 0;
}

.table_staff__2asOh > table > thead th {
	border-top: solid 1px #0000bc;
	padding: 0 10px;
	height: 60px;
	font-weight: 300;
	font-size: 11px;
	line-height: 16px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
}

.table_staff__2asOh > table > thead th:first-child {
	padding-left: 0;
}

.table_staff__2asOh > table > thead th:last-child {
	padding-right: 0;
}

@media (min-width: 1024px) {
	.table_staff__2asOh > table > thead th {
		font-size: 13px;
		line-height: 18px;
	}
}

@media (min-width: 768px) {
	.table_staff__2asOh > table {
		min-width: 0;
	}
}

@media (min-width: 1024px) {
	.table_staff__2asOh > table {
		font-size: 14px;
		line-height: 20px;
	}
}

.table_staffSpace__Ftvwr {
	width: 5%;
}

@media (min-width: 1024px) {
	.table_staffSpace__Ftvwr {
		width: 10%;
	}
}

@media (min-width: 1200px) {
	.table_staffSpace__Ftvwr {
		width: 20%;
	}
}

.table_staffHeader__2cOIp {
	position: relative;
	text-decoration: none;
	color: inherit;
}

.table_staffSort__16RQW {
	cursor: pointer;
}

.table_staffSortCurrent__3295T {

}

.table_staffSortCurrent__3295T::after {
	content: '';
	position: absolute;
	left: 100%;
	top: 50%;
	margin: -2px 0 0 8px;
	border-top: solid 6px #0000bc;
	border-right: solid 4px transparent;
	border-left: solid 4px transparent;
	transform: translateY(-50%);
}

.table_staffSort__16RQW:hover {
	text-decoration: underline;
}

.table_staffSort__16RQW.table_isDesc__3gnp0::after {
	border-top: none;
	border-bottom: solid 6px #0000bc;
}

.table_staffItem__11YwR {
	cursor: pointer;
}

.table_staffItemDisabled__2kUC4 td {
	color: rgba(0, 0, 188, 0.29);
}

.table_staffItem__11YwR td {
	border-top: solid 1px #0000bc;
	padding: 19px 10px;
	transition: 0.25s;
}

.table_staffItem__11YwR td.table_notHovered__o0UxQ {
	cursor: default;
}

.table_staffItem__11YwR td:first-child {
	padding-left: 0;
}

.table_staffItem__11YwR td:last-child {
	padding-right: 0;
	width: 22px;
}

.table_staffItemSmall__tKfcA {
	font-size: 10px;
}

@media (min-width: 1024px) {
	.table_staffItemSmall__tKfcA {
		font-size: 12px;
	}
}

.table_staffItemArrow__1qe1R {
	position: relative;
	display: block;
	margin-left: 5px;
	width: 22px;
	height: 22px;
}

.table_staffItemArrow__1qe1R::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -7px 0 0 -6px;
	border-right: solid 1px #0000bc;
	border-bottom: solid 1px #0000bc;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
}

.table_staffItem__11YwR:hover td {
	background: #efefff;
}

.table_staffItem__11YwR:hover td.table_notHovered__o0UxQ {
	background: none;
}

.table_staffItemContent__Xw22c {}

.table_staffItemContent__Xw22c > td {}

.table_staffItemContent__Xw22c > td > * {
	padding: 2px 0 35px;
}

.table_staffItemContentOptions__ExD0V {
	display: flex;
	justify-content: flex-end;
	list-style-type: none;
	margin: 0 0 42px;
	padding: 0;
	width: calc(100vw - 60px);
}

.table_staffItemContentOptions__ExD0V li + li {
	margin-left: 15px;
}

.table_staffItemContentOptions__ExD0V li a {
	fill: #0000bc;
}

.table_staffItemContentOptions__ExD0V li a svg {
	display: block;
	width: 25px;
	height: 25px;
}

.table_isEdit__2cYVG .table_staffItemContentOptions__ExD0V li a.table_isEdit__2cYVG {
	display: none;
}

.table_isEdit__2cYVG .table_staffItemContentOptions__ExD0V li a.table_isRefresh__1Vjii {
	display: none;
}

@media (min-width: 768px) {
	.table_staffItemContentOptions__ExD0V {
		width: auto;
	}
}

.table_staffItemContentTable__3xDHM table {
	width: 100%;
}

.table_staffItemContentTable__3xDHM table th {
	padding: 0 10px 2px;
	font-weight: 300;
	font-size: 10px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
}

.table_staffItemContentTable__3xDHM table th:first-child {
	padding-left: 0;
}

.table_staffItemContentTable__3xDHM table th:last-child {
	padding-right: 0;
}

@media (min-width: 1024px) {
	.table_staffItemContentTable__3xDHM table th {
		font-size: 11px;
	}
}

.table_staffItemContentTable__3xDHM table td {
	padding: 5px 10px;
}

.table_staffItemContentTable__3xDHM table td:first-child {
	padding-left: 0;
}

.table_staffItemContentTable__3xDHM table td:last-child {
	padding-right: 0;
}

.table_staffItemContentZones__SodFF {
	position: relative;
}

.table_staffItemContentZonesIcon__uhvm9 {
	border: solid 1px #0000bc;
	width: 19px;
	height: 13px;
}

.table_staffItemContentZonesPopup__3LVDH {
	position: absolute;
	left: 0;
	top: 100%;
	z-index: 5;
	margin-top: 5px;
	border: solid 1px #0000bc;
	padding: 20px;
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15);
	font-size: 12px;
	line-height: 16px;
	white-space: nowrap;
	background: #fff;
	opacity: 0;
	visibility: hidden;
	transition: 0.25s;
	pointer-events: none;
}

.table_staffItemContentZones__SodFF:hover .table_staffItemContentZonesPopup__3LVDH {
	opacity: 1;
	visibility: visible;
}

.table_staffItemContentForm__1OiXL {
	display: none;
	padding: 0 30px 15px 0;
	width: calc(100vw - 60px);
}

.table_staffItemContentForm__1OiXL .table_staffItemContentList__12jAT {
	margin-bottom: 40px;
	padding-right: 0;
}

.table_staffItemContentFormBasic__2rhcQ {
	margin-bottom: 20px;
}

.table_staffItemContentFormCarItem__1x6yO {
	position: relative;
}

.table_staffItemContentFormCarItemRemove__1uXNv {
	position: absolute;
	right: -30px;
	top: 35px;
	color: #0000bc;
	fill: #0000bc;
}

.table_staffItemContentFormCarItemRemove__1uXNv svg {
	display: block;
	width: 25px;
	height: 25px;
}

@media (min-width: 768px) {
	.table_staffItemContentFormCarItemRemove__1uXNv {
		right: -50px;
		top: 43px;
	}
}

@media (min-width: 1200px) {
	.table_staffItemContentFormCarItemRemove__1uXNv {
		right: -100px;
	}
}

.table_staffItemContentFormAdd__rVqvw {
	margin-top: 20px;
}

.table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx {
	position: relative;
	margin: 0;
	padding-left: 45px;
	width: 100%;
	font-size: 16px;
	letter-spacing: 0.5px;
}

.table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx::before, .table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx::after {
	content: "";
	position: absolute;
	top: 50%;
	border: none;
	background: #0000bc;
}

.table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx::before {
	left: 12px;
	margin-top: -1px;
	width: 19px;
	height: 1px;
}

@media (min-width: 1200px) {
	.table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx::before {
		left: 22px;
	}
}

.table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx::after {
	left: 21px;
	margin-top: -10px;
	width: 1px;
	height: 19px;
}

@media (min-width: 1200px) {
	.table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx::after {
		left: 31px;
	}
}

.table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx:hover::before, .table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx:hover::after {
	background: #fff;
}

@media (min-width: 768px) {
	.table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx {
		width: auto;
	}
}

@media (min-width: 1024px) {
	.table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx {
		padding-right: 10px;
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.table_staffItemContentFormCarAdd__1H1sZ .table_btn__3zSJx {
		padding-left: 65px;
		font-size: 18px;
	}
}

.table_staffItemContentFormFooter__1QDas {
	margin-top: 60px;
}

@media (max-width: 767px) {
	.table_staffItemContentFormFooter__1QDas .table_btn__3zSJx {
		width: 100%;
	}
}

.table_staffItemContentFormFooter__1QDas .table_btn__3zSJx + .table_btn__3zSJx {
	margin-top: 30px;
}

@media (min-width: 768px) {
	.table_staffItemContentFormFooter__1QDas .table_btn__3zSJx + .table_btn__3zSJx {
		margin: 0 0 0 30px;
	}
}

@media (min-width: 768px) {
	.table_staffItemContentForm__1OiXL {
		padding-right: 50px;
		width: auto;
	}
}

@media (min-width: 1200px) {
	.table_staffItemContentForm__1OiXL {
		padding-right: 100px;
	}
}

.table_staffItemContentList__12jAT table + table {
	margin-top: 15px;
}

@media (min-width: 768px) {
	.table_staffItemContentList__12jAT table + table {
		margin-top: 0;
	}
}

.table_staffItemContentList__12jAT table tr + tr {
	margin-top: 15px;
}

@media (min-width: 1200px) {
	.table_staffItemContentList__12jAT table tr + tr td {
		padding-top: 17px;
	}
}

@media (min-width: 1200px) {
	.table_staffItemContentList__12jAT table tr + tr {
		margin-top: 0;
	}
}

@media (max-width: 1199px) {
	.table_staffItemContentList__12jAT table tr,
	.table_staffItemContentList__12jAT table td {
		display: block;
	}
}

.table_staffItemContentList__key__337as {
	padding-right: 30px;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.table_staffItemContentList__12jAT {
		display: flex;
		justify-content: space-between;
		padding-right: 40px;
	}
}

.table_staffItemContentSessions__18LhA {
	margin-top: 48px;
}

.table_staffItemContentSessionsTitle__3onPD {
	margin-bottom: 20px;
	font-size: 22px;
}

.table_staffItemContentSessions__18LhA table {
	width: 100%;
	max-width: 860px;
}

.table_staffItemContentSessions__18LhA table th,
.table_staffItemContentSessions__18LhA table td {
	padding: 5px 10px;
}

.table_staffItemContentSessions__18LhA table th:first-child,
.table_staffItemContentSessions__18LhA table td:first-child {
	padding-left: 0;
}

.table_staffItemContentSessions__18LhA table th:last-child,
.table_staffItemContentSessions__18LhA table td:last-child {
	padding-right: 0;
}

.table_staffItemContentSessions__18LhA table th {
	padding-bottom: 2px;
	font-weight: 300;
	font-size: 11px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
}

.table_staffItemContentSessions__18LhA table td {
	vertical-align: top;
}

.table_staffItemContentSessionsType__3YyE9 {
	width: 30px;
}

.table_staffItemContentSessionsType__3YyE9 svg {
	display: block;
	margin-top: -4px;
	width: 25px;
	height: 25px;
}

.table_staffItemContentSessionsPhoto__3bU5F {
	width: 100px;
}

.table_staffItemContentSessionsPhoto__3bU5F img {
	width: 90px;
}

.table_staffItemContentDisabled__1jqcO {
	opacity: 0.3;
	pointer-events: none;
}

.table_staffItemContent__Xw22c.table_isEdit__2cYVG .table_staffItemContentTable__3xDHM,
.table_staffItemContent__Xw22c.table_isEdit__2cYVG .table_staffItemContentInfo__2e44A {
	display: none;
}

.table_staffItemContent__Xw22c.table_isEdit__2cYVG .table_staffItemContentForm__1OiXL {
	display: block;
}

.table_staffItemContent__Xw22c.table_isHidden__2ZVVd {}

.table_staffItemContent__Xw22c.table_isHidden__2ZVVd > td > * {
	padding: 0;
}

@media (min-width: 1024px) {
	.table_staff__2asOh {
		overflow: visible;
	}
}

.table_staffLogs__1ibWv {
	overflow: visible;
}

.table_staffLogs__1ibWv > table {
	min-width: 0;
}

@media (min-width: 1024px) {
	.table_staffLogs__1ibWv > table {
		font-size: 16px;
	}
}

@-webkit-keyframes table_showItemContent__WWK-p {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes table_showItemContent__WWK-p {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}


.tooltip_container__2HFeW {
	position: relative;
	display: inline-flex;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	cursor: pointer;
	margin: 0 10px;
	-webkit-tap-highlight-color: transparent;
}

.tooltip_tooltipContent__3Gtxt {
	color: #0000bc;
	position: absolute;
	z-index: 10;
	padding: 12px;
	font-size: 12px;
	cursor: default;
	border-radius: 3px;
	white-space: nowrap;
	background-color: #FFFFFF;
	border: solid 1px #0000bc;
	-webkit-animation: tooltip_fadeIn__WQGHO ease-in-out 0.65s;
	        animation: tooltip_fadeIn__WQGHO ease-in-out 0.65s;
}

.tooltip_bottom__1U4HR {
	top: calc(100% + 18px);
	left: 50%;
	transform: translateX(-50%);
}

@-webkit-keyframes tooltip_fadeIn__WQGHO {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes tooltip_fadeIn__WQGHO {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

.paginationElement_pager__2ilYW {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;
}

.paginationElement_pager__2ilYW ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.paginationElement_pager__2ilYW ul li {
	display: inline-block;
	vertical-align: middle;
}

.paginationElement_pager__2ilYW ul li span {
	display: block;
	width: 22px;
	height: 22px;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.5px;
	text-align: center;
	text-decoration: none;
	color: #0000bc;
	transition: 0.25s;
	cursor: pointer;
}

.paginationElement_pager__2ilYW ul li span:hover, .paginationElement_pager__2ilYW ul li span.paginationElement_isActive__eGVu8 {
	color: #fff;
	background: #0000bc;
}

.paginationElement_pagerPrev__2ReQ0 {
	position: relative;
	display: block;
	margin-right: 5px;
	margin-top: 5px;
	width: 22px;
	height: 22px;
}

.paginationElement_pagerPrev__2ReQ0::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -5px 0 0 -2px;
	border-bottom: solid 1px #0000bc;
	border-left: solid 1px #0000bc;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
}

.paginationElement_pagerNext__tTQ1v {
	position: relative;
	display: block;
	margin-left: 5px;
	margin-top: 5px;
	width: 22px;
	height: 22px;
}

.paginationElement_pagerNext__tTQ1v::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -5px 0 0 -7px;
	border-right: solid 1px #0000bc;
	border-bottom: solid 1px #0000bc;
	width: 10px;
	height: 10px;
	transform: rotate(-45deg);
}

.currentUser_row__3UCKA {
	width: 100%;
}

.currentUser_options__1cnB7 {
	display: flex;
	justify-content: flex-end;
	list-style-type: none;
	margin: 0 0 42px;
	padding: 0;
	width: calc(100vw - 60px);
}

.currentUser_options__1cnB7 li + li {
	margin-left: 15px;
}

.currentUser_options__1cnB7 li span {
	fill: #0000bc;
	cursor: pointer;
}

.currentUser_options__1cnB7 li span svg {
	display: block;
	width: 25px;
	height: 25px;
}

.currentUser_isEdit__7vv3z .currentUser_options__1cnB7 li span.currentUser_isEdit__7vv3z {
	display: none;
}

.currentUser_isEdit__7vv3z .currentUser_options__1cnB7 li span.currentUser_isRefresh__12j80 {
	display: none;
}

@media (min-width: 768px) {
	.currentUser_options__1cnB7 {
		width: auto;
	}
}
.currentUser_wrapper__Fz5AW {

}

.currentUser_input__rEOZP {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
}

@media (max-width: 1200px) {
	.currentUser_input__rEOZP {
		grid-template-columns: 1fr;
		width: calc(100vw - 60px);
	}
}

.currentUser_buttons__1616G > * {
	margin-right: 30px;
}

@media (max-width: 1200px) {
	.currentUser_buttons__1616G {
		width: calc(100vw - 60px);
	}
	.currentUser_buttons__1616G > * {
		margin-top: 20px;
	}
}

.currentEmployer_row__fDOr3 {
	width: 100%;
}

.currentEmployer_options__1iAJ7 {
	display: flex;
	justify-content: flex-end;
	list-style-type: none;
	margin: 0 0 42px;
	padding: 0;
	width: calc(100vw - 60px);
}

.currentEmployer_options__1iAJ7 li + li {
	margin-left: 15px;
}

.currentEmployer_options__1iAJ7 li span {
	fill: #0000bc;
	cursor: pointer;
}

.currentEmployer_options__1iAJ7 li span svg {
	display: block;
	width: 25px;
	height: 25px;
}

.currentEmployer_isEdit__1qbkb .currentEmployer_options__1iAJ7 li span.currentEmployer_isEdit__1qbkb {
	display: none;
}

.currentEmployer_isEdit__1qbkb .currentEmployer_options__1iAJ7 li span.currentEmployer_isRefresh__1Unmr {
	display: none;
}

@media (min-width: 768px) {
	.currentEmployer_options__1iAJ7 {
		width: auto;
	}
}

.currentEmployer_wrapper__2RbgG {

}

.currentEmployer_input__2Eu0W {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
}

.currentEmployer_buttons__21_AY > * {
	margin-right: 30px;
}

.currentEmployer_kit__3T4yv {
	grid-column-start: 1;
	grid-column-end: 4;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
}

.currentEmployer_kitButton__1aOhU {
	grid-column-start: 1;
	grid-column-end: 4;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
}


@media (max-width: 768px) {
	.currentEmployer_buttons__21_AY {
		width: calc(100vw - 60px);
	}
	.currentEmployer_buttons__21_AY > * {
		margin-top: 20px;
	}
	.currentEmployer_input__2Eu0W {
		display: block;
		width: calc(100vw - 90px);
	}
	.currentEmployer_kit__3T4yv {
		display: block;
		width: calc(100vw - 90px);
	}
}

.currentEmployer_switch__3Zu2S {
	margin-top: 0
}


.currentEmployer_table__1VtK9 table {
	width: 100%;
}

.currentEmployer_table__1VtK9 table th {
	padding: 0 10px 2px;
	font-weight: 300;
	font-size: 10px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
}

.currentEmployer_table__1VtK9 table th:first-child {
	padding-left: 0;
}

.currentEmployer_table__1VtK9 table th:last-child {
	padding-right: 0;
}

@media (min-width: 1024px) {
	.currentEmployer_table__1VtK9 table th {
		font-size: 11px;
	}
}

.currentEmployer_table__1VtK9 table td {
	padding: 5px 10px;
}

.currentEmployer_table__1VtK9 table .currentEmployer_disabled__kxBff td {
	color: rgba(0, 0, 188, 0.29);
}

.currentEmployer_table__1VtK9 table td:first-child {
	padding-left: 0;
}

.currentEmployer_table__1VtK9 table td:last-child {
	padding-right: 0;
}

.currentEmployer_balance__146Nq {
	width: 15px;
	height: 15px;
	border: 1px solid #0000bc;
	display: inline-block;
	border-radius: 50%;
}
.currentEmployer_active__3yJGa {
	background-color: #0000bc;
}

.currentEmployer_coub__3E8fR {
	width: 15px;
	height: 15px;
	display: block;
	border: 1px solid #0000bc;
}

.currentEmployer_number__3q5Xa {
	padding: 3px 12px;
	border: 1px solid rgba(0,0,0,0);
}

.currentEmployer_activeNumber__1WJmr {
	width: 100%;
	height: 100%;
	border: 1px solid #0000bc;
}

.currentGuest_row__3zQzG {
	width: 100%;
	max-width: 100%;
}

@media (max-width: 1200px) {
	.currentGuest_row__3zQzG {
		width: calc(100vw - 60px);
	}
}

.currentGuest_options__1vQqz {
	display: flex;
	justify-content: flex-end;
	list-style-type: none;
	margin: 0 0 42px;
	padding: 0;
	width: calc(100vw - 60px);
}

.currentGuest_options__1vQqz li + li {
	margin-left: 15px;
}

.currentGuest_options__1vQqz li span {
	fill: #0000bc;
	cursor: pointer;
}

.currentGuest_options__1vQqz li span svg {
	display: block;
	width: 25px;
	height: 25px;
}

.currentGuest_isEdit__3FVZT .currentGuest_options__1vQqz li span.currentGuest_isEdit__3FVZT {
	display: none;
}

.currentGuest_isEdit__3FVZT .currentGuest_options__1vQqz li span.currentGuest_isRefresh__29efg {
	display: none;
}

@media (min-width: 768px) {
	.currentGuest_options__1vQqz {
		width: auto;
	}
}
.currentGuest_wrapper__92XSr {

}

.currentGuest_input__1_1vW {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
}

.currentGuest_buttons__3wPhv > * {
	margin-right: 30px;
}
.currentGuest_list__11c2W {
	margin-bottom: 40px;
	padding-right: 0;
}
@media (min-width: 768px) {
	.currentGuest_list__11c2W {
		display: flex;
		justify-content: space-between;
		padding-right: 40px;
	}
}
@media (max-width: 768px) {
	.currentGuest_list__11c2W table {
		width: 100%;
	}
	.currentGuest_list__11c2W table tr td:nth-child(2) {
		text-align: right;
	}
}

.currentGuest_key__dWpQ8 {
	padding-right: 30px;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.currentGuest_value__2J_q4 {

}


.currentGuest_session__1deoI {
	width: 100%;
}

.currentGuest_title__2aw5U {
	margin-bottom: 20px;
	font-size: 22px;
}

.currentGuest_session__1deoI table {
	width: 100%;
}

.currentGuest_session__1deoI table th,
.currentGuest_session__1deoI table td {
	padding: 5px 10px;
}

.currentGuest_session__1deoI table th:first-child,
.currentGuest_session__1deoI table td:first-child {
	padding-left: 0;
}

.currentGuest_session__1deoI table th:last-child,
.currentGuest_session__1deoI table td:last-child {
	padding-right: 0;
}

.currentGuest_session__1deoI table th {
	padding-bottom: 2px;
	font-weight: 300;
	font-size: 11px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
}

.currentGuest_session__1deoI table td {
	vertical-align: middle;
}

.currentGuest_type__1y-mt {
	width: 30px;
}

.currentGuest_type__1y-mt svg {
	display: block;
	margin-top: -4px;
	width: 25px;
	height: 25px;
}

.currentGuest_image__2hEdD {
	cursor: pointer;
}

.currentGuest_disabledImage__ilPkZ {
	-webkit-filter: grayscale(100%);
	        filter: grayscale(100%);
	opacity: .4;
}

.currentGuest_photo__1ZsFH {
	width: 100px;
	height: 50px;
}

.currentGuest_photo__1ZsFH img {
	width: 45px;
}

.guestsPage_type__1Yfow {
	min-width: 200px;
}
.guestsPage_start__2bT4E {
	min-width: 250px;
}


.saleHeaderElement_content__mi06X {
	padding: 40px 0;
}

.saleHeaderElement_content__mi06X h5 {
	font-size: 20px;
	margin-top: 0;
	font-weight: 300;
}

.saleHeaderElement_info__2G6dw {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0 0 25px;
	padding: 0;
}

.saleHeaderElement_info__2G6dw li {
	width: 33.333%;
}

.saleHeaderElement_info__2G6dw li * {
	text-align: center;
}

.saleHeaderElement_info__2G6dw li:first-child * {
	text-align: left;
}
.saleHeaderElement_info__2G6dw li:last-child * {
	text-align: right;
}

.saleHeaderElement_key__1SMZg {
	margin-bottom: 11px;
	font-size: 13px;
	letter-spacing: 0.5px;
}

@media (min-width: 768px) {
	.saleHeaderElement_key__1SMZg {
		font-size: 16px;
	}
}

.saleHeaderElement_value__3-OKX {
	font-size: 18px;
	text-transform: uppercase;
}

.saleHeaderElement_content__mi06X table {
	width: 100%;
	margin-top: 40px;
}

.saleHeaderElement_content__mi06X table th {
	width: 33.33%;
	text-align: center;
}
.saleHeaderElement_content__mi06X table tbody td {
	text-align: center;
}
.saleHeaderElement_content__mi06X table tbody td:first-child, .saleHeaderElement_content__mi06X table th:first-child {
	text-align: left;
}
.saleHeaderElement_content__mi06X table tbody td:last-child, .saleHeaderElement_content__mi06X table th:last-child {
	text-align: right;
}

.salePage_input__2d5tt {
	width: 35%;
}

.salePage_comment__2Vlku {
	width: 55%;
}

.salePage_select__FJfPC {
	width: 55%;
}


@media (max-width: 1200px) {
	.salePage_input__2d5tt {
		width: 50%;
	}
}
@media (max-width: 768px) {
	.salePage_input__2d5tt {
		width: 100%;
	}
}

