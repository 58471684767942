.wrapper {
	margin-bottom: 24px;
	position: relative;
}

.labelWrapper {
	margin-top: 24px;
}

.labelWrapper .label {
	position: absolute;
	top: -24px;
	left: 0;
}

.checkbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 5px;
	height: 32px;
}

.checkbox input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.checkbox input+label {
	display: inline-flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
}

.checkbox input+label::before {
	content: '';
	display: inline-block;
	width: 19px;
	height: 20px;
	flex-shrink: 0;
	flex-grow: 0;
	border: 1px solid #0000bc;
	border-radius: 0;
	margin-right: 12px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 70%;
	transition: all .2s ease-out;
}
.checkbox input:checked+label::before {
	border-color: #0000bc;
	background-color: #0000bc;
	background-image: url("data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTUuNTU2IDUxNS41NTYiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTE1LjU1NiA1MTUuNTU2IiB3aWR0aD0iNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDI3NC4yMjYgMTc2LjU0OSAxNzYuODg2IDMzOS4wMDctMzM4LjY3Mi00OC42Ny00Ny45OTctMjkwLjMzNyAyOTAtMTI4LjU1My0xMjguNTUyeiIgZmlsbD0iI2ZmZmZmZiIvPjwvc3ZnPg==");
}

.button {
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
	background: transparent;
	line-height: 18px;
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.inputIcon {
	position: absolute;
	right: 16px;
	top: 20px;
	width: 18px;
	height: 18px;
	fill: #0000bc;
	pointer-events: none;
	z-index: 12;
}

.active .inputIcon {
	transform: rotate(180deg);
}

.checkboxInner {

}

.checkboxInner.checkboxDropdown {
	display: none;
	position: absolute;
	border: solid 1px #0000bc;
	padding: 12px;
	background-color: #FFFFFF;
	z-index: 200;
	min-width: 100%;
	margin-top: 5px;
}
.active .checkboxInner.checkboxDropdown{
	display: block;
}

.label {
	margin-bottom: 5px;
	font-size: 12px;
}
