
.staff {
	overflow: auto;
}

.staff > table {
	border-bottom: solid 1px #0000bc;
	width: 100%;
	min-width: 650px;
	font-size: 12px;
	line-height: 18px;
	border-collapse: collapse;
	border-spacing: 0;
}

.staff > table > thead th {
	border-top: solid 1px #0000bc;
	padding: 0 10px;
	height: 60px;
	font-weight: 300;
	font-size: 11px;
	line-height: 16px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
}

.staff > table > thead th:first-child {
	padding-left: 0;
}

.staff > table > thead th:last-child {
	padding-right: 0;
}

@media (min-width: 1024px) {
	.staff > table > thead th {
		font-size: 13px;
		line-height: 18px;
	}
}

@media (min-width: 768px) {
	.staff > table {
		min-width: 0;
	}
}

@media (min-width: 1024px) {
	.staff > table {
		font-size: 14px;
		line-height: 20px;
	}
}

.staffSpace {
	width: 5%;
}

@media (min-width: 1024px) {
	.staffSpace {
		width: 10%;
	}
}

@media (min-width: 1200px) {
	.staffSpace {
		width: 20%;
	}
}

.staffHeader {
	position: relative;
	text-decoration: none;
	color: inherit;
}

.staffSort {
	cursor: pointer;
}

.staffSortCurrent {

}

.staffSortCurrent::after {
	content: '';
	position: absolute;
	left: 100%;
	top: 50%;
	margin: -2px 0 0 8px;
	border-top: solid 6px #0000bc;
	border-right: solid 4px transparent;
	border-left: solid 4px transparent;
	transform: translateY(-50%);
}

.staffSort:hover {
	text-decoration: underline;
}

.staffSort.isDesc::after {
	border-top: none;
	border-bottom: solid 6px #0000bc;
}

.staffItem {
	cursor: pointer;
}

.staffItemDisabled td {
	color: rgba(0, 0, 188, 0.29);
}

.staffItem td {
	border-top: solid 1px #0000bc;
	padding: 19px 10px;
	-webkit-transition: 0.25s;
	-o-transition: 0.25s;
	-moz-transition: 0.25s;
	transition: 0.25s;
}

.staffItem td.notHovered {
	cursor: default;
}

.staffItem td:first-child {
	padding-left: 0;
}

.staffItem td:last-child {
	padding-right: 0;
	width: 22px;
}

.staffItemSmall {
	font-size: 10px;
}

@media (min-width: 1024px) {
	.staffItemSmall {
		font-size: 12px;
	}
}

.staffItemArrow {
	position: relative;
	display: block;
	margin-left: 5px;
	width: 22px;
	height: 22px;
}

.staffItemArrow::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -7px 0 0 -6px;
	border-right: solid 1px #0000bc;
	border-bottom: solid 1px #0000bc;
	width: 10px;
	height: 10px;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.staffItem:hover td {
	background: #efefff;
}

.staffItem:hover td.notHovered {
	background: none;
}

.staffItemContent {}

.staffItemContent > td {}

.staffItemContent > td > * {
	padding: 2px 0 35px;
}

.staffItemContentOptions {
	display: flex;
	justify-content: flex-end;
	list-style-type: none;
	margin: 0 0 42px;
	padding: 0;
	width: calc(100vw - 60px);
}

.staffItemContentOptions li + li {
	margin-left: 15px;
}

.staffItemContentOptions li a {
	fill: #0000bc;
}

.staffItemContentOptions li a svg {
	display: block;
	width: 25px;
	height: 25px;
}

.isEdit .staffItemContentOptions li a.isEdit {
	display: none;
}

.isEdit .staffItemContentOptions li a.isRefresh {
	display: none;
}

@media (min-width: 768px) {
	.staffItemContentOptions {
		width: auto;
	}
}

.staffItemContentTable table {
	width: 100%;
}

.staffItemContentTable table th {
	padding: 0 10px 2px;
	font-weight: 300;
	font-size: 10px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
}

.staffItemContentTable table th:first-child {
	padding-left: 0;
}

.staffItemContentTable table th:last-child {
	padding-right: 0;
}

@media (min-width: 1024px) {
	.staffItemContentTable table th {
		font-size: 11px;
	}
}

.staffItemContentTable table td {
	padding: 5px 10px;
}

.staffItemContentTable table td:first-child {
	padding-left: 0;
}

.staffItemContentTable table td:last-child {
	padding-right: 0;
}

.staffItemContentZones {
	position: relative;
}

.staffItemContentZonesIcon {
	border: solid 1px #0000bc;
	width: 19px;
	height: 13px;
}

.staffItemContentZonesPopup {
	position: absolute;
	left: 0;
	top: 100%;
	z-index: 5;
	margin-top: 5px;
	border: solid 1px #0000bc;
	padding: 20px;
	-webkit-box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15);
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15);
	font-size: 12px;
	line-height: 16px;
	white-space: nowrap;
	background: #fff;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.25s;
	-o-transition: 0.25s;
	-moz-transition: 0.25s;
	transition: 0.25s;
	pointer-events: none;
}

.staffItemContentZones:hover .staffItemContentZonesPopup {
	opacity: 1;
	visibility: visible;
}

.staffItemContentForm {
	display: none;
	padding: 0 30px 15px 0;
	width: -webkit-calc(100vw - 60px);
	width: -moz-calc(100vw - 60px);
	width: calc(100vw - 60px);
}

.staffItemContentForm .staffItemContentList {
	margin-bottom: 40px;
	padding-right: 0;
}

.staffItemContentFormBasic {
	margin-bottom: 20px;
}

.staffItemContentFormCarItem {
	position: relative;
}

.staffItemContentFormCarItemRemove {
	position: absolute;
	right: -30px;
	top: 35px;
	color: #0000bc;
	fill: #0000bc;
}

.staffItemContentFormCarItemRemove svg {
	display: block;
	width: 25px;
	height: 25px;
}

@media (min-width: 768px) {
	.staffItemContentFormCarItemRemove {
		right: -50px;
		top: 43px;
	}
}

@media (min-width: 1200px) {
	.staffItemContentFormCarItemRemove {
		right: -100px;
	}
}

.staffItemContentFormAdd {
	margin-top: 20px;
}

.staffItemContentFormCarAdd .btn {
	position: relative;
	margin: 0;
	padding-left: 45px;
	width: 100%;
	font-size: 16px;
	letter-spacing: 0.5px;
}

.staffItemContentFormCarAdd .btn::before, .staffItemContentFormCarAdd .btn::after {
	content: "";
	position: absolute;
	top: 50%;
	border: none;
	background: #0000bc;
}

.staffItemContentFormCarAdd .btn::before {
	left: 12px;
	margin-top: -1px;
	width: 19px;
	height: 1px;
}

@media (min-width: 1200px) {
	.staffItemContentFormCarAdd .btn::before {
		left: 22px;
	}
}

.staffItemContentFormCarAdd .btn::after {
	left: 21px;
	margin-top: -10px;
	width: 1px;
	height: 19px;
}

@media (min-width: 1200px) {
	.staffItemContentFormCarAdd .btn::after {
		left: 31px;
	}
}

.staffItemContentFormCarAdd .btn:hover::before, .staffItemContentFormCarAdd .btn:hover::after {
	background: #fff;
}

@media (min-width: 768px) {
	.staffItemContentFormCarAdd .btn {
		width: auto;
	}
}

@media (min-width: 1024px) {
	.staffItemContentFormCarAdd .btn {
		padding-right: 10px;
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.staffItemContentFormCarAdd .btn {
		padding-left: 65px;
		font-size: 18px;
	}
}

.staffItemContentFormFooter {
	margin-top: 60px;
}

@media (max-width: 767px) {
	.staffItemContentFormFooter .btn {
		width: 100%;
	}
}

.staffItemContentFormFooter .btn + .btn {
	margin-top: 30px;
}

@media (min-width: 768px) {
	.staffItemContentFormFooter .btn + .btn {
		margin: 0 0 0 30px;
	}
}

@media (min-width: 768px) {
	.staffItemContentForm {
		padding-right: 50px;
		width: auto;
	}
}

@media (min-width: 1200px) {
	.staffItemContentForm {
		padding-right: 100px;
	}
}

.staffItemContentList table + table {
	margin-top: 15px;
}

@media (min-width: 768px) {
	.staffItemContentList table + table {
		margin-top: 0;
	}
}

.staffItemContentList table tr + tr {
	margin-top: 15px;
}

@media (min-width: 1200px) {
	.staffItemContentList table tr + tr td {
		padding-top: 17px;
	}
}

@media (min-width: 1200px) {
	.staffItemContentList table tr + tr {
		margin-top: 0;
	}
}

@media (max-width: 1199px) {
	.staffItemContentList table tr,
	.staffItemContentList table td {
		display: block;
	}
}

.staffItemContentList__key {
	padding-right: 30px;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.staffItemContentList {
		display: flex;
		justify-content: space-between;
		padding-right: 40px;
	}
}

.staffItemContentSessions {
	margin-top: 48px;
}

.staffItemContentSessionsTitle {
	margin-bottom: 20px;
	font-size: 22px;
}

.staffItemContentSessions table {
	width: 100%;
	max-width: 860px;
}

.staffItemContentSessions table th,
.staffItemContentSessions table td {
	padding: 5px 10px;
}

.staffItemContentSessions table th:first-child,
.staffItemContentSessions table td:first-child {
	padding-left: 0;
}

.staffItemContentSessions table th:last-child,
.staffItemContentSessions table td:last-child {
	padding-right: 0;
}

.staffItemContentSessions table th {
	padding-bottom: 2px;
	font-weight: 300;
	font-size: 11px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
}

.staffItemContentSessions table td {
	vertical-align: top;
}

.staffItemContentSessionsType {
	width: 30px;
}

.staffItemContentSessionsType svg {
	display: block;
	margin-top: -4px;
	width: 25px;
	height: 25px;
}

.staffItemContentSessionsPhoto {
	width: 100px;
}

.staffItemContentSessionsPhoto img {
	width: 90px;
}

.staffItemContentDisabled {
	opacity: 0.3;
	pointer-events: none;
}

.staffItemContent.isEdit .staffItemContentTable,
.staffItemContent.isEdit .staffItemContentInfo {
	display: none;
}

.staffItemContent.isEdit .staffItemContentForm {
	display: block;
}

.staffItemContent.isHidden {}

.staffItemContent.isHidden > td > * {
	padding: 0;
}

@media (min-width: 1024px) {
	.staff {
		overflow: visible;
	}
}

.staffLogs {
	overflow: visible;
}

.staffLogs > table {
	min-width: 0;
}

@media (min-width: 1024px) {
	.staffLogs > table {
		font-size: 16px;
	}
}

@keyframes showItemContent {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}
