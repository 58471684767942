.row {
	width: 100%;
	max-width: 100%;
}

@media (max-width: 1200px) {
	.row {
		width: calc(100vw - 60px);
	}
}

.options {
	display: flex;
	justify-content: flex-end;
	list-style-type: none;
	margin: 0 0 42px;
	padding: 0;
	width: calc(100vw - 60px);
}

.options li + li {
	margin-left: 15px;
}

.options li span {
	fill: #0000bc;
	cursor: pointer;
}

.options li span svg {
	display: block;
	width: 25px;
	height: 25px;
}

.isEdit .options li span.isEdit {
	display: none;
}

.isEdit .options li span.isRefresh {
	display: none;
}

@media (min-width: 768px) {
	.options {
		width: auto;
	}
}
.wrapper {

}

.input {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
}

.buttons > * {
	margin-right: 30px;
}
.list {
	margin-bottom: 40px;
	padding-right: 0;
}
@media (min-width: 768px) {
	.list {
		display: flex;
		justify-content: space-between;
		padding-right: 40px;
	}
}
@media (max-width: 768px) {
	.list table {
		width: 100%;
	}
	.list table tr td:nth-child(2) {
		text-align: right;
	}
}

.key {
	padding-right: 30px;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.value {

}


.session {
	width: 100%;
}

.title {
	margin-bottom: 20px;
	font-size: 22px;
}

.session table {
	width: 100%;
}

.session table th,
.session table td {
	padding: 5px 10px;
}

.session table th:first-child,
.session table td:first-child {
	padding-left: 0;
}

.session table th:last-child,
.session table td:last-child {
	padding-right: 0;
}

.session table th {
	padding-bottom: 2px;
	font-weight: 300;
	font-size: 11px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
}

.session table td {
	vertical-align: middle;
}

.type {
	width: 30px;
}

.type svg {
	display: block;
	margin-top: -4px;
	width: 25px;
	height: 25px;
}

.image {
	cursor: pointer;
}

.disabledImage {
	filter: grayscale(100%);
	opacity: .4;
}

.photo {
	width: 100px;
	height: 50px;
}

.photo img {
	width: 45px;
}
