.row {
	width: 100%;
}

.options {
	display: flex;
	justify-content: flex-end;
	list-style-type: none;
	margin: 0 0 42px;
	padding: 0;
	width: calc(100vw - 60px);
}

.options li + li {
	margin-left: 15px;
}

.options li span {
	fill: #0000bc;
	cursor: pointer;
}

.options li span svg {
	display: block;
	width: 25px;
	height: 25px;
}

.isEdit .options li span.isEdit {
	display: none;
}

.isEdit .options li span.isRefresh {
	display: none;
}

@media (min-width: 768px) {
	.options {
		width: auto;
	}
}
.wrapper {

}

.input {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
}

@media (max-width: 1200px) {
	.input {
		grid-template-columns: 1fr;
		width: calc(100vw - 60px);
	}
}

.buttons > * {
	margin-right: 30px;
}

@media (max-width: 1200px) {
	.buttons {
		width: calc(100vw - 60px);
	}
	.buttons > * {
		margin-top: 20px;
	}
}
