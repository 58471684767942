@import url("https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.css");

@font-face {
	src: url("../assets/fonts/CitySans-Thin.woff2") format("woff2"), url("../assets/fonts/CitySans-Thin.woff") format("woff");
	font-family: "CitySans";
	font-weight: 100;
	font-style: normal;
}

@font-face {
	src: url("../assets/fonts/CitySans-ExtraLight.woff2") format("woff2"), url("../assets/fonts/CitySans-ExtraLight.woff") format("woff");
	font-family: "CitySans";
	font-weight: 200;
	font-style: normal;
}

@font-face {
	src: url("../assets/fonts/CitySans-Light.woff2") format("woff2"), url("../assets/fonts/CitySans-Light.woff") format("woff");
	font-family: "CitySans";
	font-weight: 300;
	font-style: normal;
}

@font-face {
	src: url("../assets/fonts/CitySans-Regular.woff2") format("woff2"), url("../assets/fonts/CitySans-Regular.woff") format("woff");
	font-family: "CitySans";
	font-weight: 400;
	font-style: normal;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

[hidden],
template {
	display: none;
}

html {
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	margin: 0;
}

a {
	background: transparent;
}

a:focus {
	outline: thin dotted;
}

a:active,
a:hover {
	outline: 0;
}

h1 {
	font-size: 2em;
	margin: 0.67em 0;
}

abbr[title] {
	border-bottom: 1px dotted;
}

b,
strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

hr {
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
}

mark {
	background: #ff0;
	color: #000;
}

code,
kbd,
pre,
samp {
	font-family: monospace, serif;
	font-size: 1em;
}

pre {
	white-space: pre-wrap;
}

q {
	quotes: "\201C" "\201D" "\2018" "\2019";
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

img {
	border: 0;
}

svg:not(:root) {
	overflow: hidden;
}

figure {
	margin: 0;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
}

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	margin: 0;
}

button,
input {
	line-height: normal;
}

button,
select {
	text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}

input[type="search"] {
	-webkit-appearance: textfield;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	overflow: auto;
	vertical-align: top;
}

*,
*::before,
*::after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	font-family: "CitySans", sans-serif;
	font-weight: 300;
	color: #fff;
	background: #0000bc;
}

b {
	font-weight: 400;
}

img {
	vertical-align: middle;
	max-width: 100%;
}

.select2-container {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	position: relative;
	vertical-align: middle;
}

.select2-container .select2-selection--single {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	height: 28px;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	display: block;
	padding-left: 8px;
	padding-right: 20px;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
	position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
	padding-right: 8px;
	padding-left: 20px;
}

.select2-container .select2-selection--multiple {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	min-height: 32px;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
	display: inline-block;
	overflow: hidden;
	padding-left: 8px;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.select2-container .select2-search--inline {
	float: left;
}

.select2-container .select2-search--inline .select2-search__field {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: none;
	font-size: 100%;
	margin-top: 5px;
	padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.select2-dropdown {
	background-color: white;
	border: 1px solid #aaa;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
	position: absolute;
	left: -100000px;
	width: 100%;
	z-index: 1051;
}

.select2-results {
	display: block;
}

.select2-results__options {
	list-style: none;
	margin: 0;
	padding: 0;
}

.select2-results__option {
	padding: 6px;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
}

.select2-results__option[aria-selected] {
	cursor: pointer;
}

.select2-container--open .select2-dropdown {
	left: 0;
}

.select2-container--open .select2-dropdown--above {
	border-bottom: none;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-bottomleft: 0;
	border-bottom-left-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-radius-bottomright: 0;
	border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
	border-top: none;
	-webkit-border-top-left-radius: 0;
	-moz-border-radius-topleft: 0;
	border-top-left-radius: 0;
	-webkit-border-top-right-radius: 0;
	-moz-border-radius-topright: 0;
	border-top-right-radius: 0;
}

.select2-search--dropdown {
	display: block;
	padding: 4px;
}

.select2-search--dropdown .select2-search__field {
	padding: 4px;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
	display: none;
}

.select2-close-mask {
	border: 0;
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 99;
	background-color: #fff;
	filter: alpha(opacity=0);
}

.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #aaa;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #444;
	line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: 100;
	width: 28px;
	right: 12px;
	font-size: 34px;
	margin-top: -4px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
	color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 26px;
	position: absolute;
	top: 1px;
	right: 1px;
	width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #888 transparent transparent transparent;
	border-style: solid;
	border-width: 5px 4px 0 4px;
	height: 0;
	left: 0;
	margin-left: -4px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
	float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
	left: 1px;
	right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
	background-color: #eee;
	cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
	display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #888 transparent;
	border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
	background-color: white;
	border: 1px solid #aaa;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0 5px;
	width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
	list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: bold;
	margin-top: -5px;
	margin-right: 10px;
	padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #e4e4e4;
	border: 1px solid #aaa;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	cursor: default;
	float: left;
	margin-right: 5px;
	margin-top: 5px;
	padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	color: #999;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;
	margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
	color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
	float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
	margin-left: 5px;
	margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
	margin-left: 2px;
	margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border: solid black 1px;
	outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
	background-color: #eee;
	cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
	display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
	-webkit-border-top-left-radius: 0;
	-moz-border-radius-topleft: 0;
	border-top-left-radius: 0;
	-webkit-border-top-right-radius: 0;
	-moz-border-radius-topright: 0;
	border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-bottomleft: 0;
	border-bottom-left-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-radius-bottomright: 0;
	border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
	background: transparent;
	border: none;
	outline: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	-webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
	max-height: 200px;
	overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
	padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
	color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
	padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
	padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -1em;
	padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -2em;
	padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -3em;
	padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -4em;
	padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -5em;
	padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #5897fb;
	color: white;
}

.select2-container--default .select2-results__group {
	cursor: default;
	display: block;
	padding: 6px;
}

.select2-container--classic .select2-selection--single {
	background-color: #f7f7f7;
	border: 1px solid #aaa;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	outline: 0;
	background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
	background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, white), to(#eeeeee));
	background-image: -moz-linear-gradient(top, white 50%, #eeeeee 100%);
	background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
	border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
	color: #444;
	line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
	cursor: pointer;
	float: right;
	font-weight: bold;
	margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
	color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
	background-color: #ddd;
	border: none;
	border-left: 1px solid #aaa;
	-webkit-border-top-right-radius: 4px;
	-moz-border-radius-topright: 4px;
	border-top-right-radius: 4px;
	-webkit-border-bottom-right-radius: 4px;
	-moz-border-radius-bottomright: 4px;
	border-bottom-right-radius: 4px;
	height: 26px;
	position: absolute;
	top: 1px;
	right: 1px;
	width: 20px;
	background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
	background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #eeeeee), to(#cccccc));
	background-image: -moz-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
	background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
	border-color: #888 transparent transparent transparent;
	border-style: solid;
	border-width: 5px 4px 0 4px;
	height: 0;
	left: 50%;
	margin-left: -4px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
	float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
	border: none;
	border-right: 1px solid #aaa;
	border-radius: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	left: 1px;
	right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
	border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
	background: transparent;
	border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #888 transparent;
	border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
	border-top: none;
	-webkit-border-top-left-radius: 0;
	-moz-border-radius-topleft: 0;
	border-top-left-radius: 0;
	-webkit-border-top-right-radius: 0;
	-moz-border-radius-topright: 0;
	border-top-right-radius: 0;
	background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
	background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(50%, #eeeeee));
	background-image: -moz-linear-gradient(top, white 0%, #eeeeee 50%);
	background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
	border-bottom: none;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-bottomleft: 0;
	border-bottom-left-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-radius-bottomright: 0;
	border-bottom-right-radius: 0;
	background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
	background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #eeeeee), to(white));
	background-image: -moz-linear-gradient(top, #eeeeee 50%, white 100%);
	background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
	background-color: white;
	border: 1px solid #aaa;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	cursor: text;
	outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
	border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
	list-style: none;
	margin: 0;
	padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
	display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
	background-color: #e4e4e4;
	border: 1px solid #aaa;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	cursor: default;
	float: left;
	margin-right: 5px;
	margin-top: 5px;
	padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
	color: #888;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;
	margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
	color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
	float: right;
	margin-left: 5px;
	margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
	margin-left: 2px;
	margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
	border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
	border-top: none;
	-webkit-border-top-left-radius: 0;
	-moz-border-radius-topleft: 0;
	border-top-left-radius: 0;
	-webkit-border-top-right-radius: 0;
	-moz-border-radius-topright: 0;
	border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
	border-bottom: none;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-bottomleft: 0;
	border-bottom-left-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-radius-bottomright: 0;
	border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
	border: 1px solid #aaa;
	outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
	outline: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.select2-container--classic .select2-dropdown {
	background-color: white;
	border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
	border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
	border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
	max-height: 200px;
	overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
	padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
	color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
	background-color: #3875d7;
	color: white;
}

.select2-container--classic .select2-results__group {
	cursor: default;
	display: block;
	padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
	border-color: #5897fb;
}

.select2-container {
	outline: none;
}

.select2-container--default {
	outline: none;
}

.select2-container--default .select2-selection--multiple {
	outline: none;
	border: solid 1px #0000bc;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	height: 50px;
	line-height: 50px;
	background: transparent;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	padding: 0 20px;
	height: 100%;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
	display: inline-block;
	float: none;
	vertical-align: middle;
	margin: 0;
	border: none;
	padding: 0;
	font-size: 18px;
	letter-spacing: 0.5px;
	background: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li::after {
	content: ",";
	padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	display: none;
}

@media (min-width: 768px) {
	.select2-container--default .select2-selection--multiple {
		height: 60px;
		line-height: 60px;
	}
}

.select2-container--default .select2-selection--single {
	outline: none;
	border: solid 1px #0000bc;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	height: 60px;
	background: transparent;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	padding: 0 20px;
	font-size: 18px;
	line-height: 60px;
	letter-spacing: 0.5px;
	color: #0000bc;
}

@media (min-width: 768px) {
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 60px;
	}
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 0;
	width: 26px;
	height: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	margin-top: -7px;
	border: none !important;
	border-right: solid 1px #0000bc !important;
	border-bottom: solid 1px #0000bc !important;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
}

@media (min-width: 768px) {
	.select2-container--default .select2-selection--single {
		height: 60px;
	}
}

.select2-container--default .select2-results__option[aria-selected="true"] {
	background: #bcbcff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background: #0000bc;
}

.select2-container--default .select2-results__option[aria-disabled="true"] {
	display: none;
}

.select2-container--default .select2-results__option[aria-disabled="true"] + .select2-results__option {
	border-top: none;
}

.select2-container--default .select2-results > .select2-results__options {
	max-height: 244px;
}

.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background-color: transparent;
}

.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-track {
	background-color: transparent;
}

.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-thumb {
	background-color: #0000bc;
	opacity: 0;
}

.select2-container--default .select2-search {
	position: relative;
	padding: 0;
}

.select2-container--default .select2-search::before {
	content: "";
	position: absolute;
	left: 19px;
	top: 18px;
	width: 25px;
	height: 26px;
	background: url("../assets/images/search.svg") 50% 50% no-repeat;
	-webkit-background-size: contain;
	-moz-background-size: contain;
	background-size: contain;
	pointer-events: none;
}

.select2-container--default .select2-search__field {
	outline: none;
	border: none !important;
	border-bottom: solid 1px #0000bc !important;
	padding: 4px 25px 0 65px;
	height: 50px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: #0000bc;
}

.select2-container--default .select2-search__field::-webkit-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.select2-container--default .select2-search__field:-moz-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.select2-container--default .select2-search__field::-moz-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.select2-container--default .select2-search__field:-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.select2-container--default .select2-search__field::-ms-input-placeholder {
	color: #0000bc;
	opacity: 0.5;
}

.select2-container--default .select2-search__field::placeholder {
	color: #0000bc;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.select2-container--default .select2-search__field {
		height: 60px;
	}
}

/*.select2-container--default .select2-search:not(.select2-search--hide) + .select2-results .select2-results__option {*/
/*	padding-left: 65px;*/
/*}*/

.select2-container--default .select2-dropdown {
	top: 5px;
	z-index: 99999;
	border: solid 1px #0000bc;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.select2-container--default .select2-results__option {
	padding: 15px 20px;
	font-size: 18px;
	line-height: 20px;
	color: #0000bc;
}

.select2-container--default .select2-results__option + .select2-results__option {
	border-top: solid 1px #0000bc;
}

@media (min-width: 768px) {
	.select2-container--default .select2-results__option {
		padding: 20px;
	}
}

.select2-container--default .select2-search--inline {
	display: none !important;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	margin-top: -2px;
	-webkit-transform: rotate(225deg);
	-moz-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	-o-transform: rotate(225deg);
	transform: rotate(225deg);
}


.xdsoft_datetimepicker {
	-webkit-box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
	-moz-box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
	box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
	background: #fff;
	border-bottom: 1px solid #bbb;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-top: 1px solid #ccc;
	color: #333;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	padding: 8px;
	padding-left: 0;
	padding-top: 2px;
	position: absolute;
	z-index: 9999;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: none;
}

.xdsoft_datetimepicker.xdsoft_rtl {
	padding: 8px 0 8px 8px;
}

.xdsoft_datetimepicker iframe {
	position: absolute;
	left: 0;
	top: 0;
	width: 75px;
	height: 210px;
	background: transparent;
	border: 0;
}

.xdsoft_datetimepicker button {
	border: none !important;
}

.xdsoft_noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.xdsoft_noselect::-moz-selection {
	background: transparent;
}

.xdsoft_noselect::selection {
	background: transparent;
}

.xdsoft_noselect::-moz-selection {
	background: transparent;
}

.xdsoft_datetimepicker.xdsoft_inline {
	display: inline-block;
	position: static;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.xdsoft_datetimepicker * {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker, .xdsoft_datetimepicker .xdsoft_timepicker {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active, .xdsoft_datetimepicker .xdsoft_timepicker.active {
	display: block;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
	width: 224px;
	margin-left: 8px;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
	float: right;
	margin-right: 8px;
	margin-left: 0;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
	width: 256px;
}

.xdsoft_datetimepicker .xdsoft_timepicker {
	width: 58px;
	margin: auto;
	text-align: center;
	margin-top: 0;
	display: block;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker {
	float: right;
	margin-right: 8px;
	margin-left: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active + .xdsoft_timepicker {
	margin-top: 8px;
	margin-bottom: 3px;
}

.xdsoft_datetimepicker .xdsoft_monthpicker {
	position: relative;
	text-align: center;
}

.xdsoft_datetimepicker .xdsoft_label i, .xdsoft_datetimepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_next, .xdsoft_datetimepicker .xdsoft_today_button {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC);
}

.xdsoft_datetimepicker .xdsoft_label i {
	opacity: .5;
	background-position: -92px -19px;
	display: inline-block;
	width: 9px;
	height: 20px;
	vertical-align: middle;
}

.xdsoft_datetimepicker .xdsoft_prev {
	float: left;
	background-position: -20px 0;
}

.xdsoft_datetimepicker .xdsoft_today_button {
	float: left;
	background-position: -70px 0;
	margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_next {
	float: right;
	background-position: 0 0;
}

.xdsoft_datetimepicker .xdsoft_next, .xdsoft_datetimepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_today_button {
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0 none;
	cursor: pointer;
	display: block;
	height: 30px;
	opacity: .5;
	-ms-filter: "alpha(opacity=50)";
	outline: medium none;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-indent: 100%;
	white-space: nowrap;
	width: 20px;
	min-width: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
	float: none;
	background-position: -40px -15px;
	height: 15px;
	width: 30px;
	display: block;
	margin-left: 14px;
	margin-top: 7px;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_prev, .xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_next {
	float: none;
	margin-left: 0;
	margin-right: 14px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
	background-position: -40px 0;
	margin-bottom: 7px;
	margin-top: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
	height: 100px;
	overflow: hidden;
	border: 1px solid #ffffff;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div {
	background: #ffffff;
	border-top: 1px solid #ffffff;
	color: #0000bc;
	font-size: 12px;
	text-align: center;
	border-collapse: collapse;
	cursor: pointer;
	border-bottom-width: 0;
	height: 25px;
	line-height: 25px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:first-child {
	border-top-width: 0;
}

.xdsoft_datetimepicker .xdsoft_today_button:hover, .xdsoft_datetimepicker .xdsoft_next:hover, .xdsoft_datetimepicker .xdsoft_prev:hover {
	opacity: 1;
	-ms-filter: "alpha(opacity=100)";
}

.xdsoft_datetimepicker .xdsoft_label {
	display: inline;
	position: relative;
	z-index: 9999;
	margin: 0;
	padding: 5px 3px;
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	background-color: #fff;
	float: left;
	width: 182px;
	text-align: center;
	cursor: pointer;
}

.xdsoft_datetimepicker .xdsoft_label:hover > span {
	text-decoration: underline;
}

.xdsoft_datetimepicker .xdsoft_label:hover i {
	opacity: 1.0;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select {
	border: 1px solid #ccc;
	position: absolute;
	right: 0;
	top: 30px;
	z-index: 101;
	display: none;
	background: #fff;
	max-height: 160px;
	overflow-y: hidden;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_monthselect {
	right: -7px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_yearselect {
	right: 2px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
	color: #fff;
	background: #ff8000;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
	padding: 2px 10px 2px 5px;
	text-decoration: none !important;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
	background: #3af;
	-webkit-box-shadow: #178fe5 0 1px 3px 0 inset;
	-moz-box-shadow: #178fe5 0 1px 3px 0 inset;
	box-shadow: #178fe5 0 1px 3px 0 inset;
	color: #fff;
	font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_month {
	width: 100px;
	text-align: right;
}

.xdsoft_datetimepicker .xdsoft_calendar {
	clear: both;
}

.xdsoft_datetimepicker .xdsoft_year {
	width: 48px;
	margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar table {
	border-collapse: collapse;
	width: 100%;
}

.xdsoft_datetimepicker .xdsoft_calendar td > div {
	padding-right: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	height: 25px;
}

.xdsoft_datetimepicker .xdsoft_calendar td, .xdsoft_datetimepicker .xdsoft_calendar th {
	width: 14.2857142%;
	background: #f5f5f5;
	border: 1px solid #ddd;
	color: #666;
	font-size: 12px;
	text-align: right;
	vertical-align: middle;
	padding: 0;
	border-collapse: collapse;
	cursor: pointer;
	height: 25px;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar td, .xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar th {
	width: 12.5%;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	background: #f1f1f1;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
	color: #3af;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_default {
	background: #ffe9d2;
	-webkit-box-shadow: #ffb871 0 1px 4px 0 inset;
	-moz-box-shadow: #ffb871 0 1px 4px 0 inset;
	box-shadow: #ffb871 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_mint {
	background: #c1ffc9;
	-webkit-box-shadow: #00dd1c 0 1px 4px 0 inset;
	-moz-box-shadow: #00dd1c 0 1px 4px 0 inset;
	box-shadow: #00dd1c 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
	background: #3af;
	-webkit-box-shadow: #178fe5 0 1px 3px 0 inset;
	-moz-box-shadow: #178fe5 0 1px 3px 0 inset;
	box-shadow: #178fe5 0 1px 3px 0 inset;
	color: #fff;
	font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled, .xdsoft_datetimepicker .xdsoft_time_box > div > div.xdsoft_disabled {
	opacity: .5;
	-ms-filter: "alpha(opacity=50)";
	cursor: default;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month.xdsoft_disabled {
	opacity: .2;
	-ms-filter: "alpha(opacity=20)";
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
	color: #fff !important;
	background: #ff8000 !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_disabled:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current.xdsoft_disabled:hover {
	background: #3af !important;
	-webkit-box-shadow: #178fe5 0 1px 3px 0 inset !important;
	-moz-box-shadow: #178fe5 0 1px 3px 0 inset !important;
	box-shadow: #178fe5 0 1px 3px 0 inset !important;
	color: #fff !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_disabled:hover {
	color: inherit !important;
	background: inherit !important;
	-webkit-box-shadow: inherit !important;
	-moz-box-shadow: inherit !important;
	box-shadow: inherit !important;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	font-weight: 700;
	text-align: center;
	color: #999;
	cursor: default;
}

.xdsoft_datetimepicker .xdsoft_copyright {
	color: #ccc !important;
	font-size: 10px;
	clear: both;
	float: none;
	margin-left: 8px;
}

.xdsoft_datetimepicker .xdsoft_copyright a {
	color: #eee !important;
}

.xdsoft_datetimepicker .xdsoft_copyright a:hover {
	color: #aaa !important;
}

.xdsoft_time_box {
	position: relative;
	border: 1px solid #ccc;
}

.xdsoft_scrollbar > .xdsoft_scroller {
	background: #ccc !important;
	height: 20px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.xdsoft_scrollbar {
	position: absolute;
	width: 7px;
	right: 0;
	top: 0;
	bottom: 0;
	cursor: pointer;
}

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_scrollbar {
	left: 0;
	right: auto;
}

.xdsoft_scroller_box {
	position: relative;
}

.xdsoft_datetimepicker.xdsoft_dark {
	-webkit-box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
	-moz-box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
	box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
	background: #000;
	border-bottom: 1px solid #444;
	border-left: 1px solid #333;
	border-right: 1px solid #333;
	border-top: 1px solid #333;
	color: #ccc;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box {
	border-bottom: 1px solid #222;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div {
	background: #0a0a0a;
	border-top: 1px solid #222;
	color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label {
	background-color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select {
	border: 1px solid #333;
	background: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
	color: #000;
	background: #007fff;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
	background: #c50;
	-webkit-box-shadow: #b03e00 0 1px 3px 0 inset;
	-moz-box-shadow: #b03e00 0 1px 3px 0 inset;
	box-shadow: #b03e00 0 1px 3px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label i, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_prev, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_next, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_today_button {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUExQUUzOTA0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUExQUUzOTE0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBQTFBRTM4RTQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBQTFBRTM4RjQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pp0VxGEAAAIASURBVHja7JrNSgMxEMebtgh+3MSLr1T1Xn2CHoSKB08+QmR8Bx9A8e7RixdB9CKCoNdexIugxFlJa7rNZneTbLIpM/CnNLsdMvNjM8l0mRCiQ9Ye61IKCAgZAUnH+mU3MMZaHYChBnJUDzWOFZdVfc5+ZFLbrWDeXPwbxIqrLLfaeS0hEBVGIRQCEiZoHQwtlGSByCCdYBl8g8egTTAWoKQMRBRBcZxYlhzhKegqMOageErsCHVkk3hXIFooDgHB1KkHIHVgzKB4ADJQ/A1jAFmAYhkQqA5TOBtocrKrgXwQA8gcFIuAIO8sQSA7hidvPwaQGZSaAYHOUWJABhWWw2EMIH9QagQERU4SArJXo0ZZL18uvaxejXt/Em8xjVBXmvFr1KVm/AJ10tRe2XnraNqaJvKE3KHuUbfK1E+VHB0q40/y3sdQSxY4FHWeKJCunP8UyDdqJZenT3ntVV5jIYCAh20vT7ioP8tpf6E2lfEMwERe+whV1MHjwZB7PBiCxcGQWwKZKD62lfGNnP/1poFAA60T7rF1UgcKd2id3KDeUS+oLWV8DfWAepOfq00CgQabi9zjcgJVYVD7PVzQUAUGAQkbNJTBICDhgwYTjDYD6XeW08ZKh+A4pYkzenOxXUbvZcWz7E8ykRMnIHGX1XPl+1m2vPYpL+2qdb8CDAARlKFEz/ZVkAAAAABJRU5ErkJggg==);
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	background: #0a0a0a;
	border: 1px solid #222;
	color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	background: #0e0e0e;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_today {
	color: #c50;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_default {
	background: #ffe9d2;
	-webkit-box-shadow: #ffb871 0 1px 4px 0 inset;
	-moz-box-shadow: #ffb871 0 1px 4px 0 inset;
	box-shadow: #ffb871 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_mint {
	background: #c1ffc9;
	-webkit-box-shadow: #00dd1c 0 1px 4px 0 inset;
	-moz-box-shadow: #00dd1c 0 1px 4px 0 inset;
	box-shadow: #00dd1c 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
	background: #c50;
	-webkit-box-shadow: #b03e00 0 1px 3px 0 inset;
	-moz-box-shadow: #b03e00 0 1px 3px 0 inset;
	box-shadow: #b03e00 0 1px 3px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td:hover, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
	color: #000 !important;
	background: #007fff !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	color: #666;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright {
	color: #333 !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a {
	color: #111 !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a:hover {
	color: #555 !important;
}

.xdsoft_dark .xdsoft_time_box {
	border: 1px solid #333;
}

.xdsoft_dark .xdsoft_scrollbar > .xdsoft_scroller {
	background: #333 !important;
}

.xdsoft_datetimepicker .xdsoft_save_selected {
	display: block;
	border: 1px solid #ddd !important;
	margin-top: 5px;
	width: 100%;
	color: #454551;
	font-size: 13px;
}

.xdsoft_datetimepicker .blue-gradient-button {
	font-family: "museo-sans", "Book Antiqua", sans-serif;
	font-size: 12px;
	font-weight: 300;
	color: #82878c;
	height: 28px;
	position: relative;
	padding: 4px 17px 4px 33px;
	border: 1px solid #d7d8da;
	background: -moz-linear-gradient(top, #fff 0, #f4f8fa 73%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(73%, #f4f8fa));
	background: -webkit-linear-gradient(top, #fff 0, #f4f8fa 73%);
	background: -o-linear-gradient(top, #fff 0, #f4f8fa 73%);
	background: linear-gradient(to bottom, #fff 0, #f4f8fa 73%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#f4f8fa', GradientType=0);
}

.xdsoft_datetimepicker .blue-gradient-button:hover, .xdsoft_datetimepicker .blue-gradient-button:focus, .xdsoft_datetimepicker .blue-gradient-button:hover span, .xdsoft_datetimepicker .blue-gradient-button:focus span {
	color: #454551;
	background: -moz-linear-gradient(top, #f4f8fa 0, #FFF 73%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f4f8fa), color-stop(73%, #FFF));
	background: -webkit-linear-gradient(top, #f4f8fa 0, #FFF 73%);
	background: -o-linear-gradient(top, #f4f8fa 0, #FFF 73%);
	background: linear-gradient(to bottom, #f4f8fa 0, #FFF 73%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f8fa', endColorstr='#FFF', GradientType=0);
}


.xdsoft_datetimepicker {
	z-index: 99999;
	margin-top: 5px;
	border: solid 1px #0000bc;
	padding: 20px 40px;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
	margin: 0;
	width: 280px;
}

.xdsoft_datetimepicker .xdsoft_monthpicker {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 8px;
}

.xdsoft_datetimepicker .xdsoft_today_button {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_year {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_month {
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-moz-box-ordinal-group: 0;
	-ms-flex-order: -1;
	order: -1;
	margin-right: auto;
	padding: 0;
	font-weight: 300;
	font-size: 13px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
	color: #0000bc;
}

.xdsoft_datetimepicker .xdsoft_month i {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_month .xdsoft_monthselect {
	left: 0;
	right: auto !important;
	top: 20px;
	border: solid 1px #0000bc;
}

.xdsoft_datetimepicker .xdsoft_month .xdsoft_monthselect .xdsoft_option:hover {
	background: #0000bc !important;
}

.xdsoft_datetimepicker .xdsoft_month .xdsoft_monthselect .xdsoft_option.xdsoft_current {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-weight: 300 !important;
	background: #0000bc !important;
}

.xdsoft_datetimepicker .xdsoft_scroller {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	background: #0000bc !important;
}

.xdsoft_datetimepicker .xdsoft_calendar {
	margin: 0 -10px;
}

.xdsoft_datetimepicker .xdsoft_calendar table {
	border: none;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	border: none;
	padding: 0;
	font-weight: 300;
	font-size: 13px;
	letter-spacing: 2px;
	color: #0000bc;
	background: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td {
	border: none;
	padding: 6px 0;
	height: auto;
	font-weight: 300;
	font-size: 14px;
	letter-spacing: 2px;
	text-align: center;
	color: #0000bc;
	background: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td div {
	margin: 0 auto;
	padding: 0 0 0 2px;
	width: 24px;
	height: 24px;
	line-height: 24px;
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	font-weight: 300;
	background: none !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover div {
	background: #0000bc;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	font-weight: 300;
	color: #fff;
	background: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today div {
	margin: 0 auto;
	width: 24px;
	background: #d6d6ff;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	font-weight: 300;
	background: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current div {
	margin: 0 auto;
	width: 24px;
	background: #0000bc;
}

.xdsoft_datetimepicker .xdsoft_prev {
	position: relative;
	background: none;
	opacity: 1;
}

.xdsoft_datetimepicker .xdsoft_prev::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -5px 0 0 -2px;
	border-bottom: solid 1px #0000bc;
	border-left: solid 1px #0000bc;
	width: 10px;
	height: 10px;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.xdsoft_datetimepicker .xdsoft_next {
	position: relative;
	margin: 0 0 0 14px;
	background: none;
	opacity: 1;
}

.xdsoft_datetimepicker .xdsoft_next::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -5px 0 0 -7px;
	border-right: solid 1px #0000bc;
	border-bottom: solid 1px #0000bc;
	width: 10px;
	height: 10px;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.Toastify__toast-container {
	z-index: 9999;
	-webkit-transform: translate3d(0, 0, 9999px);
	position: fixed;
	padding: 4px;
	width: 320px;
	box-sizing: border-box;
	color: #fff;
}

.Toastify__toast-container--top-left {
	top: 1em;
	left: 1em;
}

.Toastify__toast-container--top-center {
	top: 1em;
	left: 50%;
	transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
	top: 1em;
	right: 1em;
}

.Toastify__toast-container--bottom-left {
	bottom: 1em;
	left: 1em;
}

.Toastify__toast-container--bottom-center {
	bottom: 1em;
	left: 50%;
	transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
	bottom: 1em;
	right: 1em;
}

@media only screen and (max-width: 480px) {
	.Toastify__toast-container {
		width: 100vw;
		padding: 0;
		left: 0;
		margin: 0;
	}

	.Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
		top: 0;
		transform: translateX(0);
	}

	.Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
		bottom: 0;
		transform: translateX(0);
	}

	.Toastify__toast-container--rtl {
		right: 0;
		left: initial;
	}
}

.Toastify__toast {
	position: relative;
	min-height: 64px;
	box-sizing: border-box;
	margin-bottom: 1rem;
	padding: 8px;
	border-radius: 1px;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	max-height: 800px;
	overflow: hidden;
	font-family: sans-serif;
	cursor: pointer;
	direction: ltr;
}

.Toastify__toast--rtl {
	direction: rtl;
}

.Toastify__toast--dark {
	background: #121212;
	color: #fff;
}

.Toastify__toast--default {
	background: #fff;
	color: #aaa;
}

.Toastify__toast--info {
	background: #3498db;
}

.Toastify__toast--success {
	background: #07bc0c;
}

.Toastify__toast--warning {
	background: #f1c40f;
}

.Toastify__toast--error {
	background: #e74c3c;
}

.Toastify__toast-body {
	margin: auto 0;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

@media only screen and (max-width: 480px) {
	.Toastify__toast {
		margin-bottom: 0;
	}
}

.Toastify__close-button {
	color: #fff;
	background: transparent;
	outline: none;
	border: none;
	padding: 0;
	cursor: pointer;
	opacity: 0.7;
	transition: 0.3s ease;
	-ms-flex-item-align: start;
	align-self: flex-start;
}

.Toastify__close-button--default {
	color: #000;
	opacity: 0.3;
}

.Toastify__close-button > svg {
	fill: currentColor;
	height: 16px;
	width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
	opacity: 1;
}

@keyframes Toastify__trackProgress {
	0% {
		transform: scaleX(1);
	}
	100% {
		transform: scaleX(0);
	}
}

.Toastify__progress-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5px;
	z-index: 9999;
	opacity: 0.7;
	background-color: rgba(255, 255, 255, 0.7);
	transform-origin: left;
}

.Toastify__progress-bar--animated {
	animation: Toastify__trackProgress linear 1 forwards;
}

.Toastify__progress-bar--controlled {
	transition: transform 0.2s;
}

.Toastify__progress-bar--rtl {
	right: 0;
	left: initial;
	transform-origin: right;
}

.Toastify__progress-bar--default {
	background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

.Toastify__progress-bar--dark {
	background: #bb86fc;
}

@keyframes Toastify__bounceInRight {
	from, 60%, 75%, 90%, to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	from {
		opacity: 0;
		transform: translate3d(3000px, 0, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(-25px, 0, 0);
	}
	75% {
		transform: translate3d(10px, 0, 0);
	}
	90% {
		transform: translate3d(-5px, 0, 0);
	}
	to {
		transform: none;
	}
}

@keyframes Toastify__bounceOutRight {
	20% {
		opacity: 1;
		transform: translate3d(-20px, 0, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}
}

@keyframes Toastify__bounceInLeft {
	from, 60%, 75%, 90%, to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		transform: translate3d(-3000px, 0, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(25px, 0, 0);
	}
	75% {
		transform: translate3d(-10px, 0, 0);
	}
	90% {
		transform: translate3d(5px, 0, 0);
	}
	to {
		transform: none;
	}
}

@keyframes Toastify__bounceOutLeft {
	20% {
		opacity: 1;
		transform: translate3d(20px, 0, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(-2000px, 0, 0);
	}
}

@keyframes Toastify__bounceInUp {
	from, 60%, 75%, 90%, to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	from {
		opacity: 0;
		transform: translate3d(0, 3000px, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}
	75% {
		transform: translate3d(0, 10px, 0);
	}
	90% {
		transform: translate3d(0, -5px, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes Toastify__bounceOutUp {
	20% {
		transform: translate3d(0, -10px, 0);
	}
	40%, 45% {
		opacity: 1;
		transform: translate3d(0, 20px, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, -2000px, 0);
	}
}

@keyframes Toastify__bounceInDown {
	from, 60%, 75%, 90%, to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		transform: translate3d(0, -3000px, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0);
	}
	75% {
		transform: translate3d(0, -10px, 0);
	}
	90% {
		transform: translate3d(0, 5px, 0);
	}
	to {
		transform: none;
	}
}

@keyframes Toastify__bounceOutDown {
	20% {
		transform: translate3d(0, 10px, 0);
	}
	40%, 45% {
		opacity: 1;
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, 2000px, 0);
	}
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
	animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
	animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
	animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
	animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
	animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
	animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
	animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
	animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
	50% {
		opacity: 1;
	}
}

@keyframes Toastify__zoomOut {
	from {
		opacity: 1;
	}
	50% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
	to {
		opacity: 0;
	}
}

.Toastify__zoom-enter {
	animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
	animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
	from {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		animation-timing-function: ease-in;
	}
	60% {
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}
	80% {
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}
	to {
		transform: perspective(400px);
	}
}

@keyframes Toastify__flipOut {
	from {
		transform: perspective(400px);
	}
	30% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		opacity: 1;
	}
	to {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		opacity: 0;
	}
}

.Toastify__flip-enter {
	animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
	animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
	from {
		transform: translate3d(110%, 0, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes Toastify__slideInLeft {
	from {
		transform: translate3d(-110%, 0, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes Toastify__slideInUp {
	from {
		transform: translate3d(0, 110%, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes Toastify__slideInDown {
	from {
		transform: translate3d(0, -110%, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes Toastify__slideOutRight {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(110%, 0, 0);
	}
}

@keyframes Toastify__slideOutLeft {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(-110%, 0, 0);
	}
}

@keyframes Toastify__slideOutDown {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(0, 500px, 0);
	}
}

@keyframes Toastify__slideOutUp {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		transform: translate3d(0, -500px, 0);
	}
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
	animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
	animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
	animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
	animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
	animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
	animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
	animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
	animation-name: Toastify__slideOutDown;
}


/* REMOVE */

.rc-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 80;
}

.rc-backdrop.invert {
	background: rgba(1, 1, 1, .7);
}

.range-picker-input {
	display: inline-flex;
	border: 1px solid #e4e4e4;
	width: 300px;
}

.range-picker-input * {
	box-sizing: border-box;
}

.range-picker-input__icon {
	display: inline-flex;
	align-items: center;
}

.range-picker-input__start, .range-picker-input__end {
	display: inline-flex;
	flex: 1;
}

.range-picker-input__start .picker-input.range input, .range-picker-input__end .picker-input.range input {
	width: 100%;
	border: none;
}

.picker-input {
	display: inline-block;
	position: relative;
	width: 100%;
}

.picker-input_button {
	font-size: 13px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	line-height: 13px;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 4px;
	width: 100%;
	height: 60px;
	font-weight: 300;
	letter-spacing: 0.5px;
	color: #0000bc;
	background: transparent;
	position: relative;
	cursor: pointer;
}

.picker-input_button-placeholder {
	font-size: 18px;
	opacity: 0.5;
}
.picker-input_button-value {
	font-size: 18px;
}

.picker-input__icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 10px;
	display: inline-flex;
	align-items: center;
}

.picker-input__text {
	padding: 10px;
	border: 1px solid #e4e4e4;
	outline: none;
	font-size: 16.8px;
}

.picker-input__text:disabled {
	background: #e4e4e4;
}

.picker-input__clear {
	position: absolute;
	top: 0;
	width: 54px;
	height: 100%;
	right: 0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.picker-input__clear svg {
	width: 20px;
	height: 20px;
}

.picker {
	display: inline-block;
	width: 100%;
}

.picker__container {
	position: absolute;
	z-index: 100;
}

.picker-range {
	border: solid 1px #0000bc;
}


.picker-range .calendar__item {
	padding: 0 28px;
}

.picker__container.portal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.picker__container__include-time {
}

.picker__container__include-time .calendar__item, .picker__container__include-time .time__container {
	border: none;
}

.picker__container__tab {
	margin: 10px 0;
}

.picker__container__tab button {
	padding: 5px 10px;
	outline: none;
	display: inline-flex;
	align-items: center;
	background: none;
	border: none;
	border-bottom: 2px solid #e4e4e4;
}

.picker__container__tab button.active {
	color: #49599a;
	border-bottom: 2px solid #49599a;
}

.picker__container__tab button:first-child {
	border-right: none;
}

.picker__container__tab button svg {
	margin-right: 5px;
}

.time__container {
	display: inline-flex;
	align-items: center;
	border: 1px solid #e4e4e4;
	padding: 15px;
	background: white;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 12px;
}

.time__container__div {
	margin: 0 10px;
}

.time__container__type {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}

.time-input {
	display: inline-block;
	width: 40px;
	overflow: hidden;
}

.time-input__up, .time-input__down {
	border: 1px solid #e4e4e4;
}

.time-input__up button, .time-input__down button {
	outline: none;
	width: 100%;
	cursor: pointer;
	border: none;
}

.time-input__text {
	width: 100%;
	border-left: 1px solid #e4e4e4;
	border-right: 1px solid #e4e4e4;
	box-sizing: border-box;
}

.time-input__text input {
	width: 100%;
	box-sizing: border-box;
	border: none;
	font-size: 15px;
	padding: 5px !important;
	text-align: center;
	outline: none;
}

.calendar {
	display: block;
	background: white;
	font-size: 12px;
}

.calendar *, .calendar *:before, .calendar *:after {
	box-sizing: border-box;
}

.calendar__container {
	width: 270px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	display: none;
}

.calendar__list {
	display: table;
	padding: 24px;
}

.calendar__item {
	display: table-cell;
}

.calendar__item:not(:first-child) {
	border-left: none !important;
}

.calendar--show {
	display: inline-block;
}

.calendar__head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
	position: relative;
	text-align: center;
	color: #0a0a0a;
}

.calendar__head--title {
	order: -1;
	padding: 0;
	font-weight: 300;
	font-size: 13px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
	color: #0000bc;
}

.calendar__head--button {
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0 none;
	cursor: pointer;
	display: block;
	height: 30px;
	opacity: .5;
	-ms-filter: "alpha(opacity=50)";
	outline: medium none;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-indent: 100%;
	white-space: nowrap;
	width: 20px;
	min-width: 0;
}

.calendar__head--buttons {
	display: flex;
	justify-content: center;
	align-items: center;
}

.calendar__head--button:before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	width: 10px;
	height: 10px;
}

.calendar__head--prev, .calendar__head--next {
}

.calendar__head--prev .calendar__head--button:before {
	margin: -5px 0 0 -2px;
	border-bottom: solid 1px #0000bc;
	border-left: solid 1px #0000bc;
	transform: rotate(45deg);
}

.calendar__head--next .calendar__head--button:before {
	margin: -5px 0 0 -7px;
	border-right: solid 1px #0000bc;
	border-bottom: solid 1px #0000bc;
	transform: rotate(-45deg);
}

.calendar__panel--show {
	display: block !important;
}

.calendar__panel--today {
	background: #aab6fe;
	padding: 5px;
	display: none;
}

.calendar__panel--today h2 {
	margin: 0;
	cursor: pointer;
	font-size: 12px;
	text-align: center;
}

.calendar__body {
	margin: 0 -10px;
}

.calendar__body--table {
	width: 100%;
	table-layout: fixed;
	text-align: center;
	border-spacing: 0;
	border-collapse: collapse;
}

.calendar__body--table th {
	border: none;
	padding: 0;
	font-weight: 300;
	font-size: 13px;
	letter-spacing: 2px;
	color: #0000bc;
	background: none;
}

.calendar__day {
	border: none;
	padding: 6px 0;
	height: auto;
	font-weight: 300;
	font-size: 14px;
	letter-spacing: 2px;
	text-align: center;
	color: #0000bc;
	background: none;
}

.calendar__day div {
	margin: 0 auto;
	padding: 0 0 0 2px;
	width: 24px;
	height: 24px;
	line-height: 24px;
	cursor: pointer;
}

.calendar__day self:hover:not(self--disabled) {
	cursor: pointer;
	background: #0000bc;
	color: #FFFFFF;
}

.calendar__day--today div {
	background: #d6d6ff;
}

.calendar__day--disabled {
	opacity: .2;
}

.calendar__day--disabled > div {
	cursor: default;
}

.calendar__day--start div, .calendar__day--end div, .calendar__day--selected div {
	background: #0000bc;
	color: #fff;
}

.calendar__day--start:hover, .calendar__day--end:hover, .calendar__day--selected:hover {
	background: #7986cb;
}

.calendar__day--range {
	background: #dce1ff;
}

.calendar__day--text {
	display: block;
	font-size: 10px;
}

.calendar__year, .calendar__month {
	height: 55px;
	vertical-align: middle;
}

.calendar__year:hover, .calendar__month:hover {
	background: #aab6fe;
	cursor: pointer;
}

.picker__container__include-time, .picker__container__calonly, .picker__container__timeonly {
	margin-top: 5px;
	border: solid 1px #0000bc;
	background-color: #FFFFFF;
	padding: 20px 35px;
	box-shadow: none;
	display: flex;
	flex-direction: column;
	align-items: center;
}
