
.btn {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-left: 9px;
	outline: none;
	border: solid 1px #0000bc;
	padding: 16px 20px 12px;
	min-width: 200px;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	text-align: left;
	text-decoration: none;
	color: #0000bc;
	fill: #0000bc;
	background: transparent;
	-webkit-transition: 0.25s;
	-o-transition: 0.25s;
	-moz-transition: 0.25s;
	transition: 0.25s;
}

.btn::before, .btn::after {
	content: "";
	position: absolute;
	-webkit-transition: 0.25s;
	-o-transition: 0.25s;
	-moz-transition: 0.25s;
	transition: 0.25s;
}

.btn::before {
	right: 100%;
	top: 9px;
	border-top: solid 1px #0000bc;
	width: 10px;
	height: 1px;
}

.btn::after {
	right: 9px;
	top: 100%;
	border-right: solid 1px #0000bc;
	width: 1px;
	height: 10px;
}

.btn i {
	position: absolute;
	right: 10px;
	top: 10px;
	border-bottom: solid 1px #0000bc;
	border-left: solid 1px #0000bc;
	width: 100%;
	height: 100%;
	-webkit-transition: 0.25s;
	-o-transition: 0.25s;
	-moz-transition: 0.25s;
	transition: 0.25s;
}

.btnArrow {
	position: absolute;
	right: 20px;
	top: 50%;
	width: 15px;
	height: 14px;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.btn:hover {
	color: #fff;
	fill: #fff;
	background: #0000bc;
}

.btn:active::before {
	top: 4px;
	width: 5px;
}

.btn:active::after {
	right: 4px;
	height: 5px;
}

.btn:active i {
	right: 5px;
	top: 5px;
}

.btn:disabled {
	opacity: 0.3;
	pointer-events: none;
}

.btn:disabled:hover {
	background: transparent;
}

@media (min-width: 768px) {
	.btn {
		padding: 19px 20px;
	}
}

.btnPrimary {
	border-color: #333;
	color: #333;
	fill: #333;
}

.btnPrimary::before, .btnPrimary::after {
	border-color: #333;
}

.btnPrimary i {
	border-color: #333;
}

.btnPrimary:hover {
	color: #fff;
	fill: #fff;
	background: #333;
}

.addBtn.btn {
	position: relative;
	margin: 0;
	padding-left: 45px;
	width: 100%;
	font-size: 16px;
	letter-spacing: 0.5px;
}

.addBtn.btn::before, .addBtn.btn::after {
	content: "";
	position: absolute;
	top: 50%;
	border: none;
	background: #0000bc;
}

.addBtn.btn::before {
	left: 12px;
	margin-top: -1px;
	width: 19px;
	height: 1px;
}

@media (min-width: 1200px) {
	.addBtn.btn::before {
		left: 22px;
	}
}

.addBtn.btn::after {
	left: 21px;
	margin-top: -10px;
	width: 1px;
	height: 19px;
}

@media (min-width: 1200px) {
	.addBtn.btn::after {
		left: 31px;
	}
}

.addBtn.btn i {
	display: none;
}

.addBtn.btn:hover::before, .addBtn.btn:hover::after {
	background: #fff;
}

@media (min-width: 768px) {
	.addBtn.btn {
		width: auto;
	}
}

@media (min-width: 1024px) {
	.addBtn.btn {
		padding-right: 10px;
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.addBtn.btn {
		padding-left: 65px;
		font-size: 18px;
	}
}

.onlyIcon {
	min-width: auto;
	border: 0;
	padding: 0;
	position: absolute;
}

.onlyIcon i, .onlyIcon:before, .onlyIcon:after {
	display: none;
}

.onlyIcon svg {
	display: block;
	width: 25px;
	height: 25px;
}

@media (min-width: 768px) {
	.kitRemove {
		right: -50px;
		top: 35px;
	}
}
