.input {
	width: 35%;
}

.comment {
	width: 55%;
}

.select {
	width: 55%;
}


@media (max-width: 1200px) {
	.input {
		width: 50%;
	}
}
@media (max-width: 768px) {
	.input {
		width: 100%;
	}
}
