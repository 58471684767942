.container {
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}

@media (min-width: 768px) {
	.container {
		padding: 0 10px;
		max-width: 720px;
	}
}

@media (min-width: 1024px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

.content {
	padding: 30px 0;
}

@media (min-width: 768px) {
	.content {
		padding: 100px 0;
	}
}


.wrapperContent {
	position: relative;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-moz-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	width: 100%;
}

.wrapperContentWhite {
	padding: 20px 15px 30px;
	color: #0000bc;
	background: #fff;
}

@media (min-width: 768px) {
	.wrapperContentWhite {
		padding: 25px 30px;
	}
}

@media (min-width: 1024px) {
	.wrapperContentWhite {
		padding: 53px 60px;
	}
}

@media (min-width: 768px) {
	.wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
	}
}

.side {
	margin-bottom: 30px;
	flex-direction: column;
}


@media (min-width: 1024px) {
	.side {
		height: 1325px;
		display: flex;
		justify-content: space-between;
	}
}


.close {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 5;
	outline: none;
	border: none;
	width: 36px;
	height: 36px;
	background: none;
}

.close::before, .close::after {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	height: 2px;
	background: #fff;
}

.close::before {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.close::after {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.name {
	margin-bottom: 10px;
	font-size: 10px;
	letter-spacing: 2px;
	text-transform: uppercase;
	opacity: 0.7;
}

@media (min-width: 1200px) {
	.name {
		font-size: 11px;
	}
}

.value {
	font-size: 18px;
	line-height: 30px;
}

@media (min-width: 1200px) {
	.value {
		font-size: 22px;
		line-height: 36px;
	}
}

.number {
	font-weight: 200;
	font-size: 30px;
}

@media (min-width: 1200px) {
	.number {
		margin-top: 13px;
		font-size: 40px;
	}
}

.footnote {
	font-size: 16px;
	letter-spacing: 0.5px;
}

.block + .block {
	margin-top: 27px;
}

.block + .block--margin {
	margin-top: 63px;
}
.config {

}

.contact {

}

@media (max-width: 767px) {
	.side {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 45;
		overflow: auto;
		padding: 30px 15px;
		width: 100%;
		height: 100%;
		background: #0000bc;
		opacity: 0;
		visibility: hidden;
		-webkit-transition: 0.5s;
		-o-transition: 0.5s;
		-moz-transition: 0.5s;
		transition: 0.5s;
	}

	.isShowInfo .side {
		opacity: 1;
		visibility: visible;
	}
}

@media (min-width: 768px) {
	.side {
		flex-shrink: 0;
		margin: 0 15px 0 0;
		width: 160px;
	}
}

@media (min-width: 1024px) {
	.side {
		width: 240px;
	}
}

@media (min-width: 1200px) {
	.side {
		width: 275px;
	}
}

.sideBtn {
	display: block;
	border: solid 1px #fff;
	padding: 15px;
	text-align: center;
	text-decoration: none;
	color: #fff;
	margin-bottom: 30px;
}
