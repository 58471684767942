.pager {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;
}

.pager ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.pager ul li {
	display: inline-block;
	vertical-align: middle;
}

.pager ul li span {
	display: block;
	width: 22px;
	height: 22px;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.5px;
	text-align: center;
	text-decoration: none;
	color: #0000bc;
	transition: 0.25s;
	cursor: pointer;
}

.pager ul li span:hover, .pager ul li span.isActive {
	color: #fff;
	background: #0000bc;
}

.pagerPrev {
	position: relative;
	display: block;
	margin-right: 5px;
	margin-top: 5px;
	width: 22px;
	height: 22px;
}

.pagerPrev::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -5px 0 0 -2px;
	border-bottom: solid 1px #0000bc;
	border-left: solid 1px #0000bc;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
}

.pagerNext {
	position: relative;
	display: block;
	margin-left: 5px;
	margin-top: 5px;
	width: 22px;
	height: 22px;
}

.pagerNext::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -5px 0 0 -7px;
	border-right: solid 1px #0000bc;
	border-bottom: solid 1px #0000bc;
	width: 10px;
	height: 10px;
	transform: rotate(-45deg);
}
