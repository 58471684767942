
.container {
	position: relative;
	display: inline-flex;
	user-select: none;
	cursor: pointer;
	margin: 0 10px;
	-webkit-tap-highlight-color: transparent;
}

.tooltipContent {
	color: #0000bc;
	position: absolute;
	z-index: 10;
	padding: 12px;
	font-size: 12px;
	cursor: default;
	border-radius: 3px;
	white-space: nowrap;
	background-color: #FFFFFF;
	border: solid 1px #0000bc;
	animation: fadeIn ease-in-out 0.65s;
}

.bottom {
	top: calc(100% + 18px);
	left: 50%;
	transform: translateX(-50%);
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}
