
.container {
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}

@media (min-width: 768px) {
	.container {
		padding: 0 10px;
		max-width: 720px;
	}
}

@media (min-width: 1024px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

.content {
	padding: 30px 0;
}

@media (min-width: 768px) {
	.content {
		padding: 100px 0;
	}
}

.wrapperContent {
	position: relative;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-moz-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	width: 100%;
}

.wrapperContentWhite {
	padding: 20px 15px 30px;
	color: #0000bc;
	background: #fff;
}

@media (min-width: 768px) {
	.wrapperContentWhite {
		padding: 25px 30px;
	}
}

@media (min-width: 1024px) {
	.wrapperContentWhite {
		padding: 53px 60px;
	}
}

@media (min-width: 768px) {
	.wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
	}
}

@media (min-width: 768px) {
	.visibleXs {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.hiddenXs {
		display: none !important;
	}
}

.close {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 5;
	outline: none;
	border: none;
	width: 25px;
	height: 25px;
	background: none;
}

.close::before, .close::after {
	content: "";
	position: absolute;
	left: 10%;
	top: 50%;
	width: 80%;
	height: 1px;
	background: #0000bc;
}

.close::before {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.close::after {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.close {
		right: 20px;
		top: 20px;
	}
}

.back {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 5;
	outline: none;
	border: none;
	padding: 5px;
	width: 25px;
	height: 25px;
	fill: #0000bc;
	background: none;
}

.back svg {
	display: block;
	width: 100%;
	height: 100%;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}

@media (min-width: 768px) {
	.back {
		left: 20px;
		top: 20px;
	}
}

.title {
	margin: 0 0 30px;
	font-weight: 200;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.titleAdd {
	display: inline-block;
	vertical-align: middle;
	margin: -4px 0 0 15px;
	text-decoration: none;
}

.titleAdd svg {
	display: block;
	width: 26px;
	height: 26px;
}

@media (min-width: 768px) {
	.titleAdd svg {
		width: 32px;
		height: 32px;
	}
}

@media (min-width: 1024px) {
	.titleAdd svg {
		width: 38px;
		height: 38px;
	}
}

@media (min-width: 768px) {
	.titleAdd {
		margin: -6px 0 0 20px;
	}
}

@media (min-width: 1024px) {
	.titleAdd {
		margin: -8px 0 0 24px;
	}
}

@media (min-width: 768px) {
	.title {
		font-size: 30px;
		line-height: 40px;
	}
}

@media (min-width: 1024px) {
	.title {
		margin-bottom: 37px;
		font-size: 36px;
		line-height: 50px;
		letter-spacing: 3px;
	}
}

.row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0 -5px;
}

@media (min-width: 768px) {
	.row {
		margin: 0 -10px;
	}
}

label.labelError {
	display: block;
	margin-top: 9px;
	font-size: 12px;
	color: #e40000;
}

.col {
	padding: 0 5px;
}

@media (min-width: 768px) {
	.col {
		padding: 0 10px;
	}
}

.col6 {
	padding: 0 5px;
	width: 50%;
}

@media (min-width: 768px) {
	.col6 {
		padding: 0 10px;
	}
}

.colSm4 {
	padding: 0 5px;
	width: 100%;
}

@media (min-width: 768px) {
	.colSm4 {
		padding: 0 10px;
		width: 33.33334%;
	}
}

.colMd4 {
	padding: 0 5px;
	width: 100%;
}

@media (min-width: 768px) {
	.colMd4 {
		padding: 0 10px;
	}
}

@media (min-width: 1024px) {
	.colMd4 {
		width: 33.33334%;
	}
}

.btnSubmit {
	margin-top: 60px;
}

.kit {
	position: relative;
}

.kitRemove {
	position: absolute;
	right: -5px;
	top: 30px;
	outline: none;
	border: none;
	padding: 0;
	color: #0000bc;
	fill: #0000bc;
	background: none;
	cursor: pointer;
}
.kitRemoveEdited {
	right: 5px;
}

.kitRemove:hover {
	color: #0000bc;
	fill: #0000bc;
}

.kitRemove svg {
	display: block;
	width: 25px;
	height: 25px;
	right: 0;
	top: 0;
}

@media (min-width: 768px) {
	.kitRemove {
		right: -50px;
		top: 35px;
	}
	.kitRemoveEdited {
		right: -45px;
		top: 15px;
	}
}

@media (max-width: 768px) {
	.kitRemove {
		right: -50px;
		top: 35px;
	}
	.kitRemoveEdited {
		right: -30px;
		top: 15px;
	}
}

.disabledForm {
	opacity: 0.3;
	pointer-events: none;
}

.formButtonWrapper {
	margin-right: 30px;
}

.balanceLine {
	display: flex;
	width: 100%;
	justify-content: space-around;
}
