
.smallSwitch {
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 20px;
	cursor: pointer;
}

@media (min-width: 768px) {
	.smallSwitch + .smallSwitch {
		margin-left: 30px;
	}
}

.smallSwitch input {
	display: none;
}

.smallSwitch input:checked + .smallSwitchIcon {
	background: #0000bc;
}

.smallSwitch input:checked + .smallSwitchIcon::before {
	left: 36px;
	background: #fff;
}

.smallSwitch input:checked + .smallSwitchIcon::after {
	opacity: 1;
	visibility: visible;
}

.smallSwitch input:checked + .smallSwitchIcon i {
	opacity: 0;
	visibility: hidden;
}

.smallSwitchIcon {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	border: solid 1px #0000bc;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	width: 60px;
	height: 30px;
	background: #fff;
	-webkit-transition: 0.25s;
	-o-transition: 0.25s;
	-moz-transition: 0.25s;
	transition: 0.25s;
}

.smallSwitchIcon::before {
	content: "";
	position: absolute;
	left: 4px;
	top: 5px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	background: #0000bc;
	-webkit-transition: 0.25s;
	-o-transition: 0.25s;
	-moz-transition: 0.25s;
	transition: 0.25s;
}

.smallSwitchIcon::after {
	content: "";
	position: absolute;
	left: 12px;
	top: 5px;
	border-right: solid 1px #fff;
	border-bottom: solid 1px #fff;
	width: 8px;
	height: 14px;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: rotate(38deg);
	-moz-transform: rotate(38deg);
	-ms-transform: rotate(38deg);
	-o-transform: rotate(38deg);
	transform: rotate(38deg);
	-webkit-transition: 0.25s;
	-o-transition: 0.25s;
	-moz-transition: 0.25s;
	transition: 0.25s;
}

.smallSwitchIcon i {
	position: absolute;
	right: 7px;
	top: 5px;
	width: 17px;
	height: 17px;
	-webkit-transition: 0.25s;
	-o-transition: 0.25s;
	-moz-transition: 0.25s;
	transition: 0.25s;
}

.smallSwitchIcon i::before, .smallSwitchIcon i::after {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	height: 1px;
	background: #0000bc;
}

.smallSwitchIcon i::before {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.smallSwitchIcon i::after {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	.smallSwitchIcon {
		width: 60px;
		height: 30px;
	}
}

.smallSwitchLabel {
	display: inline-block;
	vertical-align: middle;
	margin-left: 8px;
	padding-top: 2px;
	font-size: 14px;
	letter-spacing: 0.5px;
}

@media (min-width: 768px) {
	.smallSwitchLabel {
		font-size: 16px;
	}
}

.smallSwitchInRow {
	margin-top: 40px;
}
