.row {
	width: 100%;
}

.options {
	display: flex;
	justify-content: flex-end;
	list-style-type: none;
	margin: 0 0 42px;
	padding: 0;
	width: calc(100vw - 60px);
}

.options li + li {
	margin-left: 15px;
}

.options li span {
	fill: #0000bc;
	cursor: pointer;
}

.options li span svg {
	display: block;
	width: 25px;
	height: 25px;
}

.isEdit .options li span.isEdit {
	display: none;
}

.isEdit .options li span.isRefresh {
	display: none;
}

@media (min-width: 768px) {
	.options {
		width: auto;
	}
}

.wrapper {

}

.input {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
}

.buttons > * {
	margin-right: 30px;
}

.kit {
	grid-column-start: 1;
	grid-column-end: 4;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
}

.kitButton {
	grid-column-start: 1;
	grid-column-end: 4;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
}


@media (max-width: 768px) {
	.buttons {
		width: calc(100vw - 60px);
	}
	.buttons > * {
		margin-top: 20px;
	}
	.input {
		display: block;
		width: calc(100vw - 90px);
	}
	.kit {
		display: block;
		width: calc(100vw - 90px);
	}
}

.switch {
	margin-top: 0
}


.table table {
	width: 100%;
}

.table table th {
	padding: 0 10px 2px;
	font-weight: 300;
	font-size: 10px;
	letter-spacing: 2px;
	text-align: left;
	text-transform: uppercase;
}

.table table th:first-child {
	padding-left: 0;
}

.table table th:last-child {
	padding-right: 0;
}

@media (min-width: 1024px) {
	.table table th {
		font-size: 11px;
	}
}

.table table td {
	padding: 5px 10px;
}

.table table .disabled td {
	color: rgba(0, 0, 188, 0.29);
}

.table table td:first-child {
	padding-left: 0;
}

.table table td:last-child {
	padding-right: 0;
}

.balance {
	width: 15px;
	height: 15px;
	border: 1px solid #0000bc;
	display: inline-block;
	border-radius: 50%;
}
.active {
	background-color: #0000bc;
}

.coub {
	width: 15px;
	height: 15px;
	display: block;
	border: 1px solid #0000bc;
}

.number {
	padding: 3px 12px;
	border: 1px solid rgba(0,0,0,0);
}

.activeNumber {
	width: 100%;
	height: 100%;
	border: 1px solid #0000bc;
}
