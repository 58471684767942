
.index {
	margin: 0 0 -20px;
}

.col {
	margin-bottom: 20px;
	text-decoration: none;
	display: block;
}

@media (min-width: 1024px) {
	.colSm {
		width: 44.5%;
	}
}

@media (min-width: 1024px) {
	.colMd {
		width: 55.5%;
	}
}

@media (min-width: 1024px) {
	.colLg {
		width: 100%;
	}
}

@media (min-width: 1024px) {
	.col {
		margin-bottom: 40px;
		padding: 0 20px;
	}
}

.item {
	position: relative;
	display: block;
	padding: 30px 30px 45px;
	height: 100%;
	min-height: 200px;
	text-decoration: none;
	color: #0000bc;
	background: #fff;
}

.title {
	margin-bottom: 22px;
	font-weight: 200;
	font-size: 30px;
	line-height: 40px;
	letter-spacing: 3px;
	text-transform: uppercase;
}

.title svg {
	display: inline-block;
	vertical-align: top;
	width: 36px;
	height: 36px;
	fill: #0000bc;
}

@media (min-width: 768px) {
	.title {
		font-size: 36px;
		line-height: 50px;
	}
}

.text {
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.5px;
}

.table table {
	width: 100%;
	font-size: 11px;
	line-height: 16px;
	letter-spacing: 1px;
}

.table table td {
	vertical-align: top;
	border-top: solid 1px #0000bc;
	padding: 15px 8px 23px;
}

.table table td:first-child {
	padding-left: 0;
}

.table table td:last-child {
	padding-right: 0;
}

.table table tr:first-child td {
	border-top: none;
	padding-top: 0;
}

.table table tr:last-child td {
	padding-bottom: 0;
}

@media (min-width: 1024px) {
	.table table {
		font-size: 14px;
		line-height: 20px;
	}
}

.tableName {
	max-width: 115px;
}

@media (min-width: 1024px) {
	.tableName {
		max-width: 170px;
	}
}

.tableNumber {
	margin-top: 5px;
}

.tableTime {
	margin-top: 5px;
	font-size: 12px;
	letter-spacing: 0;
}

@media (max-width: 767px) {
	.table {
		overflow: auto;
	}

	.table table {
		min-width: 460px;
	}
}

@media (min-width: 1200px) {
	.table {
		margin-bottom: -9px;
	}
}

.link {
	position: absolute;
	right: 20px;
	bottom: 20px;
	z-index: 5;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	fill: #0000bc;
}

.link svg {
	display: block;
	width: 15px;
	height: 14px;
}

.disabled {
	opacity: 0.3;
}

@media (min-width: 1024px) {
	.item {
		min-height: 250px;
	}
}

@media (min-width: 1200px) {
	.item {
		padding: 53px 60px;
		min-height: 300px;
	}
}

@media (min-width: 1024px) {
	.index {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 -20px -40px;
	}
}

.instructionLink {
	width: 100%;
	text-align: center;
	padding-top: 20px;
	display: none;
}
.instructionLink > a {
	font-family: "CitySans", sans-serif;
	font-weight: 300;
	color: #fff;
	font-size: 14px;
	text-decoration: none;
	list-style-type: none;
}
.instructionLink > a:hover {
	text-decoration: underline;
}

@media (max-width: 768px) {
	.instructionLink {
		display: block;
	}
}
