
.filter {
	margin-bottom: 40px;
}

.filterContainer {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-auto-flow: column;
	grid-column-gap: 1em;
	flex: 1;
	width: 100%;
}

.filterRight {
	align-items: flex-end;
}

.filterRight > button {
	margin: 0 0 20px;
}

@media (min-width: 768px) {
	.filterRow {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
	}
}

@media (min-width: 768px) {
	.filterCol {
		padding-left: 20px;
		width: 33.33334%;
	}
}

@media (min-width: 768px) {
	.filterRight {
		display: flex;
		flex-shrink: 0;
		justify-content: flex-start;
	}
	.filterRight > button {
		margin-right: 40px;
	}
}

@media (min-width: 1024px) {
	.filterRight {
		display: block;
		margin-left: 30px;
		width: 200px;
	}
}

@media (min-width: 1200px) {
	.filterRight {
		margin-left: 25px;
	}
}

.filterSubmit {
	margin: 0;
}

.filterInput input {
	box-sizing: border-box;
}

@media (min-width: 768px) {
	.filterSubmit .btn {
		margin-left: 0;
	}
}

@media (min-width: 1024px) {
	.filter {
		display: flex;
	}
}

@media (max-width: 768px) {
	.filterContainer {
		display: flex;
		flex-direction: column;
	}
}
