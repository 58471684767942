.header {
	background: url("../../assets/images/header.jpg") 50% 50% no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
}

.top {
	padding: 15px 0;
	background: rgba(0, 0, 0, 0.9);
}

.top .container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@media (min-width: 1024px) {
	.top {
		padding: 31px 0;
	}
}

.logo img {
	width: 120px;
}

@media (min-width: 768px) {
	.logo img {
		width: 160px;
	}
}

.menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
	font-size: 12px;
}

.menu li + li {
	margin-left: 20px;
}

.left {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.link {
	padding-left: 40px;
}
.link > a{
	font-family: "CitySans", sans-serif;
	font-weight: 300;
	color: #fff;
	font-size: 12px;
	text-decoration: none;
	list-style-type: none;
}
.link > a:hover {
	text-decoration: underline;
}

@media (max-width: 768px) {
	.link {
		display: none;
	}
}
@media (min-width: 768px) {
	.menu li + li {
		margin-left: 40px;
	}
}

.menu li a {
	text-decoration: none;
	color: inherit;
}

.menu li a:hover {
	text-decoration: underline;
}

.bottom {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 120px;
}

@media (min-width: 768px) {
	.bottom {
		height: 170px;
	}
}

@media (min-width: 1200px) {
	.bottom {
		height: 270px;
	}
}

.title {
	font-weight: 200;
	font-size: 30px;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.title {
		font-size: 40px;
	}
}

@media (min-width: 1024px) {
	.title {
		font-size: 50px;
	}
}

@media (min-width: 1200px) {
	.title {
		font-size: 60px;
	}
}


.container {
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}

@media (min-width: 768px) {
	.container {
		padding: 0 10px;
		max-width: 720px;
	}
}

@media (min-width: 1024px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}
