.container {
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}

@media (min-width: 768px) {
	.container {
		padding: 0 10px;
		max-width: 720px;
	}
}

@media (min-width: 1024px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

.login {
	position: relative;
	z-index: 5;
	padding: 50px 0;
	min-height: 100vh;
	background: #060606 url("../../assets/images/login.jpg") 50% 50% no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
}

.login::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
}

.content {
	margin: 0 0 0 auto;
	max-width: 460px;
}

.title {
	margin-bottom: 28px;
	font-weight: 200;
	font-size: 38px;
	line-height: 50px;
}

@media (min-width: 768px) {
	.title {
		font-size: 50px;
		line-height: 70px;
	}
}

@media (min-width: 1200px) {
	.title {
		font-size: 60px;
		line-height: 80px;
	}
}

.loginLabel {
	margin-bottom: 22px;
	font-size: 22px;
}

.form {
	max-width: 360px;
}

.buttonWrapper {
	margin-top: 30px;
}

.buttonWrapper .btn {
	border-color: #fff;
	color: #fff;
	fill: #fff;
}

.buttonWrapper .btn::before {
	border-top-color: #fff;
}

.buttonWrapper .btn::after {
	border-right-color: #fff;
}

.buttonWrapper .btn i {
	border-bottom-color: #fff;
	border-left-color: #fff;
}

.buttonWrapper .btn:hover {
	color: #333;
	fill: #333;
	background: #fff;
}

@media (min-width: 768px) {
	.buttonWrapper {
		margin-top: 56px;
	}
}

.link {
	margin-top: 28px;
	font-weight: 400;
	font-size: 16px;
}

.link a {
	text-decoration: none;
	color: inherit;
}

.link a:hover {
	text-decoration: underline;
}

.login .input input {
	border-color: #fff;
	color: #fff;
}
.login .input svg {
	fill: #fff!important;
}

.login .input input::-webkit-input-placeholder {
	color: #fff;
	opacity: 0.5;
}

.login .input input:-moz-placeholder {
	color: #fff;
	opacity: 0.5;
}

.login .input input::-moz-placeholder {
	color: #fff;
	opacity: 0.5;
}

.login .input input:-ms-input-placeholder {
	color: #fff;
	opacity: 0.5;
}

.login .input input::-ms-input-placeholder {
	color: #fff;
	opacity: 0.5;
}

.login .input input::placeholder {
	color: #fff;
	opacity: 0.5;
}

@media (min-width: 768px) {
	.login {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-moz-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
