.wrapper {
	margin-bottom: 24px;
	position: relative;
}

.labelWrapper {
	margin-top: 24px;
}

.button {
	display: block;
	outline: none;
	border: solid 1px #0000bc;
	padding: 4px 20px 0;
	width: 100%;
	height: 60px;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 0.5px;
	color: rgba(0, 0, 188, 0.29);
	background: transparent;
	line-height: 54px;
	position: relative;
	cursor: pointer;
}

.active .button {
	color: #0000bc;
}
